import React from 'react';

import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Image } from '@chakra-ui/react';

const Clients2 = () => {
  return (
    <section>
      <div className="relative w-full flex justify-center">
        <div className="flex items-center marque2 w-90 px-auto">
          <div className="container mx-auto">
            <div className="lg:px-12 md:px-12 px-8">
              <Swiper
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                navigation={{
                  nextEl: '.image-swiper-button-next',
                  prevEl: '.image-swiper-button-prev',
                }}
                className="mySwiper"
                breakpoints={{
                  1536: {
                    slidesPerView: 6,
                    spaceBetween: 30,
                  },
                  1280: {
                    slidesPerView: 6,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 25,
                  },
                  991: {
                    slidesPerView: 4.5,
                    spaceBetween: 25,
                  },
                  640: {
                    slidesPerView: 4,
                    spaceBetween: 25,
                  },
                  425: {
                    slidesPerView: 2.2,
                    spaceBetween: 15,
                  },
                  325: {
                    slidesPerView: 2.2,
                    spaceBetween: 15,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="w-full">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                      alt=""
                      className="object-contain d-block img-custom"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                      alt=""
                      className="object-contain d-block img-custom"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                      alt=""
                      className="object-contain d-block img-custom"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                      alt=""
                      className="object-contain d-block img-custom"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                      alt=""
                      className="object-contain d-block img-custom"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                      alt=""
                      className="object-contain d-block img-custom"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                      alt=""
                      className="object-contain d-block img-custom"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients2;
