import React, { useEffect } from 'react';
import { Button, Checkbox } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ContactForm1 = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="bg-primary">
      <div className="container mx-auto lg:px-16 md:px-12 px-5 lg:py-16 md:py-16 py-8">
        <div className="lg:flex lg:justify-between gap-4 w-full">
          <div className="lg:w-1/2 lg:mb-0 md:mb-6">
            <p
              className="lg:w-11/12 fsize86 md-fsize64 sm-fsize30 font-semibold sm-lead text-white"
              data-aos="fade-up"
              data-aos-once="true"
            >
              Lorem Ipsum generate
            </p>
          </div>
          <div className="lg:w-1/2 contactcss lg:mt-0 md:mt-0 mt-4">
            <p className="lg:mb-4 md:mb-4 mb-2 letter-spacing4px fsize24 sm-fsize18 font-semibold clr-ddd9d8">
              Lorem Ipsum
            </p>
            <form>
              <div className="lg:mb-10 mb-3">
                <input
                  type="text"
                  placeholder="Full Name*"
                  className="inputFeild"
                />
              </div>
              <div className="lg:mb-10 mb-3 w-full lg:flex gap-8">
                <div className="w-full lg:mb-0 md:mb-0 mb-4">
                  <input
                    type="text"
                    placeholder="Phone number*"
                    className="inputFeild"
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    placeholder="Email Id*"
                    className="inputFeild"
                  />
                </div>
              </div>
              <div className="lg:mb-10 mb-3">
                <input
                  type="text"
                  placeholder="Your Message*"
                  className="inputFeild"
                />
              </div>
              <div className="pb-3">
                <Checkbox>
                  <span className="fsize14 text-dark">
                    Check to subscribe to our Newsletter
                  </span>
                </Checkbox>
              </div>
              <Button
                colorScheme="primary"
                className="fsize16 font-semibold w-full lg:p-6  lg:rounded-md"
              >
                Enquire Now
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm1;
