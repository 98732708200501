import React from 'react';
import { Facebook, Instagram } from 'react-feather';
import { useSelector } from 'react-redux/es/exports';

export const ContactDetails3 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="container mx-auto lg:pt-14 md:pt-12 pt-6 lg:px-12 md:px-8 px-5">
      <div className="lg:flex md:flex">
        <div className="lg:w-1/2 md:w-1/2 text-center lg:p-16 md:p-12 lg:p-4 md:pb-4 pb-2 bdr-r">
          <h2
            id="4828765606"
            className="dynamicStyle fsize34 md-fsize26 sm-fsize22 font-medium pb-2 text-primary"
          >
            {data ? data['4828765606'] : 'Connect with Us'}
          </h2>
          <p
            id="4811037063"
            className="dynamicStyle text-center sm-fsize14 clr-191E22"
          >
            {data ? data['4811037063'] : '+5985998259+884'}
          </p>
          <p
            id="6497734695"
            className="dynamicStyle text-center sm-fsize14 clr-191E22"
          >
            {data ? data['6497734695'] : 'hello@company.com'}
          </p>
        </div>
        <div className="lg:w-1/2 md:w-1/2 text-center lg:p-16 md:p-12 lg:p-4 md:pb-4 pb-2 bdr-l">
          <h2
            id="7976773743"
            className="dynamicStyle fsize34 md-fsize26 sm-fsize22 font-medium pb-2 text-primary"
          >
            {data ? data['7976773743'] : 'Visit Us'}
          </h2>
          <p id="9712330784" className="dynamicStyle sm-fsize14 clr-191E22">
            {data
              ? data['9712330784']
              : '128 southwork Street,London Jetpur,Rajkot,Gujrat'}
          </p>
        </div>
      </div>
      <div className="flex justify-center gap-4 lg:py-12 md:py-10 py-6">
        <div>
          <Facebook />
        </div>
        <div>
          <Instagram />
        </div>
      </div>
    </section>
  );
};
export default ContactDetails3;
