import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import ModalStartnew from './AiSubComp/ModalStartnew';
import { setConversationHistory } from '../../../redux/Aiassist/Aiassist-actions';
import Chat from './AiSubComp/Chat';
import Mic from './AiSubComp/Mic';
import { ResetConversationHistory } from '../../../redux/Aiassist/Aiassist-actions';
import Recognizer from './AiSubComp/Core/Recognizer';
import Synthensizer from './AiSubComp/Core/Synthensizer';
const AIassistant = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [button, setbutton] = useState(false);
  const [pause, setpause] = useState(false);
  const [section, setsection] = useState(false);
  const Conversation = useSelector(state => state.Aiassist.ConversationHistory);
  let targetLanguage = useSelector(state => state.Aiassist.targetLanguage);
  let neuralModels = useSelector(state => state.Aiassist.neuralModels);

  useEffect(() => {
    Recognizer(dispatch, targetLanguage, setpause);
    Synthensizer(dispatch, targetLanguage, neuralModels);
  }, [targetLanguage]);

  const reset = () => {
    dispatch(ResetConversationHistory());
    setsection(false);
    setbutton(false);
    setpause(false);
    onClose(false);
  };
  return (
    <section>
      <ModalStartnew isOpen={isOpen} onClose={onClose} reset={reset} />

      <div className="bg-white lg:p-10 md:p-8 p-3">
        <div className="lg:flex md:flex block w-full">
          <Chat section={section} onOpen={onOpen} />
          <Mic
            section={section}
            button={button}
            pause={pause}
            setpause={setpause}
            setsection={setsection}
            setbutton={setbutton}
          />
        </div>
      </div>
    </section>
  );
};

export default AIassistant;
