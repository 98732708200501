import React from 'react';
import { Image, InputGroup, Input, InputRightElement } from '@chakra-ui/react';
import { Star } from 'react-feather';

import { Search2Icon } from '@chakra-ui/icons';
import { FaHeart } from 'react-icons/fa';
import { useSelector } from 'react-redux/es/exports';

const Wishlist2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  return (
    <div className="container m-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
      <div>
        <div className="w-full flex justify-between lg:gap-16 md:gap-12 gap-4">
          <h2
            id="6212668990"
            className="dynamicStyle w-full  fsize24 sm-fsize20  font-semibold"
          >
            {data ? data['6212668990'] : 'Lorem Ipsum'}
          </h2>
          <div className="w-full">
            <InputGroup width="100%">
              <Input
                pr="4.5rem"
                placeholder="Search courses"
                className="rounded-100px"
              />
              <InputRightElement width="3.5rem">
                <Search2Icon />
              </InputRightElement>
            </InputGroup>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:pt-12 md:pt-10 pt-8 lg:pb-12 md:pb-10 pb-8">
          <div className="">
            <div className="relative ">
              <div className="overflow-hidden">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="h-200px w-full m-auto object-cover  custimghover"
                />
              </div>
              <div className="absolute top-0">
                <div className="bg-white px-2 py-1 flex gap-1 items-center">
                  <p className=" fsize14">4.9/5 </p>
                  <span>
                    <Star className="w-4 h-4" />
                  </span>
                </div>
              </div>
              <div className="absolute top-3 right-4 bg-white rounded-lg p-2">
                <FaHeart className="fsize16 cursor-pointer" />
              </div>
            </div>

            <div className="px-5 bg-primary h-fit lg:py-10 md:py-6 py-4 custcardcourse">
              <div className="flex items-center justify-between pb-2 ">
                <p className="text-white fsize14">Course Name</p>
                <p className="text-white fsize14">Mobile</p>
              </div>
              <p className="text-white fsize14 font-medium">Course Contest</p>
              {/* <div className="mt-4">
                        <Button
                          width="100%"
                          colorScheme="secondary"
                          className="rounded-100px"
                        >
                          Course
                        </Button>
                      </div> */}
            </div>
          </div>
        </div>
        {/* <div className=" flex justify-center">
          <Button
            size="lg"
            colorScheme="primary"
            className="dynamicStyle rounded-full  text-white"
          >
            <a href="/elearning-course">All Course</a>
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default Wishlist2;
