import React, { useEffect } from 'react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const FeatureCard2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section className="lg:py-14 md:py-14 py-6">
      <div className="text-center w-1/2 md-w-80 sm-wfull mx-auto lg:mb-14 md:mb-14 mb-6 lg:px-0 md:px-0 px-5 ">
        <h3
          id="6159809925"
          className="dynamicStyle text-primary sm-fsize14 font-semibold clr-ddd9d8 mb-2 cust-textColor"
        >
          {data ? data['6159809925'] : 'Lorem Ipsum'}
        </h3>
        <h1
          id="0370346088"
          className="dynamicStyle fsize30 sm-fsize20 font-semibold mb-2 cust-textColor"
        >
          {data ? data['0370346088'] : 'Lorem Ipsum'}
        </h1>
        <p
          id="3287910140"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['3287910140']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className="dynamicStyle textColor-light sm-fsize14  text-center"
        ></p>
      </div>

      <div className="lg:flex lg:flex-col container mx-auto lg:px-12 md:px-12 px-5">
        <div
          className="lg:flex md:flex items-center lg:gap-6 hover-6f6f6f mb-4 w-full lg:mb-6 lg:px-6 md:px-4 px-4 lg:py-6 md:py-4 py-4 h-120px sm-h-200px rounded-lg box-shadow-inset bg-d2d2d2  cust-bgColor br-ddd9d8"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div
            id="7581017454"
            className="dynamicStyle2 flex lg:flex-row md:flex-row flex-col justify-center lg:w-3/12 md:w-2/5 w-full items-center gap-2 "
          >
            <Image
              src={data ? getImage(data['7581017454']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="arrow"
              className="object-cover lg:w-16 md:w-16 md:h-16 lg:h-16 md:w-16 h-16 w-16 rounded-full bg-EDF2F6"
            />
            <p
              id="4220378609"
              className="dynamicStyle fsize20 sm-fsize16 font-semibold cust-textColor"
            >
              {data ? data['4220378609'] : 'Lorem Ipsum'}
            </p>
          </div>
          <div className="w-9/12 sm-wfull">
            <p
              id="7667630130"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['7667630130']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle fsize16 sm-fsize14 lg:text-left md:text-left text-center textColor-light"
            ></p>
          </div>
        </div>
        <div
          className="lg:flex md:flex items-center lg:gap-6 hover-ddd9d8 mb-4 sm-h-200px w-full lg:mb-6 lg:px-6 md:px-4 px-4 lg:py-6 md:py-4 py-4 h-120px rounded-lg box-shadow-outer  br-ddd9d8"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div
            id="1782692944"
            className="dynamicStyle2 flex lg:flex-row md:flex-row flex-col justify-center lg:w-3/12 md:w-2/5 w-full items-center gap-2"
          >
            <Image
              src={data ? getImage(data['1782692944']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="mouse"
              className="object-cover lg:w-16 md:w-16 md:h-16 lg:h-16 md:w-16 h-16 w-16 rounded-full bg-EDF2F6"
            />
            <p
              id="1359798079"
              className="dynamicStyle fsize20 sm-fsize16 font-semibold cust-textColor"
            >
              {data ? data['1359798079'] : 'Lorem Ipsum'}
            </p>
          </div>
          <div className="w-9/12 sm-wfull">
            <p
              id="1830733163"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['1830733163']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle fsize16 sm-fsize14 lg:text-left md:text-left text-center textColor-light"
            ></p>
          </div>
        </div>
        <div
          className="lg:flex md:flex items-center lg:gap-6 hover-6f6f6fb mb-4 sm-h-200px w-full lg:mb-6 lg:px-6 md:px-4 px-4 lg:py-6 md:py-4 py-4 h-120px rounded-lg box-shadow-inset bg-d2d2d2  cust-bgColor br-ddd9d8"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div
            id="1312094461"
            className="dynamicStyle2 flex lg:flex-row md:flex-row flex-col justify-center lg:w-3/12 md:w-2/5 w-full items-center gap-2"
          >
            <Image
              src={data ? getImage(data['1312094461']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="arrow"
              className="object-cover lg:w-16 md:w-16 md:h-16 lg:h-16 md:w-16 h-16 w-16 rounded-full bg-EDF2F6"
            />
            <p
              id="4404500100"
              className="dynamicStyle fsize20 sm-fsize16 font-semibold cust-textColor"
            >
              {data ? data['4404500100'] : 'Lorem Ipsum'}
            </p>
          </div>
          <div className="w-9/12 sm-wfull">
            <p
              id="8588867181"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['8588867181']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle fsize16 sm-fsize14 lg:text-left md:text-left text-center textColor-light"
            ></p>
          </div>
        </div>
        <div
          className="lg:flex md:flex items-center lg:gap-6 hover-ddd9d8 mb-4 w-full sm-h-200px lg:mb-6 lg:px-6 md:px-4 px-4 lg:py-6 md:py-4 py-4 h-120px rounded-lg box-shadow-outer  br-ddd9d8"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div
            id="6460468279"
            className="dynamicStyle2 flex lg:flex-row md:flex-row flex-col justify-center lg:w-3/12 md:w-2/5 w-full items-center gap-2"
          >
            <Image
              src={data ? getImage(data['6460468279']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="mouse"
              className="object-cover lg:w-16 md:w-16 md:h-16 lg:h-16 md:w-16 h-16 w-16 rounded-full bg-EDF2F6"
            />
            <p
              id="5144967331"
              className="dynamicStyle fsize20 sm-fsize16 font-semibold cust-textColor"
            >
              {data ? data['5144967331'] : 'Lorem Ipsum'}
            </p>
          </div>
          <div className="w-9/12 sm-wfull">
            <p
              id="0646192287"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['0646192287']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle fsize16 sm-fsize14 lg:text-left md:text-left text-center textColor-light"
            ></p>
          </div>
        </div>
        <div
          className="lg:flex md:flex items-center lg:gap-6 hover-6f6f6f mb-4 w-full sm-h-200px lg:mb-6 lg:px-6 px-4 lg:py-6 py-4 h-120px rounded-lg box-shadow-inset bg-d2d2d2  cust-bgColor br-ddd9d8"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div
            id="7480410287"
            className="dynamicStyle2 flex lg:flex-row md:flex-row flex-col justify-center lg:w-3/12 md:w-2/5 w-full items-center gap-2"
          >
            <Image
              src={data ? getImage(data['7480410287']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="arrow"
              className="object-cover lg:w-16 md:w-16 md:h-16 lg:h-16 md:w-16 h-16 w-16 rounded-full bg-EDF2F6"
            />
            <p
              id="9447561304"
              className="dynamicStyle fsize20 sm-fsize16 font-semibold cust-textColor"
            >
              {data ? data['9447561304'] : 'Lorem Ipsum'}
            </p>
          </div>
          <div className="w-9/12 sm-wfull">
            <p
              id="7897181051"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['7897181051']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle fsize16 sm-fsize14 lg:text-left md:text-left text-center textColor-light"
            ></p>
          </div>
        </div>
        <div
          className="lg:flex md:flex items-center lg:gap-6 hover-ddd9d8 w-full sm-h-200px lg:mb-6 lg:px-6 md:px-4 px-4 lg:py-6 md:py-4 py-4 h-120px rounded-lg box-shadow-outer  br-ddd9d8"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div
            id="6397117338"
            className="dynamicStyle2 flex lg:flex-row md:flex-row flex-col justify-center lg:w-3/12 md:w-2/5 w-full items-center gap-2"
          >
            <Image
              src={data ? getImage(data['6397117338']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="mouse"
              className="object-cover lg:w-16 md:w-16 md:h-16 lg:h-16 md:w-16 h-16 w-16 rounded-full bg-EDF2F6"
            />
            <p
              id="4473823001"
              className="dynamicStyle fsize20 sm-fsize16 font-semibold cust-textColor"
            >
              {data ? data['4473823001'] : 'Lorem Ipsum'}
            </p>
          </div>
          <div className="w-9/12 sm-wfull">
            <p
              id="5568935294"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['5568935294']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle fsize16 sm-fsize14 lg:text-left md:text-left text-center textColor-light"
            ></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard2;
