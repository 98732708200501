import { useColorMode } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import VanillaTilt from 'vanilla-tilt';

const FeatureCard12 = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const items = [
    {
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',

      title: 'Creativity',

      description:
        'Duis aute irure dolor in it esse cillum fugiat nulla pari erunt mollit anim id est laborum.',
    },

    {
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',

      title: 'Creativity 1',

      description:
        'Duis aute irure dolor in it esse cillum fugiat nulla pari erunt mollit anim id est laborum.',
    },

    {
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',

      title: 'Creativity 2',

      description:
        'Duis aute irure dolor in it esse cillum fugiat nulla pari erunt mollit anim id est laborum.',
    },
  ];

  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll('.item-card2'), {
      max: 25,
      speed: 400,
      glare: true,
      'max-glare': 0.5,
    });
  }, []);

  return (
    <div
    className={
      colorMode === 'light'
        ? 'bg-1a2540 lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8'
        : 'lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8'
    }
     >
      <div className="container mx-auto">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center lg:mb-16 md:mb-12 mb-8">
          <h2 className="font-semibold header2 fsize30 sm-fsize20 mb-2 relative inline-block text-white cust-textColor">
          Welcome to My World
          </h2>

          <p className="fsize16 sm-fsize14 text-white textColor-light">
            I'm a young tech enthasist and entrepreneur who love to take risk. I
            grew up in a tech family in New York City.
          </p>
        </div>

        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
          {items.map(item => (
            <div className="item-card2 text-center" data-tilt>
              <div className="lg:w-1/4 md:w-2/4 w-1/2 mx-auto">
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt=""
                  className="w-20 h-20 mx-auto bg-gray rounded-full"
                />
              </div>

              <div className="">
                <h3 className="fsize24 sm-fsize20 mt-2 lg:mb-4 md:mb-3 mb-2 font-medium">
                  {item.title}
                </h3>

                <p className="fsize18 sm-fsize16 clr-b0aac0 ">
                  Duis aute irure dolor in it esse cillum fugiat nulla pari
                  erunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard12;
