import React, { useEffect, useState } from 'react';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import Fade from 'react-reveal/Fade';
import FeatherIcon from 'feather-icons-react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { Swiper, SwiperSlide } from 'swiper/react';

import { EffectCoverflow, Navigation } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import Rating from 'react-rating';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';
import testimonial from '../../../apis/client/testimonial';


const Testimonial5 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const data = useSelector(state => state.cms.allSections[pageName]);
  const dispatch = useDispatch();
  const getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  // console.log(getAllTestimonial());
  useEffect(() => {
    dispatch(getTestimonal());
  }, []);

  return (
    <>
      {getAllTestimonial ? (
        <section
        className={
          colorMode === 'light'
            ? 'bggradient-mid relative testimonial lg:pb-8 md:pb-8 pb-8'
            : 'relative testimonial lg:pb-8 md:pb-8 pb-8'
        }>
          <div className=" container mx-auto px-5 lg:py-16 md:py-16 py-8 lg:px-12 md:px-12">
            <div className="text-center lg:mb-12 md:mb-12 mb-6 lg:mt-0 md:mt-0 mt-8">
              <h2
                id="9466016868"
                className="dynamicStyle clr-fff fsize36 md:fsize32 sm-fsize20 font-semibold cust-textColor"
              >
                {data ? data['9466016868'] : '  CLIENT SUCCESS STORIES'}
              </h2>
              <p
                id="4102193340"
                dangerouslySetInnerHTML={{
                  __html: data ? data['4102193340'] : 'no-data',
                }}
                className="dynamicStyle mt-2 fsize16 sm-fsize14 clr-fff textColor-light"
              ></p>
            </div>

            <div className="">
              <Swiper
                spaceBetween={20}
                effect={'coverflow'}
                modules={[EffectCoverflow, Navigation]}
                autoplay={{
                  delay: '1000',
                }}
                navigation={{
                  nextEl: '.test-swiper-button-next',
                  prevEl: '.test-swiper-button-prev',
                }}
                className="mySwiper"
                grabCursor={true}
                centeredSlides={false}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  820: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                  425: {
                    slidesPerView: 1.2,
                    spaceBetween: 10,
                  },
                  325: {
                    slidesPerView: 1.2,
                    spaceBetween: 10,
                  },
                  300: {
                    slidesPerView: 1.2,
                    spaceBetween: 10,
                  },
                }}
              >
                <div className="swiper-button  test-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div className="swiper-button test-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>
                {getAllTestimonial && getAllTestimonial.data.result.length > 0
                  ? getAllTestimonial.data.result.map(testimonial => {
                      return (
                        <SwiperSlide>
                          <Card className="mx-auto bg-white lg:p-8 md:p-8 p-4 rounded-xl lg:mt-6 md:mt-6 mt-6">
                            <div className="text-center my-2">
                              <Image
                                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                src={
                                  `${process.env.REACT_APP_STORAGE_URL}` +
                                  `${testimonial.image}`
                                }
                                className="rounded-full roundetest cursor-pointer activeuser mx-auto"
                                alt="heroImage"
                              />
                            </div>
                            <div className="flex justify-center mt-4">
                              <Rating
                                initialRating={4}
                                readonly={true}
                                emptySymbol={
                                  <FeatherIcon
                                    icon="star"
                                    className="mr-1"
                                    size={22}
                                  />
                                }
                                fullSymbol={
                                  <FeatherIcon
                                    icon="star"
                                    className="mr-1 fillstroke"
                                    size={22}
                                  />
                                }
                                fractions={2}
                              />
                            </div>

                            {/* <h2 className="sm-fsize20 fsize24 font-semibold text-primary text-center mt-3">
                              {`${testimonial.firstName}` +
                                "\n" +
                                `${testimonial.lastName}`}
                            </h2> */}
                            <h2 className="cust-textColor sm-fsize20 fsize24 font-semibold text-primary text-center mt-3">
                              {testimonial.name}
                            </h2>

                            {/* <p className="sm-fsize14 fsize16 text-dark text-center">
                          Lorem ipsus
                        </p> */}
                          </Card>
                        </SwiperSlide>
                      );
                    })
                  : 'No Data'}
              </Swiper>
            </div>
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

export default Testimonial5;
