import React, { useEffect, useState } from 'react';
import { Check, Feather } from 'react-feather';
import FeatherIcon from 'feather-icons-react';
import {
  Button,
  Image,
  Progress,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  LinkBox,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux/es/exports';
// import {
//   getAllTeamMembers,
//   getAllDealTerms,
//   getAllFaqs,
// } from '../../../redux/forms/forms-action';
const ProductDetails3 = ({ pageName }) => {
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState([]);
  const [search, setSearch] = useState();
  // const getTableDetails = async () => {
  //   let payload = {
  //     projectId: process.env.REACT_APP_ID,
  //     projectName: process.env.REACT_APP_COMMUNITY_NAME,
  //     paginated: true,
  //   };
  //   if (search) Object.assign(payload, { searchBy: search });
  //   let data = await dispatch(getData("team_members", payload));
  //   setCardData(data.result);
  // };
  // useEffect(() => {
  //   // getTableDetails();
  //   dispatch(getAllTeamMembers());
  //   dispatch(getAllDealTerms());
  //   dispatch(getAllFaqs());
  // }, []);

  let selectedData = useSelector(state => state.Forms.selectedCard);

  const AllTeamMembers = useSelector(state => state.Forms.TeamMembers);
  const DealTerm = useSelector(state => state.Forms.DealTerms);
  const Faq = useSelector(state => state.Forms.Faqs);
  console.log(Faq, 'selected');

  const data = useSelector(state => state.cms.allSections[pageName]);
  // function getImage(image) {
  //   if (image && image.includes('blob:')) {
  //     return image;
  //   } else {
  //     return process.env.REACT_APP_STORAGE_URL + image;
  //   }
  // }

  return (
    <div className="lg:px-12 md:px-8 px-5 container m-auto my-8">
      <div className="lg:flex md:flex gap-4">
        <div className="lg:w-2/6 md:w-2/6 lg:mb-0 md:mb-0 mb-8">
          <div className="w-full box-shdw rounded-lg bg-white lg:p-10 p-5">
            <div>
              <h2 className="fsize30 sm-fsize20 font-bold">
                {selectedData['company name']}
              </h2>
              <div className="flex gap-4 pt-2">
                <Button className="fsize14 textprimary">
                  <FeatherIcon icon="map-pin" className="mr-1" size={16} />
                  {selectedData.companylocation}
                </Button>
                {/* <Button className="fsize14 textprimary">E-commerce</Button> */}
              </div>
              <div className="lg:pt-8 md:pt-8 pt-6">
                <div className="flex gap-2 ">
                  <Check size="15" />
                  <p className="fsize14 sm-fsize13">
                    Done 1770 aution in last 2 years.
                  </p>
                </div>
                <div className="flex gap-2 lg:pt-6 md:pt-6 pt-4">
                  <Check size="15" />
                  <p className="fsize14 sm-fsize13">
                    Done 1770 aution in last 2 years.
                  </p>
                </div>
                <div className="flex gap-2 lg:pt-6 md:pt-6 pt-4">
                  <Check size="15" />
                  <p className="fsize14 sm-fsize13">
                    Done 1770 aution in last 2 years.
                  </p>
                </div>
              </div>
              <div className="flex gap-10 pt-8">
                <div className="text-start">
                  <p className="fsize14 text-gray mb-1">Startup Stage</p>
                  <p className="font-medium sm-fsize13">
                    {selectedData.stageOfStartup}
                  </p>
                </div>
                <div className="text-start">
                  <p className="fsize14 text-gray mb-1">Funding Type</p>
                  <p className="font-medium sm-fsize13">
                    {selectedData.typeOfFunding}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-full shadow-lg bg-white p-10 mt-8">
            <div>
              <h2 className="fsize28 sm-fsize20 pb-8 font-bold">Bid Wheelz</h2>

              <div>
                <h2 className="  font-medium">What is an AMA ?</h2>

                <p className="fsize14 text-gray leading-6 pt-2">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. In
                  reprehenderit consectetur cupiditate illum, ullam voluptates
                  quo quaerat officiis quos officia veritatis sint, deleniti
                  ipsa cum maiores qui dolorem tempora nemo!
                </p>
              </div>
              <div className="pt-6">
                <h2 className="  font-medium">Save the Date</h2>

                <p className="fsize14 text-gray leading-6 pt-2">
                  7:00 PM, 5th June, 2024{" "}
                </p>
              </div>

              <div className="pt-6">
                <h2 className="  font-medium">Book a Spot</h2>
                <Button className="mt-4" px="8" py="6" colorScheme="primary">
                  Register
                </Button>
              </div>
            </div>
          </div> */}
        </div>
        <div className="lg:w-4/6 md:w-4/6  lg:px-4 md:px-4">
          <div className="w-full bg-f4f5f6">
            <Image
              src={`${process.env.REACT_APP_STORAGE_URL}${selectedData['company logo']} `}
              alt=""
              className="h-200px w-full object-contain"
            />
          </div>
          {/* <div className="pt-4">
            <h2 className="fsize30 font-bold">About</h2>
            <p className="fsize16 text-gray pt-2">
              {selectedData.companydescription}
            </p>
          </div> */}

          <div className="mt-8 box-shdw rounded-lg lg:p-6 p-4">
            <Tabs variant="unstyle">
              <TabList className="gap-4 overflow-x-scroll">
                <Tab
                  fontSize="14px"
                  fontWeight="500"
                  _selected={{
                    color: 'white',
                    px: '6',
                    borderRadius: '4',
                    bg: 'primary.500',
                  }}
                  className="selected_tab bg-f4f5f6"
                >
                  <p
                    id="2409734759"
                    className="dynamicStyle lg:px-4 md:px-4 px-4 sm-fsize12"
                  >
                    {data ? data['2409734759'] : 'Pitch'}
                  </p>
                </Tab>
                <Tab
                  fontSize="14px"
                  fontWeight="500"
                  _selected={{
                    color: 'white',
                    fontSize: '14px',
                    px: '6',
                    bg: 'primary.500',
                    borderRadius: '4',
                  }}
                  className="selected_tab bg-f4f5f6"
                >
                  <p
                    className="dynamicStyle lg:px-4 md:px-4 px-4 sm-fsize12"
                    id="9891712684"
                  >
                    {data ? data['9891712684'] : 'Team'}
                  </p>
                </Tab>

                <Tab
                  fontSize="14px"
                  fontWeight="500"
                  _selected={{
                    color: 'white',
                    px: '6',
                    fontSize: '14px',
                    bg: 'primary.500',
                    borderRadius: '4',
                  }}
                  className="selected_tab bg-f4f5f6"
                >
                  <p
                    className="dynamicStyle lg:px-4 md:px-4 px-4 sm-fsize12"
                    id="5721846803"
                  >
                    {data ? data['5721846803'] : 'Deal Teams'}
                  </p>
                </Tab>

                <Tab
                  fontSize="14px"
                  fontWeight="500"
                  _selected={{
                    color: 'white',
                    px: '6',
                    fontSize: '14px',
                    borderRadius: '4',
                    bg: 'primary.500',
                  }}
                  className="selected_tab bg-f4f5f6"
                >
                  <p
                    className="dynamicStyle lg:px-4 md:px-4 px-4 sm-fsize12"
                    id="2766740655"
                  >
                    {data ? data['2766740655'] : 'Faq'}
                  </p>
                </Tab>
              </TabList>
              <TabPanels className="lg:mt-6 md:mt-3 mt-3">
                <TabPanel pb={4} pt={2} px={0}>
                  <div className="">
                    <div className="">
                      <p className="fsize14 text-gray lg:leading-6 md:leading-6">
                        {selectedData.companydescription}
                      </p>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel pb={4} pt={2} px={0}>
                  {' '}
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                    {AllTeamMembers &&
                    AllTeamMembers.result &&
                    AllTeamMembers.result.length > 0
                      ? AllTeamMembers.result.map((item, index) => {
                          return (
                            <div className="">
                              <Image
                                className="w-full object-cover rounded-t-lg"
                                src={`${process.env.REACT_APP_STORAGE_URL}${item.image} `}
                                alt="img"
                              />
                              <div className="py-4">
                                <h2 className="font-semibold fsize16">
                                  {item['full name']}
                                </h2>
                                <p className="fsize12 text-gray">
                                  {item['job title']}
                                </p>

                                <p className="fsize12 text-gray pt-4">
                                  {item.description}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      : ''}
                  </div>
                </TabPanel>
                <TabPanel pb={4} pt={2} px={0}>
                  {' '}
                  {DealTerm && DealTerm.result && DealTerm.result.length > 0
                    ? DealTerm.result.map(e => {
                        return (
                          <div className="  rounded-sm ">
                            <div className="flex justify-between pb-2">
                              {' '}
                              <h2 className="font-semibold md-fsize14 sm-fsize13">
                                MINIMUM INVESTMENT VALUE
                              </h2>
                              <h2 className="font-semibold md-fsize14 sm-fsize13">
                                {e['minimum value']}
                              </h2>
                            </div>
                            <hr />
                            <div className="flex justify-between pb-2 pt-4">
                              {' '}
                              <h2 className="font-semibold md-fsize14 sm-fsize13">
                                VALUATION
                              </h2>
                              <h2 className="font-semibold md-fsize14 sm-fsize13">
                                {e.valuation}
                              </h2>
                            </div>
                            <hr />
                            <div className="flex justify-between pb-2 pt-4">
                              {' '}
                              <h2 className="font-semibold md-fsize14 sm-fsize14">
                                TARGET
                              </h2>
                              <h2 className="font-semibold md-fsize14 sm-fsize14">
                                {e.target}
                              </h2>
                            </div>
                          </div>
                        );
                      })
                    : ''}
                </TabPanel>

                <TabPanel pb={4} pt={2} px={0}>
                  <div className="w-full ">
                    <Accordion allowToggle>
                      {Faq && Faq.result && Faq.result.length > 0
                        ? Faq.result.map((faq, index) => (
                            <div key={index} className="py-1 mb-2">
                              <AccordionItem className="border rounded-md">
                                <h2>
                                  <AccordionButton py="3">
                                    <Box as="span" flex="1" textAlign="left">
                                      <h2 className="font-semibold md-fsize14 sm-fsize13">
                                        {faq.question}
                                      </h2>
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <p className="fsize14 md-fsize12 sm-fsize12">
                                    {faq.answer}
                                  </p>
                                </AccordionPanel>
                              </AccordionItem>
                            </div>
                          ))
                        : ''}
                    </Accordion>
                  </div>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductDetails3;
