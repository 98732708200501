import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { Linkedin, Twitter } from 'react-feather';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';

const Team6 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const data = useSelector(state => state.cms.allSections[pageName]);
  const Meet = [
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Michael Foster',
      role: 'motion Graphics',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Michael Foster',
      role: 'motion Graphics',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Michael Foster',
      role: 'motion Graphics',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Michael Foster',
      role: 'motion Graphics',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Michael Foster',
      role: 'motion Graphics',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Michael Foster',
      role: 'motion Graphics',
    },
  ];
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className="container m-auto lg:py-20 md:py-14 py-12 lg:px-12 md:px-8 px-5">
      <h2
        id="4436359893"
        className=" dynamicStyle cust-textColor  fsize36 sm-fsize20 mb-2 text-center font-semibold"
      >
        {data ? data['4436359893'] : 'Our Instructor'}
      </h2>
      <p
        id="6763163213"
        className="dynamicStyle textColor-light fsize16 sm-fsize14 text-center"
        dangerouslySetInnerHTML={{
          __html: data
            ? data['6763163213']
            : 'We are united by our passion for innovation and our commitment',
        }}
      ></p>
      <div className="lg:pt-12 md:pt-10 pt-8">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 2.4,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>

          <SwiperSlide>
            <Card id="3795501775"            
            className={
              colorMode === 'light'
                ? 'dynamicStyle2 w-full pt-4 pb-4 box-shadow-none'
                : 'dynamicStyle2 w-full pt-4 pb-4'
            }
            >
              <Image
                src={data ? getImage(data['3795501775']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`}
                alt="img"
                className="h-250px m-auto rounded-lg object-cover "
              />
              <p
                id="9761753690"
                className="dynamicStyle cust-textColor text-center pt-4 pb-1 fsize18 font-medium"
              >
                {data ? data['9761753690'] : 'Title'}
              </p>
              <p
                id="4235858975"
                className="dynamicStyle textColor-light text-506380 fsize18 text-center"
              >
                {data ? data['4235858975'] : 'sub-title'}
              </p>
              <div className="flex gap-3 pt-4 justify-center">
                <Twitter className="text-506380 cust-textColor" />
                <Linkedin className="text-506380 cust-textColor" />
              </div>
            </Card>
          </SwiperSlide>

          <SwiperSlide>
            <Card id="5509124898"  className={
              colorMode === 'light'
                ? 'dynamicStyle2 w-full pt-4 pb-4 box-shadow-none'
                : 'dynamicStyle2 w-full pt-4 pb-4'
            }>
              <Image
                src={data ? getImage(data['5509124898']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`}
                alt="img"
                className="h-250px m-auto rounded-lg object-cover "
              />
              <p
                id="4225487052"
                className="dynamicStyle  text-center pt-4 pb-1 fsize18 font-medium cust-textColor"
              >
                {data ? data['4225487052'] : 'Title'}
              </p>
              <p
                id="5485109456"
                className="dynamicStyle text-506380 fsize18 text-center textColor-light"
              >
                {data ? data['5485109456'] : 'sub-title'}
              </p>
              <div className="flex gap-3 pt-4 justify-center">
                <Twitter className="text-506380 cust-textColor" />
                <Linkedin className="text-506380 cust-textColor" />
              </div>
            </Card>
          </SwiperSlide>

          <SwiperSlide>
            <Card id="5361054786"  className={
              colorMode === 'light'
                ? 'dynamicStyle2 w-full pt-4 pb-4 box-shadow-none'
                : 'dynamicStyle2 w-full pt-4 pb-4'
            }>
              <Image
                src={data ? getImage(data['5361054786']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`}
                alt="img"
                className="h-250px m-auto rounded-lg object-cover "
              />
              <p
                id="2836393930"
                className="dynamicStyle  text-center pt-4 pb-1 fsize18 font-medium cust-textColor"
              >
                {data ? data['2836393930'] : 'Title'}
              </p>
              <p
                id="0300067224"
                className="dynamicStyle text-506380 fsize18 text-center textColor-light"
              >
                {data ? data['0300067224'] : 'sub-title'}
              </p>
              <div className="flex gap-3 pt-4 justify-center">
                <Twitter className="text-506380 cust-textColor" />
                <Linkedin className="text-506380 cust-textColor" />
              </div>
            </Card>
          </SwiperSlide>

          <SwiperSlide>
            <Card id="8049898199"  className={
              colorMode === 'light'
                ? 'dynamicStyle2 w-full pt-4 pb-4 box-shadow-none'
                : 'dynamicStyle2 w-full pt-4 pb-4'
            }>
              <Image
                src={data ? getImage(data['8049898199']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`}
                alt="img"
                className="h-250px m-auto rounded-lg object-cover "
              />
              <p
                id="8690671504"
                className="dynamicStyle  text-center pt-4 pb-1 fsize18 font-medium cust-textColor"
              >
                {data ? data['8690671504'] : 'Title'}
              </p>
              <p
                id="5738042146"
                className="dynamicStyle text-506380 fsize18 text-center textColor-light"
              >
                {data ? data['5738042146'] : 'sub-title'}
              </p>
              <div className="flex gap-3 pt-4 justify-center">
                <Twitter className="text-506380 cust-textColor" />
                <Linkedin className="text-506380 cust-textColor" />
              </div>
            </Card>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Team6;
