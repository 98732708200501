import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FaQuoteRight } from 'react-icons/fa';
import ReactStars from 'react-rating-stars-component';
import { Autoplay, Navigation } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';
import { Image, Card, Text } from '@chakra-ui/react';
const Testimonial8 = ({ pageName }) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  let getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  console.log(getAllTestimonial, 'check-test');
  useEffect(() => {
    async function mount() {
      await dispatch(getTestimonal());
    }
    mount();
  }, []);
  return (
    <div className="lg:px-12 md:px-12 px-5 lg:py-14 md:py-14 py-8 container m-auto">
      <div class="text-center pt-5 lg:pb-12 md:pb-10 pb-5">
        <h3 class="cust-textColor fsize34 sm-fsize20 text-primary cust-textColor font-semibold">
          Testimonial{' '}
        </h3>
        <div className="flex justify-center ">
          <p className="w-70 sm-fsize14 textColor-light mt-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
            natus tenetur quo, tempore corrupti blanditiis id illo vero magnam
            quod.
          </p>
        </div>
      </div>
      <Swiper
        spaceBetween={30}
        navigation={true}
        modules={[Navigation]}
        className="h-swipertestimonial8 mySwiper lg:p-2 p-1"
        breakpoints={{
          1536: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          820: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          425: {
            slidesPerView: 1.2,
            spaceBetween: 12,
          },
          325: {
            slidesPerView: 1.2,
            spaceBetween: 12,
          },
        }}
      >
        {getAllTestimonial &&
        getAllTestimonial.data &&
        getAllTestimonial.data.result &&
        getAllTestimonial.data.result.length > 0
          ? getAllTestimonial.data.result.map((testimonial, index) => {
              return (
                <SwiperSlide>
                  <Card
                    className="cust-shadow rounded-lg mt-10 p-4 relative"
                    key={index}
                  >
                    <ReactStars count={5} size={24} activeColor="#ffd700" />
                    <div className="pt-3">
                      <h2 className="fsize20 sm-fsize18 font-semibold cust-textColor">
                        {testimonial.name}
                      </h2>
                      <div className="flex gap-2">
                        <p className="w-90per cust-textColor fsize14 mt-1 lineClam4">
                          {testimonial.testimonial}
                        </p>
                        {/* <span className='w-10per fsize45 text-center'> &#8221; </span> */}
                        <FaQuoteRight
                          size="23"
                          className="text-primary cust-textColor"
                        />
                      </div>
                    </div>
                    <div className="topr-abs ">
                      <Image
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                        alt=""
                        className="rounded-full img-wh-80"
                      />
                    </div>
                  </Card>
                </SwiperSlide>
              );
            })
          : 'No Data'}
      </Swiper>
    </div>
  );
};
export default Testimonial8;
