import { useSelector } from 'react-redux/es/exports';
import {
  Button,
  Image,
  AvatarGroup,
  Avatar,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  InputGroup,
  Input,
  InputRightElement,
  Card,
} from '@chakra-ui/react';
import { FiFilter } from 'react-icons/fi';
import { Search2Icon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getData } from '../../../redux/forms/forms-action';

const FeatureDetailCard1 = ({ pageName }) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const [cards, setCards] = useState([]);
  const [search, setSearch] = useState('');

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else if (image) {
      return process.env.REACT_APP_STORAGE_URL + image;
    } else {
      return `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
    }
  }
  function errorImage(e) {
    e.target.src = `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
  }
  const getCardDetails = async () => {
    let payload = {
      projectId: process.env.REACT_APP_ID,
      projectName: process.env.REACT_APP_COMMUNITY_NAME,
      paginated: true,
    };
    if (search) Object.assign(payload, { searchBy: search });
    // let data = await dispatch(getData("startup_registration", payload));
    console.log(data, 'cards');
    setCards(data.result);
  };

  const searchBy = e => {
    console.log(e);
  };

  useEffect(() => {
    getCardDetails();
  }, []);
  return (
    <div className="container mx-auto lg:px-12 md:px-12 px-5 lg:py-12 md:py-12 py-6">
      <div className="flex lg:gap-8 md:gap-8 gap-4 mb-6">
        <InputGroup size="md">
          <Input
            pr="2.5rem"
            placeholder="Search for startups"
            className="fsize14"
          />
          <InputRightElement width="2.5rem">
            <Search2Icon
              value={search}
              onChange={searchBy}
              className="txt-gray"
            />
          </InputRightElement>
        </InputGroup>
        <Menu>
          <MenuButton
            className="c-flex gap-2 menu_width180px md-fsize14 sm-fsize13 sm-px-10px"
            as={Button}
            rightIcon={<FiFilter />}
          >
            Filter & Sort
          </MenuButton>
          <MenuList>
            <MenuItem>Live Deal</MenuItem>
            <MenuItem>Closed Deal</MenuItem>
          </MenuList>
        </Menu>
        {/* <Menu>
          <MenuButton
            className="c-flex gap-2 menu_width180px"
            as={Button}
            rightIcon={<FiFilter />}
          >
            <span>Filters & Sort</span>
          </MenuButton>
          <MenuList>
            <div className="py-2 px-4 ">
              <p className="fsize14 mb-2">Filter by Live Deal</p>
              <p className="fsize14 mb-2">Filter by Closed Deal</p>
            </div>
          </MenuList>
        </Menu> */}
      </div>
      <Tabs variant="soft-rounded">
        <TabList className="tab_css">
          <Tab
            fontSize="14px"
            _selected={{ color: 'white', bg: 'primary.500' }}
            className="selected_tab"
          >
            <p id="2721103651" className="dynamicStyle">
              {' '}
              {data ? data['2721103651'] : 'Live Deal'}
            </p>
          </Tab>
          <Tab
            fontSize="14px"
            _selected={{ color: 'white', fontSize: '14px', bg: 'primary.500' }}
            className="selected_tab"
          >
            <p id="3849130488" className="dynamicStyle">
              {' '}
              {data ? data['3849130488'] : 'Closed Deal'}
            </p>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div className="grid lg:grid-cols-3   md:grid-cols-2 gap-10 mt-6">
              <Card className="box-shdw rounded-2xl p-4 lg:pt-10 md:pt-10 pt-4">
                <div className="py-4">
                  <Image
                    className="h-24 lg:mb-4 md:mb-4 mb-2 mx-auto"
                    // src={getImage(card['company logo'])}
                    src={data ? getImage(data['']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="img"
                    onError={event => errorImage(event)}
                  />
                </div>
                <div className="flex justify-evenly items-center ">
                  <p className="fsize20 font-semibold cust-textColor">
                    {/* {card['company name']} */}Company Name
                  </p>
                  {/* <p className="text-gray cust-textColor">{card["typeOfFunding"]}</p> */}
                </div>
                <div className="py-4">
                  <p className="fsize14 text-gray cust-textColor text-center">
                    {/* {card['companydescription']} */}company Description
                  </p>
                </div>
                <div className="flex justify-center gap-8 mt-4">
                  <div className="text-center">
                    <p className="fsize14 text-gray cust-textColor mb-1">
                      Stage
                    </p>
                    {/* <p className="font-medium  cust-textColor">{card['stageOfStartup']}</p> */}
                    <p className="font-medium  cust-textColor">Test</p>
                  </div>
                  <div className="text-center">
                    <p className="fsize14 text-gray cust-textColor mb-1">
                      Round Size
                    </p>
                    {/* <p className="font-medium  cust-textColor">{card['fundingamount']}</p> */}
                    <p className="font-medium  cust-textColor">45</p>
                  </div>
                  <div className="text-center">
                    <p className="fsize14 text-gray cust-textColor mb-1">
                      Team
                    </p>
                    <AvatarGroup size="sm" max={2}>
                      <Avatar
                        name="Ryan Florence"
                        src="https://bit.ly/ryan-florence"
                      />
                      <Avatar
                        name="Segun Adebayo"
                        src="https://bit.ly/sage-adebayo"
                      />
                      <Avatar
                        name="Kent Dodds"
                        src="https://bit.ly/kent-c-dodds"
                      />
                      <Avatar
                        name="Prosper Otemuyiwa"
                        src="https://bit.ly/prosper-baba"
                      />
                      <Avatar
                        name="Christian Nwamba"
                        src="https://bit.ly/code-beast"
                      />
                    </AvatarGroup>
                  </div>
                </div>
                <div className="pt-6 pb-4 flex justify-center gap-8">
                  <Button className="fsize14 textprimary  cust-textColor">
                    Automobile
                  </Button>
                  <Button className="fsize14 textprimary  cust-textColor">
                    E-commerce
                  </Button>
                </div>
              </Card>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="grid lg:grid-cols-3   md:grid-cols-2 gap-10 mt-6">
              <div className="box-shdw rounded-2xl p-4 pt-10">
                <div className="py-4">
                  <Image
                    className="h-24 lg:mb-4 md:mb-4 mb-2 mx-auto"
                    // src={getImage(card['company logo'])}
                    src={data ? getImage(data['']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="img"
                  />
                </div>
                <div className="flex justify-evenly items-center ">
                  {/* <p className="fsize20 font-semibold cust-textColor">
                        {card['company name']}
                      </p> */}
                  <p className="fsize20 font-semibold cust-textColor">Bcoz</p>
                </div>
                <div className="py-4">
                  <p className="fsize14 text-gray cust-textColor text-center">
                    {/* {card['companydescription']} */}
                    Company Description
                  </p>
                </div>
                <div className="flex justify-center gap-8 mt-4">
                  <div className="text-center">
                    <p className="fsize14 text-gray cust-textColor mb-1">
                      Stage
                    </p>
                    {/* <p className="font-medium  cust-textColor">{card['stageOfStartup']}</p> */}
                    <p className="font-medium  cust-textColor">Test</p>
                  </div>
                  <div className="text-center">
                    <p className="fsize14 text-gray cust-textColor mb-1">
                      Round Size
                    </p>
                    {/* <p className="font-medium  cust-textColor">{card['fundingamount']}</p> */}
                    <p className="font-medium  cust-textColor">45</p>
                  </div>
                  <div className="text-center">
                    <p className="fsize14 text-gray cust-textColor mb-1">
                      Team
                    </p>
                    <AvatarGroup size="sm" max={2}>
                      <Avatar
                        name="Ryan Florence"
                        src="https://bit.ly/ryan-florence"
                      />
                      <Avatar
                        name="Segun Adebayo"
                        src="https://bit.ly/sage-adebayo"
                      />
                      <Avatar
                        name="Kent Dodds"
                        src="https://bit.ly/kent-c-dodds"
                      />
                      <Avatar
                        name="Prosper Otemuyiwa"
                        src="https://bit.ly/prosper-baba"
                      />
                      <Avatar
                        name="Christian Nwamba"
                        src="https://bit.ly/code-beast"
                      />
                    </AvatarGroup>
                  </div>
                </div>
                <div className="pt-6 pb-4 flex justify-center gap-8">
                  <Button className="fsize14 textprimary  cust-textColor">
                    {/* {card['companylocation']} */}
                    Company Location
                  </Button>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default FeatureDetailCard1;
