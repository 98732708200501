import {
  Button,
  Input,
  Select,
  Textarea,
  RadioGroup,
  HStack,
  Radio,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import axios from "axios";
import {
  getFormSchema,
  getSchemaByFormId,
  getInputByFormId,
} from "../../../../redux/forms/forms-action";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { object } from "yup";
import { values } from "lodash";
import form from "../../../../apis/client/Forms/form";
import { getData } from "../../../../redux/forms/forms-action";
const MasterForm = ({ pageName, formId, close, edit }) => {
  // const { pageName, formId, close, edit } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const sectionName = useSelector((state) => state.cms.sectionNames[pageName]);
  // let getallField = useSelector((state) => state.Forms.FormInput);
  let getFormName = useSelector((state) => state.Forms.Forms);
  // let getSchemaForm = useSelector((state) => state.Forms.FormSchemaByFormId);

  let userToken = useSelector((state) => state.AuthDetails.user.token);

  const [formFields, setForm] = useState({});
  const [change, setChange] = useState(false);
  const [getallField, setGetallField] = useState("");
  const [getSchemaForm, setGetSchemaForm] = useState("");

  useEffect(() => {
    async function mount() {
      if (formId) {
        let payload = {
          formId: formId,
        };

        let formDetails = await dispatch(getInputByFormId(payload));
        setGetallField(formDetails);
        let formSchema = await dispatch(getSchemaByFormId(payload));
        setGetSchemaForm(formSchema);
        console.log(getSchemaForm, formSchema, "schema 1");
      } else {
        let formSection = sectionName.filter(
          (x) => x.sectionId === "4457530739"
        );

        if (formSection.length && formSection[0].form) {
          let payload = {
            formId: formSection[0].form,
          };

          let formDetails = await dispatch(getInputByFormId(payload));
          await setGetallField(formDetails);
          let formSchema = await dispatch(getSchemaByFormId(payload));
          await setGetSchemaForm(formSchema);
          console.log(getSchemaForm, formSchema, "schema");
        }
      }
    }
    mount();
    initializeData();
  }, [dispatch]);
  console.log(formFields, "from value");
  //getallField[0].field.value,

  const handleClear = () => {
    if (getallField) {
      let tempForm = {};
      getallField.map((x) => {
        if (x.inputType != "button") tempForm[x.value] = "";
      });
      setForm(tempForm);
    }
  };

  const handleSubmit = async () => {
    let payload = {
      projectId: process.env.REACT_APP_ID,
      projectName: process.env.REACT_APP_COMMUNITY_NAME,
    };
    payload = { ...formFields, ...payload };

    let key = Object.keys(payload);
    let value = Object.values(payload);
    let formData = new FormData();

    console.log(payload, "cloudpl");
    for (let i = 0; i < key.length; i++) {
      if (value[i].type && value[i].type === "files") {
        for (let j = 0; j < value[i].files.length; j++) {
          formData.append(key[i], value[i].files[j]);
        }
      } else {
        formData.append(key[i], value[i]);
      }
    }

    let output = await axios.post(
      `${process.env.REACT_APP_BASE_URL}forms/create/${getSchemaForm[0].schemaName}`,
      formData,

      {
        headers: {
          "community-name": process.env.REACT_APP_COMMUNITY_NAME,
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (output && output.data) {
      handleClear();
      close(false);
    }
  };

  const handleChange = async (e, field, item) => {
    let fields;

    if (item === "file") {
      fields = { ...formFields, [field]: e.target.files[0] };
    } else if (item === "files") {
      fields = {
        ...formFields,
        [field]: { type: "files", files: e.target.files },
      };
    } else if (item === "radio") {
      fields = { ...formFields, [field]: e };
    } else {
      fields = { ...formFields, [field]: e.target.value };
      // console.log('ENtered else');
    }

    setForm(fields);
  };

  const initializeData = async () => {
    if (edit && Object.keys(edit).length) {
      let payload = {
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        _id: edit._id,
      };
      let formData = formFields;
      let keys = Object.keys(formData);
      let data = await dispatch(getData(edit.form, payload));
      console.log(keys, "edit detail");
      if (data && data.result && data.result.length) {
        for (let i = 0; i < keys.length; i++) {
          formData[keys[i]] = data.result[0][keys[i]];
        }

        setForm(formData);
      }
    }
  };

  return (
    <section className="w-full">
      <div>
        <div className="m-auto lg:grid md:grid grid-cols-2 gap-4 ">
          {getallField
            ? getallField.map((item, index) => {
                return (
                  <div className="lg:pb-0 md:pb-0 pb-3 ">
                    {item.inputType !== "button" && (
                      <p
                        id="1437369070"
                        className=" lg:pb-2 md:pb-2 pb-1 clr-191E22"
                      >
                        {item.label}
                      </p>
                    )}
                    {item.inputType == "input" && (
                      <Input
                        className="p-2 inp-bdr w-full bg-white"
                        placeholder={item.placeholder}
                        value={formFields[item.value]}
                        maxlength={item.maxLength}
                        minlength={item.minLength}
                        onChange={(e) => {
                          handleChange(e, item.value);
                        }}
                      />
                    )}
                    {item.inputType == "multiline" && (
                      <Textarea
                        className="p-2 inp-bdr w-full"
                        placeholder={item.placeholder}
                        value={formFields[item.value]}
                        maxlength={item.maxLength}
                        minlength={item.minLength}
                        onChange={(e) => {
                          handleChange(e, item.value);
                        }}
                      />
                    )}
                    {item.inputType == "phone" && (
                      <Input
                        className="p-2 inp-bdr w-full"
                        placeholder={item.placeholder}
                        value={formFields[item.value]}
                        maxlength={item.maxLength}
                        minlength={item.minLength}
                        onChange={(e) => {
                          handleChange(e, item.value);
                        }}
                      />
                    )}
                    {item.inputType == "email" && (
                      <Input
                        type="email"
                        className="p-2 inp-bdr w-full"
                        placeholder={item.placeholder}
                        value={formFields[item.value]}
                        maxlength={item.maxLength}
                        minlength={item.minLength}
                        onChange={(e) => {
                          handleChange(e, item.value);
                        }}
                      />
                    )}
                    {item.inputType == "url" && (
                      <Input
                        className="p-2 inp-bdr w-full"
                        placeholder={item.placeholder}
                        value={formFields[item.value]}
                        maxlength={item.maxLength}
                        minlength={item.minLength}
                        onChange={(e) => {
                          handleChange(e, item.value);
                        }}
                      />
                    )}
                    {item.inputType === "singleselect" && (
                      <Select
                        placeholder={item.placeholder}
                        value={formFields[item.value]}
                        className="fsize14"
                        onChange={(e) => {
                          handleChange(e, item.value);
                        }}
                      >
                        {item.enum.map((i) => {
                          return <option value={i}>{i}</option>;
                        })}
                      </Select>
                    )}
                    {item.inputType === "radiobutton" && (
                      <RadioGroup
                        defaultValue="Itachi"
                        onChange={(e) => {
                          handleChange(e, item.value, "radio");
                        }}
                      >
                        <HStack spacing="24px">
                          {item.enum.map((e) => {
                            return <Radio value={e}>{e}</Radio>;
                          })}
                        </HStack>
                      </RadioGroup>
                    )}
                    {item.inputType === "imagefile" && (
                      <input
                        type="file"
                        className="p-2 inp-bdr w-full"
                        placeholder={item.placeholder}
                        onChange={(e) => {
                          handleChange(e, item.value, "file");
                        }}
                      />
                    )}
                    {item.inputType === "file" && (
                      <input
                        type="file"
                        className="p-2 inp-bdr w-full"
                        placeholder={item.placeholder}
                        onChange={(e) => {
                          handleChange(e, item.value, "file");
                        }}
                      />
                    )}

                    {item.inputType === "multipleimagefile" && (
                      <input
                        type="file"
                        multiple
                        className="p-2 inp-bdr w-full"
                        placeholder={item.placeholder}
                        onChange={(e) => {
                          handleChange(e, item.value, "files");
                        }}
                      />
                    )}
                    {/* {item.inputType == '"button"' && (
                      <Input
                        type="file"
                        className="p-2 inp-bdr w-full"
                        placeholder={item.placeholder}
                        value={item.value}
                        maxlength={item.maxLength}
                        minlength={item.minLength}
                      />
                    )} */}
                  </div>
                );
              })
            : ""}
        </div>

        {getallField
          ? getallField.map((item) => {
              return (
                <div className=" text-center">
                  {item.inputType == "button" && (
                    <Button
                      onClick={() => handleSubmit()}
                      colorScheme="primary"
                      className=" font-semibold bg-da8f2c py-2 px-4 mt-6"
                    >
                      <span> {item.label}</span>
                    </Button>
                  )}
                </div>
              );
            })
          : "no-data"}
      </div>
    </section>
  );
};

export default MasterForm;
