import React, { useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Button } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

const AboutSection5 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section className="container mx-auto lg:px-12 md:px-12 lg:py-28 md:py-20 px-4 py-20">
      <div className="lg:flex md:flex items-center">
        <div className="lg:w-5/12 md:w-5/12">
          <div className="cards-home relative rounded-2xl bg-primary cust-bgColor lg:w-3/4 md:w-11/12">
            <div
              id="1734576216"
              className="dynamicStyle2 lg:ml-8 md:ml-8  cards-img  z-10 rounded-2xl w-full"
            >
              <Image
                src={data ? getImage(data['1734576216']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="cards-img object-cover absolute-cards lg:absolute md:absolute z-20 rounded-2xl"
                alt="cards"
              />
            </div>
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/SBI-foundation/SBI-foundation/home/Frame%20125.png"
              className="w-24 h-20 absolute bottom-0 left-0"
              alt="side"
            />
          </div>
        </div>
        <div
          className="lg:w-7/12  md:w-7/12 md:pl-10  lg:pl-0 lg:mt-0 md:mt-0 mt-6"
          data-aos="fade-up"
          data-aos-once="true"
          delay={1200}
        >
          <h2
            id="9397996354"
            className="dynamicStyle fsize34 sm-fsize20 font-semibold text-primary cust-textColor"
          >
            {data ? data['9397996354'] : 'About Us'}
          </h2>
          <p
            id="7507347486"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['7507347486']
                : ' The SBI Foundation, or State Bank of India Foundation, is the corporate social responsibility (CSR) arm of the State Bank of',
            }}
            className="dynamicStyle textColor-light fsize16 sm-fsize14 mt-2"
          ></p>

          <Button
            variant="outline"
            colorScheme="primary"
            className="dynamicStyle mt-6 text-primary cust-textColor sm-fsize14"
            size="lg"
            id="0631228998"
          >
            <span
              className="flex items-center"
              onClick={() => handleClick('0631228998')}
            >
              {data ? data['0631228998'] : 'Know More'}

              <FeatherIcon
                className="text-primary cust-textColor ml-3"
                size={18}
                icon="arrow-right"
              />
            </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default AboutSection5;
