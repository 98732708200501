import { Card } from '@chakra-ui/react';
import React from 'react';

const FeatureCard10 = () => {
  return (
    <div className="lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8">
      <div className="container mx-auto">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center lg:mb-16 md:mb-12 mb-8">
          <h2 className="font-semibold cust-textColor header2 fsize30 sm-fsize20 mb-2 relative inline-block">
            Welcome to My World
          </h2>

          <p className="fsize16 sm-fsize14 textColor-light">
            I'm a young tech enthasist and entrepreneur who love to take risk. I
            grew up in a tech family in New York City.
          </p>
        </div>

        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
          <Card  className="item-card">
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="w-12 h-12 bg-gray"
              />
            </div>

            <div className="">
              <h3 className="fsize24 sm-fsize20 cust-textColor mt-2 lg:mb-4 md:mb-3 mb-2 font-medium">
                Creativity
              </h3>

              <p className="fsize16 sm-fsize14 textColor-light">
                Duis aute irure dolor in it esse cillum fugiat nulla pari erunt
                mollit anim id est laborum.
              </p>
            </div>
          </Card>

          <Card className="item-card">
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="w-12 h-12 bg-gray"
              />
            </div>

            <div className="">
              <h3 className="fsize24 sm-fsize20 cust-textColor mt-2 lg:mb-4 md:mb-3 mb-2 font-medium">
                Creativity
              </h3>

              <p className="fsize16 sm-fsize14 textColor-light">
                Duis aute irure dolor in it esse cillum fugiat nulla pari erunt
                mollit anim id est laborum.
              </p>
            </div>
          </Card>

          <Card className="item-card">
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="w-12 h-12 bg-gray"
              />
            </div>

            <div className="">
              <h3 className="fsize24 sm-fsize20 cust-textColor mt-2 lg:mb-4 md:mb-3 mb-2 font-medium">
                Creativity
              </h3>

              <p className="fsize16 sm-fsize14 textColor-light">
                Duis aute irure dolor in it esse cillum fugiat nulla pari erunt
                mollit anim id est laborum.
              </p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard10;
