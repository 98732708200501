import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Button, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const Wishlist = ({ pageName }) => {
  const wishListProduct = useSelector(state => state.products.Products);
  console.log(wishListProduct, 'wishListProduct');
  const data = useSelector(state => state.cms.allSections[pageName]);

  return (
    <>
      <div className="container mx-auto lg:px-12 md:px-12 px-5 lg:py-16 md:py-12 py-8">
        <h2
          id="2799259493"
          className="dynamicStyle fsize40 sm-fsize24 font-semibold lg:mb-8 md:mb-8 mb-4"
        >
          {data ? data['2799259493'] : 'Wishlist'}
        </h2>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 lg:gap-6 md:gap-6 gap-1 mb-20">
          {wishListProduct &&
          wishListProduct.result &&
          wishListProduct.result.length > 0
            ? wishListProduct.result.map(item => {
                return (
                  <div className="w-full wishlist-card my-2 cursor-pointer">
                    <div className="w-full relative">
                      <Image
                        src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                        alt="product-card-image"
                        className="wishlist-card-image"
                      />
                      <div className="absolute top-3 right-3 wishlist-x-icon">
                        <FeatherIcon icon="x" className="cursor-pointer p-1" />
                      </div>
                    </div>
                    <div className="p-4">
                      <h2 className="fsize18 sm-fsize16 lineClamp1 mb-1 font-medium">
                        {item.name}
                      </h2>
                      <div className="flex items-center gap-2 lg:mb-2 md:mb-2 mb-1">
                        <p className="fsize18 sm-fsize16 font-medium">
                          {item.productAtt &&
                          item.productAtt.length > 0 &&
                          item.productAtt[0].discount
                            ? `${
                                `${
                                  item.productAtt && item.productAtt.length > 0
                                    ? item.productAtt[0].price
                                    : ''
                                }` -
                                (`${
                                  item.productAtt && item.productAtt.length > 0
                                    ? item.productAtt[0].price
                                    : ''
                                }` *
                                  `${
                                    item.productAtt &&
                                    item.productAtt.length > 0
                                      ? item.productAtt[0].discount
                                      : ''
                                  }`) /
                                  100
                              }`
                            : `${
                                item.productAtt && item.productAtt.length > 0
                                  ? item.productAtt[0].price
                                  : ''
                              }`}
                        </p>

                        {item.productAtt &&
                        item.productAtt.length > 0 &&
                        item.productAtt[0].discount ? (
                          <div className="flex gap-2">
                            {' '}
                            <p className="fsize14 sm-fsize12 font-medium strikethrough">
                              {item.productAtt && item.productAtt.length > 0
                                ? item.productAtt[0].price
                                : ''}
                            </p>
                            <p className="fsize14 sm-fsize12 font-medium">
                              {item.productAtt && item.productAtt.length > 0
                                ? item.productAtt[0].discount
                                : ''}
                              % off
                            </p>{' '}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="flex items-center gap-2 ">
                        <FeatherIcon
                          icon="star"
                          className="cursor-pointer w-4 h-4"
                        />
                        <FeatherIcon
                          icon="star"
                          className="cursor-pointer w-4 h-4"
                        />
                        <FeatherIcon
                          icon="star"
                          className="cursor-pointer w-4 h-4"
                        />
                        <FeatherIcon
                          icon="star"
                          className="cursor-pointer w-4 h-4"
                        />
                        <FeatherIcon
                          icon="star"
                          className="cursor-pointer w-4 h-4"
                        />
                      </div>
                      <div className=" w-full lg:pt-5 md:pt-5 pt-3">
                        <Button
                          colorScheme="primary"
                          variant="outline"
                          className="dynamicStyle fsize14 sm-fsize14 w-full py-6"
                          id="8810560553"
                        >
                          {data ? data['8810560553'] : 'View Details'}
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })
            : 'no-data'}
        </div>
      </div>
    </>
  );
};

export default Wishlist;
