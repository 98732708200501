import { Button, Image, Input } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { getData } from "../../../redux/forms/forms-action";
import { useDispatch } from "react-redux";
const CareersSection = () => {
  const options = [
    { value: "search", label: "search" },
    { value: "search", label: "search" },
  ];
  const dispatch = useDispatch();
  const [cards, setCards] = useState([]);
  const getJobDetails = async () => {
    let payload = {
      projectId: process.env.REACT_APP_ID,
      projectName: process.env.REACT_APP_COMMUNITY_NAME,
    };
    let data = await dispatch(getData("careers-job_postings", payload));
    console.log(data, "careerscard");
    setCards(data.result);
  };

  const navigate = useNavigate();
  const handleClick = (jobId) => {
    navigate(`/careers-details?ji=${jobId}`);
  };
  useEffect(() => {
    getJobDetails();
  }, []);
  return (
    <section>
      <div>
        {/* <div className="cust-bgf5f5f5">
          <div className="container mx-auto">
            <div className="lg:flex md:flex block w-full items-end lg:px-12 md:px-10 px-5 lg:py-16 md:py-16 py-10">
              <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-5 gap-2 lg:w-9/12 md:w-11/12 w-full">
                <div className="">
                  <div className="fsize14 mb-2 font-medium">
                    Filter by location
                  </div>
                  <div className="w-full">
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      options={options}
                      className="basic-multi-select"
                    ></Select>
                  </div>
                </div>
                <div className="">
                  <div className="fsize14 mb-2 font-medium">
                    Filter by departments
                  </div>
                  <div className="w-full">
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      options={options}
                      className="basic-multi-select"
                    ></Select>
                  </div>
                </div>
                <div className="">
                  <div className="fsize14 mb-2 font-medium">Filter by Jobs</div>
                  <div className="w-full">
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      options={options}
                      className="basic-multi-select"
                    ></Select>
                  </div>
                </div>
                <div className="">
                  <div className="fsize14 mb-2 font-medium">
                    Filter by experience
                  </div>
                  <div className="w-full">
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      options={options}
                      className="basic-multi-select"
                    ></Select>
                  </div>
                </div>
              </div>
              <div className="lg:w-2/12 md:w-2/12 w-full lg:ml-5 md:ml-3 ml-0 lg:mt-0 md:mt-0 mt-5">
                <Button colorScheme="primary" px={5}>
                  Filter jobs
                </Button>
              </div>
            </div>
          </div>
        </div> */}
        <div className="bg-white">
          <div className="container mx-auto lg:px-12 md:px-10 px-5 lg:py-12 md:py-12 py-10">
            <div className="lg:flex md:flex block justify-between w-full items-center ">
              <h4 className="fsize34 md-fsize24 sm-fsize21 font-semibold text-dark lg:mb-0 md:mb-0 mb-3">
                Browse open position
              </h4>
              {/* <Button colorScheme="primary" variant="outline" px={10}>
                  Show all
                </Button> */}
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:mt-8 md:mt-5 mt-4 gap-6">
              {cards.map((card) => {
                return (
                  <div className="bg-white p-6 rounded-lg cust-shadow">
                    <h6 className="fsize20 md-fsize17 sm-fsize17 font-medium">
                      {card.jobtitle}
                    </h6>
                    <p className="mt-1 fsize14 sm-fsize13 text-primary font-medium">
                      {card.departmentname}
                    </p>
                    <p className="mt-3 fsize14 sm-fsize13 text-gray">
                      {card.joblocation}
                    </p>
                    <Button
                      onClick={() => handleClick(card._id)}
                      colorScheme="primary"
                      className="fsize14"
                      mt={4}
                      px={6}
                    >
                      Apply
                    </Button>
                  </div>
                );
              })}
            </div>
            <div className="text-center mt-9">
              <p className="fsize18 md-fsize16 sm-fsize15 text-gray">
                Hang on !! Couldn't find an appropiate match for your profile?
                You can still apply, our team is expanding into different
                verticals and we would love to expore your profile.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareersSection;
