import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";

const AboutSection9 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section className="py-8 lg:py-20">
      <div className="container mx-auto px-4 lg:px-12 md:px-10">
        <div className="flex cust-reverse items-center">
          <div
            className="lg:w-6/12 md:w-6/12 lg:mt-0 mt-4 lg:pr-10"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <h2
              className="fsize30 sm-fsize20 font-semibold textprimary cust-textColor dynamicStyle"
              id="3725827689"
            >
              {data ? data["3725827689"] : "Lorem Ipsum"}
            </h2>
            <p
              className="fsize16 sm-fsize14 textColor-light mt-3 dynamicStyle"
              id="0414522803"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["0414522803"]
                  : " publishing and graphic design, Lorem ipsum is a placeholder text",
              }}
            ></p>
          </div>
          <div className="lg:w-6/12 md:w-6/12 w-full lg:pl-10">
            <div id="6162638972" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data["6162638972"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="creating"
                className="creating-img object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection9;
