import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

const FeatureCard1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section className="container mx-auto  lg:px-12 md:px-12 px-5 py-8">
      <div className="text-center lg:w-1/2 md:w-2/3 w-full mx-auto lg:mb-12 md:mb-12 mb-6">
        <h3
          id="5663514396"
          className="dynamicStyle sm-fsize14 font-semibold text-primary cust-textColor mb-2 "
        >
          {data ? data['5663514396'] : 'Lorem Ipsum'}
        </h3>
        <h1
          id="5361268763"
          className="dynamicStyle cust-textColor fsize30 sm-fsize20 font-semibold mb-2 "
        >
          {data ? data['5361268763'] : 'Lorem Ipsum'}
        </h1>
        <p
          id="0288217792"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['0288217792']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className="dynamicStyle sm-fsize14 textColor-light"
        ></p>
      </div>
      <div className="">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper p-4"
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 2.5,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 2.5,
              spaceBetween: 30,
            },
            505: {
              slidesPerView: 1.2,
              spaceBetween: 15,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 15,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 15,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <Card className="w-full flex flex-col items-center card lg:px-8 md:px-8 px-4 lg:py-12 md:py-10 py-6">
              <div
                id="0433984666"
                className="dynamicStyle2 w-full flex justify-center"
              >
                <Image
                  src={data ? getImage(data['0433984666']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt=""
                  className="object-cover card-img mb-6 "
                />
              </div>
              <div className="lg:pb-4 md:pb-4 pb-2">
                <h1
                  id="3629366432"
                  className="fsize24 text-center dynamicStyle sm-fsize18 font-semibold cust-textColor"
                >
                  {data ? data['3629366432'] : 'Lorem ipsum'}
                </h1>
              </div>
              <p
                id="2113895211"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['2113895211']
                    : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                }}
                className="dynamicStyle fsize15 sm-fsize14 text-center textColor-light"
              ></p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className="w-full flex flex-col items-center card lg:px-8 md:px-8 px-4 lg:py-12 md:py-10 py-6">
              <div
                id="1614625710"
                className="dynamicStyle2 w-full flex justify-center"
              >
                <Image
                  src={data ? getImage(data['1614625710']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt=""
                  className="object-cover card-img mb-6"
                />
              </div>
              <div className="lg:pb-4 md:pb-4 pb-2">
                <h1
                  id="6700026023"
                  className="dynamicStyle text-center fsize24 sm-fsize18 cust-textColor font-semibold"
                >
                  {data ? data['6700026023'] : 'Lorem ipsum'}
                </h1>
              </div>
              <p
                id="4531718678"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['4531718678']
                    : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                }}
                className="dynamicStyle fsize15 sm-fsize14 text-center textColor-light"
              ></p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className="w-full flex flex-col items-center card lg:px-8 md:px-8 px-4 lg:py-12 md:py-10 py-6">
              <div
                id="2918580236"
                className="dynamicStyle2  w-full flex justify-center"
              >
                <Image
                  src={data ? getImage(data['2918580236']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt=""
                  className="object-cover card-img mb-6"
                />
              </div>
              <div className="lg:pb-4 md:pb-4 pb-2">
                <h1
                  id="4566195358"
                  className="dynamicStyle text-center fsize24 sm-fsize18 cust-textColor font-semibold"
                >
                  {data ? data['4566195358'] : 'Lorem ipsum'}
                </h1>
              </div>
              <p
                id="7982900107"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['7982900107']
                    : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                }}
                className="dynamicStyle fsize15 sm-fsize14 text-center textColor-light"
              ></p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className="w-full flex flex-col items-center card lg:px-8 md:px-8 px-4 lg:py-12 md:py-10 py-6">
              <div
                id="4439227822"
                className="dynamicStyle2 w-full flex justify-center"
              >
                <Image
                  src={data ? getImage(data['4439227822']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt=""
                  className="object-cover card-img mb-6"
                />
              </div>
              <div className="lg:pb-4 md:pb-4 pb-2">
                <h1
                  id="7629444802"
                  className="dynamicStyle text-center fsize24 sm-fsize18 cust-textColor"
                >
                  {data ? data['7629444802'] : 'Lorem ipsum'}
                </h1>
              </div>
              <p
                id="4289041177"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['4289041177']
                    : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                }}
                className="dynamicStyle fsize15 sm-fsize14 text-center cust-textColor"
              ></p>
            </Card>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default FeatureCard1;
