import React, { useEffect } from 'react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const CardImage3 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="container mx-auto  lg:py-16 md:py-16 py-8 lg:px-12 md:px-6 px-5">
      <div className="text-center lg:w-1/2 md:w-3/4 w-full mx-auto lg:mb-16 md:mb-8">
        <h3
          id="2632409156"
          className="dynamicStyle fsize24 sm-fsize16 font-semibold clr-ddd9d8 lg:mb-2"
        >
          {data ? data['2632409156'] : 'Lorem Ipsum'}
        </h3>
        <h1
          id="4015122783"
          className="dynamicStyle fsize40 sm-fsize24 font-semibold lg:mb-8 md:mb-8 mb-6"
        >
          {data ? data['4015122783'] : 'Lorem Ipsum'}
        </h1>
        <p
          id="6941527127"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['6941527127']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className="dynamicStyle fsize18 sm-fsize14 lg:mb-0 md:mb-0 mb-4"
        ></p>
      </div>
      <div className="container mx-auto ">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <div id="3314586501" className="dynamicStyle2 w-full p-2">
              <Image
                src={data ? getImage(data['3314586501']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt=""
                className="object-cover card-img2"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div id="1046183491" className="dynamicStyle2 w-full p-2">
              <Image
                src={data ? getImage(data['1046183491']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt=""
                className="object-cover card-img2"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div id="6156641127" className="dynamicStyle2 w-full p-2">
              <Image
                src={data ? getImage(data['6156641127']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt=""
                className="object-cover card-img2"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div id="7759433649" className="dynamicStyle2 w-full p-2">
              <Image
                src={data ? getImage(data['7759433649']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt=""
                className="object-cover card-img2"
              />
            </div>
          </SwiperSlide>
          {/* <SwiperSlide >
            <div id='' className="w-full p-2">
              <Image
                src={data ? getImage(data['']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt=""
                className="object-cover card-img2"
              />
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </section>
  );
};

export default CardImage3;
