import { Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const AboutSection6 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <section className="relative chairman-h">
      <div id="3411776711" className="dynamicStyle2 w-full cus-featuredynamic">
        <Image
          src={data ? getImage(data['3411776711']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          className="w-full chairman-h lg:block hidden"
          alt="chairman-h"
        />
      </div>
      <div className=" lg:absolute top-0 left-0 w-full">
        <div className="flex cust-reverse  items-center container mx-auto lg:px-12 lg:py-28 md:py-28 md:px-10 px-4 py-12">
          <div
            className="lg:w-7/12  md:w-7/12 lg:pr-12 md:pr-10 lg:mt-0 md:mt-0 mt-6"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <h2
              id="5516522235"
              className="dynamicStyle fsize30 sm-fsize18 font-semibold text-primary"
            >
              {data ? data['5516522235'] : 'Message from Chairman'}
            </h2>
            <p
              id="0893052102"
              className=" fsize22 sm-fsize12 col-9e mt-1 lg:mt-2 dynamicStyle"
            >
              {data ? data['0893052102'] : 'Dinesh Khara'}
            </p>
            <p
              className="fsize18 md-fsize16 sm-fsize14 col-79 mt-2 lg:mt-3 dynamicStyle"
              id="0652394764"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['0652394764']
                  : ' The SBI Foundation, or State Bank of India Foundation, is the corporate social responsibility (CSR) arm of the State Bank of',
              }}
            ></p>

            <Button
              variant="outline"
              colorScheme="primary"
              className="text-primary sm-fsize14 mt-6 dynamicStyle"
              size="lg"
              id="8489854633"
            >
              <span className='flex items-center' onClick={() => handleClick('8489854633')}>
              {data ? data['8489854633'] : 'button'}
              
              <FeatherIcon
                className="text-primary ml-3"
                size={18}
                icon="arrow-right"
              />
              </span>
            </Button>
          </div>
          <div className="lg:w-5/12 lg:flex lg:justify-around md:w-5/12 ">
            <div className="cards-home relative rounded-2xl bg-primary cust-bgColor lg:w-3/4 md:w-11/12 ">
              <div
                id="3607127858"
                className="dynamicStyle2 lg:ml-8 md:ml-8  cards-img  z-10 rounded-2xl w-full"
              >
                <Image
                  src={data ? getImage(data['3607127858']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="cards-img object-cover absolute-cards lg:absolute md:absolute z-20 rounded-2xl"
                  alt="cards"
                />
              </div>
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/SBI-foundation/SBI-foundation/home/Frame%20125.png"
                className="w-24 h-20 absolute bottom-0 left-0"
                alt="side"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection6;
