import { Image } from '@chakra-ui/react';
import React from 'react';

const CardImage6 = () => {
  return (
    <div className="container mx-auto lg:px-12 md:px-10 px-5  lg:py-12 md:py-10 py-8 ">
      <p className="text-secondary fsize24 md:fsize20 fsize16 font-medium text-center">
        Services
      </p>
      <h2 className="text-primary fsize36 md:fsize24 sm-fsize20 fomt-semibold text-center">
        Expert Solutions Across Industries
      </h2>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-8 gap-4 w-full lg:mt-16 md:mt-12 mt-8">
        <div className="">
          <div className="w-full mb-2">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="card-image"
              id=""
              className="h-20 w-20 mx-auto object-cover"
            />
          </div>
          <div className="w-full">
            <h2 className="lg:w-2/3 mx-auto mb-3 text-center text-primary font-semibold fsize20 ms:fsize18 sm-fsize16 leading-6">
              Marine Engeneering
            </h2>
            <p className="text-secondary text-center fsize16 sm-fsize14">
              As Marine Engine Specialist we service and repair a wide range of
              engines and related equipment.
            </p>
          </div>
        </div>
        <div className="">
          <div className="w-full mb-2">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="card-image"
              id=""
              className="h-20 w-20 mx-auto object-cover"
            />
          </div>
          <div className="w-full">
            <h2 className="lg:w-2/3 mx-auto mb-3 text-center text-primary font-semibold fsize20 ms:fsize18 sm-fsize16 leading-6">
              Marine Engeneering
            </h2>
            <p className="text-secondary text-center fsize16 sm-fsize14">
              As Marine Engine Specialist we service and repair a wide range of
              engines and related equipment.
            </p>
          </div>
        </div>
        <div className="">
          <div className="w-full mb-2">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="card-image"
              id=""
              className="h-20 w-20 mx-auto object-cover"
            />
          </div>
          <div className="w-full">
            <h2 className="lg:w-2/3 mx-auto mb-3 text-center text-primary font-semibold fsize20 ms:fsize18 sm-fsize16 leading-6">
              Marine Engeneering
            </h2>
            <p className="text-secondary text-center fsize16 sm-fsize14">
              As Marine Engine Specialist we service and repair a wide range of
              engines and related equipment.
            </p>
          </div>
        </div>
        <div className="">
          <div className="w-full mb-2">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="card-image"
              id=""
              className="h-20 w-20 mx-auto object-cover"
            />
          </div>
          <div className="w-full">
            <h2 className="lg:w-2/3 mx-auto mb-3 text-center text-primary font-semibold fsize20 ms:fsize18 sm-fsize16 leading-6">
              Marine Engeneering
            </h2>
            <p className="text-secondary text-center fsize16 sm-fsize14">
              As Marine Engine Specialist we service and repair a wide range of
              engines and related equipment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardImage6;
