import { CalendarIcon } from '@chakra-ui/icons';
import React from 'react';
import { Button, Select ,Image } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

const Appointment = () => {
  return (
    <div className="lg:p-6 md:p-6 lg:border md:border rounded-3px">
      <h2 className=" fsize20 font-semibold pb-4 clr-606060">My Booking</h2>

      <div className="w-full lg:flex gap-2 ">
        <div className="lg:w-3/5">
          <div className="grid grid-cols-3 gap-2">
            <div className="bg-f4f5f6 p-3 rounded-lg relative">
              <p className="fsize24 font-semibold mb-2">97</p>
              <p className="fsize11 lg:w-1/2 md:w-2/3 lineClamp2">
                Assignmenet Completed
              </p>
              <div className="absolute top-2 right-3">
                <CalendarIcon />
              </div>
            </div>
            <div className="bg-f4f5f6 p-3 rounded-lg relative">
              <p className="fsize24 font-semibold mb-2">97</p>
              <p className="fsize11 lg:w-1/2 md:w-2/3 lineClamp2">
                Assignmenet Completed
              </p>
              <div className="absolute top-2 right-3">
                <CalendarIcon />
              </div>
            </div>
            <div className="bg-f4f5f6 p-3 rounded-lg relative">
              <p className="fsize24 font-semibold mb-2">97</p>
              <p className="fsize11 lg:w-1/2 md:w-2/3 lineClamp2">
                Assignmenet Completed
              </p>
              <div className="absolute top-2 right-3">
                <CalendarIcon />
              </div>
            </div>
          </div>
          <div className="lg:flex md:flex bg-white p-2 rounded-lg mt-4 shadow-md">
            <div className="flex gap-2 lg:w-2/5 md:w-1/2 py-2">
              <div className="py-2 w-1/3 flex lg:justify-start md:justify-start justify-center ">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="profile-img"
                  className="w-16 h-16 rounded-full"
                />
              </div>
              <div className="w-2/3 flex flex-col justify-center">
                <h2 className="fsize14 font-semibold uppercase">
                  MANISH SHARMA
                </h2>
                <div>
                  <p className="clr-9c9c9c fsize11 font-medium uppercase">
                    Woodland
                  </p>

                  <p className="clr-9c9c9c fsize11 font-medium uppercase">
                    5* 120 REVIEW
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-3/5 md:w-1/2 grid grid-cols-2 gap-y-4 gap-x-2">
              <input
                type="time"
                className="fsize12 font-semibold w-full px-2 lg:py-0 md:py-0 py-2 rounded-md border"
              />

              <input
                type="date"
                className="fsize12 font-semibold w-full px-2 lg:py-0 md:py-0 py-2 rounded-md border"
              />

              <div className=" flex justify-between items-center px-2 lg:py-0 md:py-0 py-2 bg-f4f5f6 rounded-md">
                <p className="fsize12 font-semibold">Online</p>
                <div className="online-dot mr-1"></div>
              </div>
              <div className="flex justify-between items-center px-2 lg:py-0 md:py-0 py-2 bg-f4f5f6 rounded-md">
                <p className="fsize12 font-semibold">Online</p>
                <div className="online-dot mr-1"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-2/5 lg:mt-0 md:mt-0 mt-6">
          <div className="w-full bg-f4f5f6 rounded-lg p-3">
            <h2 className="fsize14 font-semibold mb-4">Upcoming Booking</h2>
            <div className="flex bg-white p-2 rounded-lg ">
              <div className="text-start w-full py-2">
                <div className="py-2 w-full flex justify-start ">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="profile-img"
                    className="w-16 h-16 rounded-full"
                  />
                </div>
                <div>
                  <h2 className="fsize14 font-semibold uppercase">
                    MANISH SHARMA
                  </h2>
                  <div className="flex justify-start items-center">
                    <p className="clr-9c9c9c fsize11 font-medium uppercase">
                      Woodland 5*
                    </p>

                    <p className="clr-9c9c9c fsize11 font-medium uppercase">
                      120 REVIEW
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full pl-2">
                <div className="bg-f4f5f6 rounded-md w-full">
                  <input
                    type="time"
                    className="fsize12 font-semibold w-full px-2 py-2 rounded-md bg-f4f5f6"
                  />
                </div>
                <div className="mt-4 bg-f4f5f6 rounded-md w-full">
                  <input
                    type="date"
                    className="fsize12 font-semibold w-full px-2 py-2 rounded-md bg-f4f5f6"
                  />
                </div>
                <div className="mt-4 flex justify-between items-center px-2 py-2 bg-f4f5f6 rounded-md">
                  <p className="fsize12 font-semibold">Online</p>
                  <div className="online-dot mr-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-12">
        <div className="lg:flex md:flex justify-between items-center">
          <h2 className="fsize14 font-semibold lg:pb-0 md:pb-0 pb-2">
            My Booking
          </h2>
          <div className="flex gap-2 w-30 md-w-60 sm-wfull sel">
            <Select placeholder="Status " px="1">
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </Select>
            <Select placeholder="Status">
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </Select>
            <Select placeholder="Status ">
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </Select>
          </div>
        </div>
        <div className="mt-4">
          <TableContainer>
            <Table variant="simple" className="border ">
              <Thead>
                <Tr className="bg-f4f5f6">
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th>Date</Th>
                  <Th>Time</Th>
                  <Th>Type</Th>
                  <Th>Preferred Location</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td className="fsize12">1</Td>
                  <Td className="fsize12">Manish Sharma</Td>
                  <Td className="fsize12">25.4.2024</Td>
                  <Td className="fsize12">7.15pm-8.15pm</Td>
                  <Td className="fsize12">Online</Td>
                  <Td className="fsize12">Online</Td>
                  <Td className="fsize12">
                    <div className="flex w-full gap-2">
                      <Button
                        colorScheme="green"
                        py="1"
                        height="1.5rem"
                        className="fsize12 "
                      >
                        Accept
                      </Button>
                      <Button
                        colorScheme="red"
                        py="1"
                        height="1.5rem"
                        className="fsize12 "
                      >
                        Reject
                      </Button>
                    </div>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
