import { Card, useColorMode } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import VanillaTilt from 'vanilla-tilt';

const FeatureCard13 = () => {
  const { colorMode, toggleColorMode} = useColorMode()
  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll('.shake'), {
      max: 25,
      speed: 400,
      glare: true,
      'max-glare': 0.5,
    });

    VanillaTilt.init(document.querySelectorAll('.shakeDark'), {
      max: 25,
      speed: 400,
      glare: true,
      'max-glare': 0.5,
    });
  }, []);

  return (
    <div
    className= {
      colorMode === "light" ? "bg-e2ecf6 lg:px-12 md:px-8 px-5 lg:py-24 md:py-16 py-12" : "textColor-light lg:px-12 md:px-8 px-5 lg:py-24 md:py-16 py-12"
    }
     >
      <div className="container mx-auto">
      <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center lg:mb-16 md:mb-12 mb-8">
          <h2 className="font-semibold cust-textColor header2 fsize30 sm-fsize20 mb-2 relative inline-block">
            Welcome to My World
          </h2>

          <p className="fsize16 sm-fsize14 textColor-light">
            I'm a young tech enthasist and entrepreneur who love to take risk. I
            grew up in a tech family in New York City.
          </p>
        </div>
        <div className="w-full grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-x-8 gap-x-12">
          <Card
            className="px-12 py-12 bg-white rounded-xl border-e0e0e0 lg:mb-12 md:mb-8 shake "
            data-tilt
          >
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="w-12 h-12 bg-gray"
              />
            </div>

            <div className="">
              <h3 className="fsize24 md:fsize20 sm-fsize18 mt-2 lg:mb-4 md:mb-3 mb-2 font-medium cust-textColor">
                Creativity
              </h3>

              <p className="fsize16 sm-fsize14 textColor-light">
                Duis aute irure dolor in it esse cillum fugiat nulla pari erunt
                mollit anim id est laborum.
              </p>
            </div>
          </Card>

          <Card
            className="px-12 py-12 bg-white rounded-xl border-e0e0e0 lg:mt-12 md:mt-8 shake "
            data-tilt
          >
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="w-12 h-12 bg-gray"
              />
            </div>

            <div className="">
              <h3 className="fsize24 md:fsize20 sm-fsize18 mt-2 lg:mb-4 md:mb-3 mb-2 font-medium cust-textColor">
                Creativity
              </h3>

              <p className="fsize16 sm-fsize14 textColor-light">
                Duis aute irure dolor in it esse cillum fugiat nulla pari erunt
                mollit anim id est laborum.
              </p>
            </div>
          </Card>

          <Card
            className="px-12 py-12 bg-white rounded-xl border-e0e0e0 lg:mb-12 md:mb-8 shake "
            data-tilt
          >
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="w-12 h-12 bg-gray"
              />
            </div>

            <div className="">
              <h3 className="fsize24 md:fsize20 sm-fsize18 mt-2 lg:mb-4 md:mb-3 mb-2 font-medium cust-textColor">
                Creativity
              </h3>

              <p className="fsize16 sm-fsize14 textColor-light">
                Duis aute irure dolor in it esse cillum fugiat nulla pari erunt
                mollit anim id est laborum.
              </p>
            </div>
          </Card>

          <Card
            className="px-12 py-12 bg-white rounded-xl border-e0e0e0 lg:mt-12 md:mt-8 shake "
            data-tilt
          >
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="w-12 h-12 bg-gray"
              />
            </div>

            <div className="">
              <h3 className="fsize24 md:fsize20 sm-fsize18 mt-2 lg:mb-4 md:mb-3 mb-2 font-medium cust-textColor">
                Creativity
              </h3>

              <p className="fsize16 sm-fsize14 textColor-light">
                Duis aute irure dolor in it esse cillum fugiat nulla pari erunt
                mollit anim id est laborum.
              </p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard13;
