import { Button, Image } from '@chakra-ui/react';
import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux/es/exports';

const SimilarProductSection = ({ pageName }) => {
  const [heartFill, setHeartFill] = useState(false);
  const handleHeartFill = () => {
    setHeartFill(!heartFill);
  };

  const allSimilarProduct = useSelector(state => state.products.Products);
  console.log(allSimilarProduct, 'allSimilarProduct');
  const data = useSelector(state => state.cms.allSections[pageName]);
  return (
    <>
      <div className="container mx-auto lg:px-12 md:px-6 px-5 lg:py-20 md:py-12 py-8w-full sm-pt-8">
        <h2
          id="7066829237"
          className="dynamicStyle fsize24 md-fsize22 sm-fsize16 "
        >
          {data ? data['7066829237'] : 'Lorem Ipsum'}
        </h2>
        <h1
          id="0227091204"
          className="dynamicStyle font-semibold fsize40 md-font-28 sm-fsize20 lg:mb-12 md:mb-6 mb-4"
        >
          {data ? data['0227091204'] : 'Similar Item You Might Like'}
        </h1>
        <div className="">
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            className="mySwiper"
            breakpoints={{
              1536: {
                slidesPerView: 3.8,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 3.8,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3.8,
                spaceBetween: 30,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              820: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {allSimilarProduct &&
            allSimilarProduct.result &&
            allSimilarProduct.result.length > 0
              ? allSimilarProduct.result.reverse().map(e => {
                  return (
                    <SwiperSlide>
                      <div className="w-full product-card my-2">
                        <div className="w-full relative">
                          <Image
                            src={`${process.env.REACT_APP_STORAGE_URL}${e.image}`}
                            alt="product-card-image"
                            className="product-card-image"
                          />
                          <div className="absolute top-4 right-4">
                            <FeatherIcon
                              icon="heart"
                              className="cursor-pointer"
                              fill={heartFill ? 'red' : 'white'}
                              color={heartFill ? 'red' : 'black'}
                              onClick={() => handleHeartFill()}
                            />
                          </div>
                        </div>
                        <div className="p-4">
                          <h2 className="fsize18 sm-fsize16 lineClamp1 lg:mb-1 font-medium">
                            {e.name}
                          </h2>
                          <div className="flex items-center gap-2 lg:mb-2">
                            <p className="fsize18 sm-fsize16 font-medium">
                              {e.productAtt &&
                              e.productAtt.length > 0 &&
                              e.productAtt[0].discount
                                ? `${
                                    `${
                                      e.productAtt && e.productAtt.length > 0
                                        ? e.productAtt[0].price
                                        : ''
                                    }` -
                                    (`${
                                      e.productAtt && e.productAtt.length > 0
                                        ? e.productAtt[0].price
                                        : ''
                                    }` *
                                      `${
                                        e.productAtt && e.productAtt.length > 0
                                          ? e.productAtt[0].discount
                                          : ''
                                      }`) /
                                      100
                                  }`
                                : `${
                                    e.productAtt && e.productAtt.length > 0
                                      ? e.productAtt[0].price
                                      : ''
                                  }`}
                            </p>

                            {e.productAtt &&
                            e.productAtt.length > 0 &&
                            e.productAtt[0].discount ? (
                              <div className="flex gap-2">
                                {' '}
                                <p className="fsize14 sm-fsize12 font-medium strikethrough">
                                  {e.productAtt && e.productAtt.length > 0
                                    ? e.productAtt[0].price
                                    : ''}
                                </p>
                                <p className="fsize14 sm-fsize12 font-medium">
                                  {e.productAtt && e.productAtt.length > 0
                                    ? e.productAtt[0].discount
                                    : ''}
                                  % off
                                </p>{' '}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          {/* <div className="flex items-center gap-2 lg:mb-2 mb-1">
                            <p className="fsize18 sm-fsize16 font-medium">
                              ₹123456
                            </p>
                            <p className="fsize14 font-medium strikethrough">
                              ₹123456
                            </p>
                            <p className="fsize14 font-medium">(10% off)</p>
                          </div> */}
                          <div className="flex items-center gap-2 ">
                            <FeatherIcon
                              icon="star"
                              className="cursor-pointer w-4 h-4"
                            />
                            <FeatherIcon
                              icon="star"
                              className="cursor-pointer w-4 h-4"
                            />
                            <FeatherIcon
                              icon="star"
                              className="cursor-pointer w-4 h-4"
                            />
                            <FeatherIcon
                              icon="star"
                              className="cursor-pointer w-4 h-4"
                            />
                            <FeatherIcon
                              icon="star"
                              className="cursor-pointer w-4 h-4"
                            />
                          </div>
                          <div className=" w-full lg:pt-5 pt-3">
                            <Button
                              colorScheme="primary"
                              variant="outline"
                              py="6"
                              className="dynamicStyle fsize14 w-full"
                              id="6355335498"
                            >
                              {data ? data['6355335498'] : 'View Details'}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              : 'no-data'}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SimilarProductSection;
