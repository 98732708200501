import React, { useEffect } from "react";
import { Image, Button } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const LeftTextRightImg = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const navigate = useNavigate();
  const link = "https://example.com";
  useEffect(() => {
    Aos.init();
  }, []);

  const handleClick = (id) => {
    if (CTALink && CTALink[id]) {
      console.log(CTALink[id]);
      const isHttpsLink =
        CTALink[id].includes("https") || CTALink[id].includes("www");
      if (isHttpsLink) {
        window.open(`${CTALink[id]}`, "_blank");

        console.log(link, "cmsdatacheck");
      } else {
        navigate(`/${CTALink[id]}`);
      }
    }
  };
  return (
    <section>
      {" "}
      <div className="lg:flex md:flex container mx-auto  sm-reverse  lg:py-16 md:py-12 lg:px-12 md:px-12 px-5">
        <div
          className="lg:w-1/2 md:w-1/2 lg:py-12 md:py-0 py-8 relative zindex9 flex flex-col justify-center"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <h2
            id="2491847836"
            className="dynamicStyle fsize36 md-fsize28 sm-fsize22  text-primary cust-textColor font-bold"
          >
            {data
              ? data["2491847836"]
              : "FINED YOUR BEST COACH NEAREST TO YOU AND BOOK YOUR SLOT EASILY"}
          </h2>
          <p
            id="8410324712"
            dangerouslySetInnerHTML={{
              __html: data ? data["8410324712"] : "no-data",
            }}
            className="dynamicStyle textColor-light fsize16 sm-fsize14 lg:mt-4 md:mt-4 mt-2"
          ></p>
          <Button
            width="180px"
            height="50px"
            className="dynamicStyle bg-primary cust-bgColor lg:mt-8 md:mt-8 zindex9 relative mt-6 textwhite sm-btntextwh"
            id="8752237435"
          >
            <span onClick={() => handleClick("8752237435")}>
              {" "}
              {data ? data["8752237435"] : "Know more"}
            </span>
          </Button>
        </div>
        <div className="lg:w-1/2 md:w-1/2 lg:pl-8 md:pl-8  lg:mt-0 md:mt-0 mt-12">
          <div
            id="8498811174"
            className="dynamicStyle2 relative zindex9 w-full"
          >
            <Image
              src={data ? getImage(data["8498811174"]) : ""}
              alt="heroImage"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              className="w-full sm-h250px minh450px object-cover rounded-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeftTextRightImg;
