import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import React from 'react';
import FeatherIcon from 'feather-icons-react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

const UpcomingEvents = () => {
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
      <div className="lg:flex md:flex justify-between w-full gap-12">
        <p className="font-semibold fsize28 md:fsize24 sm-fsize20 w-full lg:mb-0 md:mb-0 mb-2">
          Hi, <span className="text-primary">User</span>
        </p>
        <div className="relative w-full">
          <InputGroup size="md">
            <Input
              py="0.75rem"
              pr="4.5rem"
              height="fit-content"
              placeholder="search for a turf nearby"
              className="fsize14"
            />
            <InputRightElement width="5.5rem" my="0.25rem">
              <Button size="md" mr={1} px="1rem" className="fsize14">
                Search
              </Button>
            </InputRightElement>
          </InputGroup>
        </div>
      </div>
      <div className="lg:mt-12 md:mt-8 mt-6 lg:flex md:flex flex justify-between w-full">
        <h1 className="font-semibold fsize32 md-fsize24 sm-fsize20">
          Nearby Turf
        </h1>
        <div className="flex items-center">
          <p className="cursor-pointer text-primary fsize16">View More</p>
          <FeatherIcon
            className="text-primary ml-2"
            size={18}
            icon="arrow-right"
          />
        </div>
      </div>
      <div className="mt-6">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 2.6,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 2.6,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 2.6,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <div className="borderall flex w-full rounded-lg">
              <div className="w-2/5">
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="turf-name"
                  className="turf_Image rounded-l-lg object-cover"
                />
              </div>
              <div className="p-4 w-3/5">
                <h2 className="fsize18 font-semibold">Turf Name</h2>
                <div className="flex items-center">
                  <FeatherIcon
                    className="text-primary mr-1"
                    size={12}
                    icon="map-pin"
                  />
                  <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                </div>
                <p className="fsize12 lineClamp3 clr-7c7c7c mt-1">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  in nobis accusantium
                </p>
                <p className="text-primary fsize14 mt-1">
                  Rs. 1500 <span className="clr-7c7c7c  fsize12">onwards</span>
                </p>
                <div className="mt-1">
                  <Button colorScheme="primary" className="w-100per fsize14">
                    Book Now
                  </Button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="borderall flex w-full rounded-lg">
              <div className="w-2/5">
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="turf-name"
                  className="turf_Image rounded-l-lg object-cover"
                />
              </div>
              <div className="p-4 w-3/5">
                <h2 className="fsize18 font-semibold">Turf Name</h2>
                <div className="flex items-center">
                  <FeatherIcon
                    className="text-primary mr-1"
                    size={12}
                    icon="map-pin"
                  />
                  <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                </div>
                <p className="fsize12 lineClamp3 clr-7c7c7c mt-1">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  in nobis accusantium
                </p>
                <p className="text-primary fsize14 mt-1">
                  Rs. 1500 <span className="clr-7c7c7c  fsize12">onwards</span>
                </p>
                <div className="mt-1">
                  <Button colorScheme="primary" className="w-100per fsize14">
                    Book Now
                  </Button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="borderall flex w-full rounded-lg">
              <div className="w-2/5">
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="turf-name"
                  className="turf_Image rounded-l-lg object-cover"
                />
              </div>
              <div className="p-4 w-3/5">
                <h2 className="fsize18 font-semibold">Turf Name</h2>
                <div className="flex items-center">
                  <FeatherIcon
                    className="text-primary mr-1"
                    size={12}
                    icon="map-pin"
                  />
                  <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                </div>
                <p className="fsize12 lineClamp3 clr-7c7c7c mt-1">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  in nobis accusantium
                </p>
                <p className="text-primary fsize14 mt-1">
                  Rs. 1500 <span className="clr-7c7c7c  fsize12">onwards</span>
                </p>
                <div className="mt-1">
                  <Button colorScheme="primary" className="w-100per fsize14">
                    Book Now
                  </Button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default UpcomingEvents;
