import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import FeatherIcon from 'feather-icons-react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

const GallerySection5 = ({ pageName }) => {
  const [check, SetCheck] = useState(1);
  const memeber = [
    { id: 1, name: 'All' },
    { id: 2, name: 'Programing' },
    { id: 3, name: 'Development' },
    { id: 4, name: 'Design' },
    { id: 5, name: 'Application' },
  ];
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  const {
    isOpen: isopenprofile1,
    onOpen: onopenprofile1,
    onClose: oncloseprofile1,
  } = useDisclosure();
  return (
    <div className="lg:px-12 md:px-8 px-4 lg:py-20 md:py-16 py-8">
      <Modal size="4xl" isOpen={isopenprofile1} onClose={oncloseprofile1}>
        <ModalOverlay />
        <ModalContent className="custom custom-marl">
          <ModalCloseButton className="textColor-light" />
          <div class="gap-4 lg:items-center wordf lg:pt-12 md:pt-12 pt-12 pb-6 lg:px-8 md:px-4 px-4">
            <div class="">
              <img
                class="w-full lg:h-60 md:h-52 rounded-lg object-cover"
                src={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="man"
              />
            </div>
            <div className="lg:mt-5 md:mt-3 mt-2 lg:flex md:flex items-start">
              <div className="lg:w-8/12 md:w-8/12 lg:mr-4">
                <h2 class="fsize22 md-fsize20 sm-fsize18 lg:my-1 font-bold text-black cust-textColor">
                  MR. Jhon Deo
                </h2>
                <p class="fsize15 md-fsize14 textColor-light sm-fsize14 text-black font-semibold mt-1 lg:mt-2 lg:leading-7 md:leading-7 leading-6">
                  Mr Jhon Deo joined as the Managing Director & Chief Executive
                  Officer on 27th July 2022. Prior to this, he was posted as the
                  Deputy General Manager (Industrial Relations) in the HR
                  Department of , Corporate Centre, Mumbai, for 4 years.
                  <br />
                  With a graduate degree in Science with honours in Statistics
                  from Patna University, he joined in the year 1990. He began
                  his banking career with postings in small rural places in the
                  Santhal Parganas of Jharkhand. Over the last 32 years, he has
                  served in various positions, heading branches in rural and
                  urban centres, handling IT projects, retail business
                  portfolio, cross-selling business, and manning critical
                  administrative positions in the State Bank of India.
                </p>
              </div>
              <div className="lg:w-4/12 md:w-4/12 bg-fa cust-bgColor lg:p-3 md:p-3 lg:mt-0 md:mt-0 mt-4 grid grid-cols-1 gap-3">
                <div className="lg:flex md:flex items-center gap-2">
                  <p className="font-medium fsize14 cust-textColor">Project-type:</p>
                  <p className="font-semibold text-dark fsize16 textColor-light">UI Designer</p>
                </div>
                <div className="lg:flex md:flex items-center gap-2">
                  <p className="font-medium fsize14 cust-textColor">Client:</p>
                  <p className="font-semibold text-dark fsize16 textColor-light">Jhon Deo</p>
                </div>
                <div className="lg:flex md:flex items-center gap-2">
                  <p className="font-medium fsize14 cust-textColor">Duration:</p>
                  <p className="font-semibold text-dark fsize16 textColor-light">2 weeks</p>
                </div>
                <div className="lg:flex md:flex items-center gap-2">
                  <p className="font-medium fsize14 cust-textColor">Task:</p>
                  <p className="font-semibold text-dark fsize16 textColor-light">
                    UI/UX Frontend
                  </p>
                </div>
                <div className="lg:flex md:flex items-center gap-2">
                  <p className="font-medium fsize14 cust-textColor">Budget:</p>
                  <p className="font-semibold text-dark fsize16 textColor-light">$2000</p>
                </div>
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
      <div className="container mx-auto">
        <h4
          className="fsize30 sm-fsize20 cust-textColor font-semibold text-center dynamicStyle"
          id="7787544779"
        >
          lorem ipsum
        </h4>
        <div className="lg:w-7/12 mx-auto">
          <p
            id="0412520574"
            className="fsize16 sm-fsize14 font-medium text-center mt-2 textColor-light dynamicStyle"
          >
            lorem ipsum
          </p>
        </div>
        <div className="lg:mt-8 mt-5">
          <div className="mx-auto lg:w-8/12">
            <Swiper
              spaceBetween={25}
              breakpoints={{
                1536: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                820: {
                  slidesPerView: 2,
                  spaceBetween: 12,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 12,
                },
                640: {
                  slidesPerView: 1.9,
                  spaceBetween: 12,
                },
                425: {
                  slidesPerView: 1.9,
                  spaceBetween: 12,
                },
                320: {
                  slidesPerView: 1.9,
                  spaceBetween: 12,
                },
              }}
              className="mySwiper"
            >
              {memeber &&
                memeber.map((e, i) => {
                  return (
                    <SwiperSlide key={i} onClick={() => SetCheck(e.id)}>
                      <p
                        className={
                          check == e.id
                            ? 'fsize14 cust-bgColor bg-primary text-white py-2 px-2 text-center cursor-pointer'
                            : 'fsize14 border py-2 px-2 text-center cursor-pointer cust-textColor'
                        }
                      >
                        {e.name}
                      </p>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
          <div className="lg:mt-12 mt-8 lg:w-11/12 mx-auto">
            {check === 1 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6">
                  <div
                    className="w-full rounded-lg overflow-hidden relative gradient"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className='px-5 text-gradient w-full'>
                      <h6 className='fsize20 absolute bottom-0 left-0 z-10 text-white lg:p-4 p-2 font-semibold'>Lorem Ipsum</h6>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            {check === 2 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6">
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative lg:col-span-2 md:col-span-2 "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            {check === 3 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6">
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative lg:row-span-2 md:row-span-2"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="h-teams7 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 h-teams7 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            {check === 4 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6">
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative lg:col-span-2 md:col-span-2 "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            {check === 5 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6">
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  lg:row-span-2 md:row-span-2"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="h-teams7 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 h-teams7 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection5;
