import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FaQuoteRight } from 'react-icons/fa';
import ReactStars from 'react-rating-stars-component';
import { Autoplay, Navigation } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';
import { Card, Image } from '@chakra-ui/react';
const Testimonial9 = ({ pageName }) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  let getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  console.log(getAllTestimonial, 'check-test');
  useEffect(() => {
    async function mount() {
      await dispatch(getTestimonal());
    }
    mount();
  }, []);
  return (
    <div className="lg:px-12 md:px-12 px-5 lg:py-12 md:py-12 py-8 container m-auto">
      <div class="text-center pt-5 lg:pb-12 md:pb-10 pb-5">
        <h2 class="fsize30 sm-fsize20 text-primary cust-textColor font-semibold">
          Testimonial{' '}
        </h2>
        <div className="flex justify-center ">
          <p className="w-70 sm-fsize14 textColor-light">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
            natus tenetur quo, tempore corrupti blanditiis id illo vero magnam
            quod.
          </p>
        </div>
      </div>
      <Swiper
        spaceBetween={30}
        navigation={true}
        modules={[Navigation]}
        className="h-swipertestimonial mySwiper lg:p-2 p-1"
        breakpoints={{
          1536: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 3,
            spaceBetween: 12,
          },
          820: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          325: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
        }}
      >
        {getAllTestimonial &&
        getAllTestimonial.data &&
        getAllTestimonial.data.result &&
        getAllTestimonial.data.result.length > 0
          ? getAllTestimonial.data.result.map((testimonial, index) => {
              return (
                <SwiperSlide>
                  <div className="lg:mt-10 md:mt-10 mt-12 relative">
                    <Card className="rounded-xl bg-white cust-shadow pt-10 pb-8 px-4 pb-4 relative z-30">
                      <p className="text-center sm-fsize14 lineClam4 textColor-light">
                      {testimonial.testimonial}
                      </p>
                      <div className="flex gap-4 justify-center items-center lg:pt-4 md:pt-4 pt-2">
                        <div className="">
                          <Image
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                            alt="testimonial"
                            className="img-wh-70 rounded-full object-cover"
                          />
                        </div>
                        <div>
                          <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          {testimonial.name}
                          </p>
                          {/* <p className="fsize14 sm-fsize12">Developer</p> */}
                        </div>
                      </div>
                    </Card>
                    <div className="topc-abs z-40 flex justify-center w-full">
                      <div className="img-wh-65 rounded-full flex items-center justify-center bg-primary cust-bgColor">
                        <FaQuoteRight size="23" className="text-white" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })
          : 'No Data'}
      </Swiper>
    </div>
  );
};
export default Testimonial9;
