import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import React from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { FaFileMedical } from 'react-icons/fa';
import { Image, Input, useDisclosure } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
} from '@chakra-ui/react';
import UploaderBox from '../../../../../../../components/UploaderBox';

const Gridcards = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div className="container mx-auto lg:px-12 md:px-6 px-5 lg:py-12 md:py-18 py-6">
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h5 className="text-white fsize17">Add Folder</h5>
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <div className="py-3 px-1">
              <div className="">
                <label className="text-primary">Sub-Group Name</label>
                <Input placeholder="Enter" className="mt-2" />
              </div>
              <div className="mt-3">
                <label className="text-primary">Add Cover Image</label>
                <div className="mt-2">
                  <UploaderBox accept="image/*" />
                </div>
              </div>
              <div className="flex items-center gap-3 mt-5">
                <Button colorScheme="primary" className="w-full">
                  Submit
                </Button>
                <Button colorScheme="secondary" className="w-full">
                  Cancel
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="lg:mb-16 md:mb-12 mb-8">
        <div className="w-full lg:flex md:flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <h2 className="fsize20 sm-fsize16 text-primary" id="">
              Knowledge Mangamement Portal
            </h2>
            <EditIcon className="text-primary " />
          </div>
        </div>

        <div className="lg:mt-8 md:mt-6 mt-6 ">
          <Swiper
            spaceBetween={20}
            className="mySwiper py-2"
            breakpoints={{
              1536: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              325: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
          >
            <div className="swiper-button  image-swiper-button-prevz bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-nextz bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="relative" onClick={onOpen}>
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="KMP"
                  id=""
                  className="w-full lg:h-32 md:h-32 h-32 object-cover rounded-lg"
                />
                <div className="absolute top-0 left-0 lg:h-32 md:h-32 h-32 w-full flex justify-center items-center">
                  <FeatherIcon icon="plus" size="24" className="textprimary" />
                </div>
                <p className="mt-3 text-center text-primary fsize14">
                  Add Sub-Group
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="lg:mb-16 md:mb-12 mb-8">
        <div className="flex gap-2 items-center">
          <h2 className="fsize20 sm-fsize16 text-primary" id="">
            Processes
          </h2>
          <EditIcon className="text-primary " />
        </div>

        <div className="lg:mt-8 md:mt-6 mt-4 ">
          <Swiper
            spaceBetween={20}
            className="mySwiper py-2"
            breakpoints={{
              1536: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              325: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
          >
            <div className="swiper-button  image-swiper-button-prevz bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-nextz bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="relative" onClick={onOpen}>
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="KMP"
                  id=""
                  className="w-full lg:h-32 md:h-32 h-32 object-cover rounded-lg"
                />
                <div className="absolute top-0 left-0 lg:h-32 md:h-32 h-32 w-full flex justify-center items-center">
                  <FeatherIcon icon="plus" size="24" className="textprimary" />
                </div>
                <p className="mt-3 text-center text-primary fsize14">
                  Add Sub-Group
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="lg:mb-16 md:mb-12 mb-8">
        <div className="flex gap-2 items-center">
          <h2 className="fsize20 sm-fsize16 text-primary" id="">
            Information
          </h2>
          <EditIcon className="text-primary " />
        </div>

        <div className="lg:mt-8 md:mt-6 mt-4 ">
          <Swiper
            spaceBetween={20}
            className="mySwiper py-2"
            breakpoints={{
              1536: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              325: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
          >
            <div className="swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="relative" onClick={onOpen}>
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="KMP"
                  id=""
                  className="w-full lg:h-32 md:h-32 h-32 object-cover rounded-lg"
                />
                <div className="absolute top-0 left-0 lg:h-32 md:h-32 h-32 w-full flex justify-center items-center">
                  <FeatherIcon icon="plus" size="24" className="textprimary" />
                </div>
                <p className="mt-3 text-center text-primary fsize14">
                  Add Sub-Group
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div>
        <div className="flex gap-2 items-center">
          <h2 className="fsize20 sm-fsize16 text-primary" id="">
            Others
          </h2>
          <EditIcon className="text-primary " />
        </div>

        <div className="lg:mt-8 md:mt-6 mt-4 ">
          <Swiper
            spaceBetween={20}
            className="mySwiper py-2"
            breakpoints={{
              1536: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              325: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
          >
            <div className="swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="relative" onClick={onOpen}>
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="KMP"
                  id=""
                  className="w-full lg:h-32 md:h-32 h-32 object-cover rounded-lg"
                />
                <div className="absolute top-0 left-0 lg:h-32 md:h-32 h-32 w-full flex justify-center items-center">
                  <FeatherIcon icon="plus" size="24" className="textprimary" />
                </div>
                <p className="mt-3 text-center text-primary fsize14">
                  Add Sub-Group
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Gridcards;
