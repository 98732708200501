import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image, Button } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Gallery = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section>
      <div className="container mx-auto  lg:py-16 md:py-10 py-8 lg:px-12 md:px-6 px-5">
        <div className="text-center ">
          <div className="lg:w-3/4 md:w-4/5 w-full mx-auto">
            <h3
              id="1134054994"
              className="dynamicStyle cust-textColor fsize30 sm-fsize20 uppercase  text-primary font-semibold"
            >
              {data ? data['1134054994'] : '  What is our goals'}
            </h3>
            <p
              id="6297891318"
              dangerouslySetInnerHTML={{
                __html: data ? data['6297891318'] : 'no-data',
              }}
              className="dynamicStyle textColor-light text-gray fsize16 sm-fsize14"
            ></p>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:px-12 md:px-12 px-4 lg:mt-12 mt-6 lg:gap-8 md:gap-4 gap-4">
            <div className="" data-aos="zoom-in" data-aos-once="true">
              <div
                id="0497833108"
                className="dynamicStyle2 lg:mb-0 md:mb-0 mb-6 w-full"
              >
                <Image
                  src={data ? getImage(data['0497833108']) : ''}
                  className="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="sections"
                />
              </div>
              <div
                id="5735483205"
                className="dynamicStyle2 lg:mb-0 md:mb-0 mb-6 lg:mt-4 md:mt-4 w-full"
              >
                <Image
                  src={data ? getImage(data['5735483205']) : ''}
                  className="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="sections"
                />
              </div>
            </div>
            <div className="lg:mt-12" data-aos="zoom-in" data-aos-once="true">
              <div
                id="1129031166"
                className="dynamicStyle2 lg:mb-0 md:mb-0 mb-6 w-full"
              >
                <Image
                  src={data ? getImage(data['1129031166']) : ''}
                  className="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="sections"
                />
              </div>
              <div
                id="8803829542"
                className="dynamicStyle2 lg:mb-0 md:mb-0 mb-6  lg:mt-4 md:mt-4 w-full"
              >
                <Image
                  src={data ? getImage(data['8803829542']) : ''}
                  className="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="sections"
                />
              </div>
            </div>
            <div className="" data-aos="zoom-in" data-aos-once="true">
              <div id="5867196401" className="dynamicStyle2 w-full">
                <Image
                  src={data ? getImage(data['5867196401']) : ''}
                  className="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="sections"
                />
              </div>
              <div id="1487085918" className="dynamicStyle2 mt-4 w-full">
                <Image
                  src={data ? getImage(data['1487085918']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover"
                  alt="sections"
                />
              </div>
            </div>
            <div className="lg:mt-12" data-aos="zoom-in" data-aos-once="true">
              <div
                id="3353631293"
                className="dynamicStyle2 lg:mb-0 md:mb-0 mb-6 w-full"
              >
                <Image
                  src={data ? getImage(data['3353631293']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover"
                  alt="sections"
                />
              </div>
              <div
                id="4843289427"
                className="dynamicStyle2 lg:mb-0 md:mb-0 mb-6 lg:mt-4 md:mt-4 w-full"
              >
                <Image
                  src={data ? getImage(data['4843289427']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover"
                  alt="sections"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
