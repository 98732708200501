import { Input, Textarea } from '@chakra-ui/react';
import React from 'react';
import { FaPhone, FaPhoneAlt } from 'react-icons/fa';
import { FiMail, FiMapPin } from 'react-icons/fi';

const ContactDetails4 = () => {
  return (
    <div className="lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8">
      <div className="container mx-auto">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center lg:mb-16 md:mb-12 mb-8">
          <h2 className="font-semibold header2 cust-textColor fsize30 sm-fsize20 mb-2 relative inline-block">
            Get in touch
          </h2>

          <p className="fsize16 sm-fsize14 textColor-light">
            I'm a young tech enthasist and entrepreneur who love to take risk. I
            grew up in a tech family in New York City.
          </p>
        </div>

        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 lg:px-20 md:px-12 pr-4">
          <div className="flex">
            <div className="icon-box flex items-center justify-center">
              <FiMapPin/>
            </div>

            <div className="">
              <h4 className="font-bold fsize18 sm-fsize16 cust-textColor">Address</h4>

              <p className="fsize16 sm-fsize14 textColor-light">Andheri East, Mumbai</p>
            </div>
          </div>

          <div className="flex">
            <div className="icon-box flex items-center justify-center">
              <FaPhoneAlt className=''/>
            </div>

            <div className="">
              <h4 className="font-bold fsize18 sm-fsize16 cust-textColor">Phone</h4>

              <a href="tel:123 456 7890">
                <p className="fsize16 sm-fsize14 textColor-light">+91 123 456 7890</p>
              </a>
            </div>
          </div>

          <div className="flex">
            <div className="icon-box flex items-center justify-center">
              <FiMail/>
            </div>

            <div className="">
              <h4 className="font-bold fsize18 sm-fsize16 cust-textColor">Email</h4>

              <a href="mailto:abcd@abcd.com">
                <p className="fsize16 sm-fsize14 textColor-light">abcd@abcd.com</p>
              </a>
            </div>
          </div>
        </div>

        <div className="w-full lg:flex md:flex gap-6 lg:pt-20 md:pt-16 pt-12">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.8095320612306!2d72.85291797495573!3d19.116009950715732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7170e07b235%3A0xb59deaaa5b1ce0e6!2sBRAINED!5e0!3m2!1sen!2sin!4v1712748084423!5m2!1sen!2sin"
              className="iframe-size"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="lg:w-1/2 md:w-1/2 w-full lg:pt-0 md:pt-0 pt-6">
            <h3 className="fsize36 sm-fsize24 font-bold lg:mb-8 md:mb-6 mb-4 cust-textColor">
              Write me a message
            </h3>

            <div className="w-full lg:flex md:flex gap-6 mb-4">
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <label htmlFor=""></label>

                <Input placeholder="Name" className="form-control" />
              </div>

              <div className="lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-4">
                <Input placeholder="Email" className="form-control" />
              </div>
            </div>

            <div className="mb-6">
              <Textarea
                placeholder="Here is a sample placeholder"
                className="form-control"
              />
            </div>

            <div className="">
              <a href="#" className="buttonAnimate">
                Send Message
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails4;
