import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux/es/exports';
import { Image, Card } from '@chakra-ui/react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';
import { useDispatch } from 'react-redux';
import testimonial from '../../../apis/client/testimonial';
import Rating from 'react-rating';
import FeatherIcon from 'feather-icons-react';
// import testimonial from '../../../apis/client/testimonial';
const Testimonial1 = ({ pageName }) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  let getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  console.log(getAllTestimonial, 'check-test');
  useEffect(() => {
    async function mount() {
      await dispatch(getTestimonal());
    }
    mount();
    Aos.init();
  }, []);
  return (
    <>
      {getAllTestimonial ? (
        <section className="container mx-auto lg:px-12 md:px-12 px-5 lg:py-16 md:py-16 py-8">
          <div className=" lg:flex md:flex gap-8">
            <div
              className="lg:w-2/5 md:w-2/5 lg:pr-8"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <h3
                id="4534821466"
                className="dynamicStyle fsize18 md-fsize16 sm-fsize14 font-semibold text-primary cust-textColor lg:mb-2"
              >
                {data ? data['4534821466'] : 'Lorem Ipsum'}
              </h3>
              <h1
                id="1318038280"
                className="dynamicStyle cust-textColor fsize36 md-fsize24 sm-fsize20 font-semibold lg:mb-6 md:mb-2"
              >
                {data ? data['1318038280'] : 'Lorem Ipsum'}
              </h1>
              <p
                id="9294925836"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['9294925836']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle textColor-light fsize16 md-fsize14 sm-fsize14"
              ></p>
            </div>
            <div className="lg:w-3/5 md:w-3/5">
              <Swiper
                spaceBetween={30}
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                className="mySwiper container relative px-2 overflow-x-hidden pb-2 newswiper"
                autoplay={{
                  delay: '1000',
                }}
                navigation={{
                  nextEl: '.image-swiper-button-nexts',
                  prevEl: '.image-swiper-button-prevs',
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  991: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                  },
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                  },
                  325: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                  },
                }}
              >
                <div className="swiper-button image-swiper-button-prevs cursor-pointer">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div className="swiper-button image-swiper-button-nexts cursor-pointer">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>
                {getAllTestimonial &&
                getAllTestimonial.data &&
                getAllTestimonial.data.result &&
                getAllTestimonial.data.result.length > 0
                  ? getAllTestimonial.data.result.map((testimonial, index) => {
                      return (
                        <SwiperSlide>
                          <Card className="testimonial-card cust-shadow" key={index}>
                            <p className="fsize16 sm-fsize14 pb-8 textColor-light">
                              {testimonial.testimonial}
                            </p>
                            <div className="flex justify-between items-center">
                              <div className="flex items-center lg:gap-6 md:gap-4 gap-2 ">
                                <div className="testimonial-div1 flex items-center">
                                  <Image
                                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                    src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                                    alt=""
                                    className="testimonial-img rounded-full object-cover"
                                  />
                                </div>

                                <div className="w-full text-start">
                                  <h2 className="fsize20 md-fsize16 sm-fsize14 font-semibold cust-textColor">
                                    {testimonial.name}
                                  </h2>
                                  <div className="lg:hidden md:block block md:mt-2 mt-1">
                                    <Rating
                                      initialRating={testimonial.rating}
                                      readonly={true}
                                      emptySymbol={
                                        <FeatherIcon
                                          icon="star"
                                          className="mr-1 md-star-18 sm-star-16"
                                          size={22}
                                        />
                                      }
                                      fullSymbol={
                                        <FeatherIcon
                                          icon="star"
                                          className="mr-1 fillstroke sg"
                                          size={22}
                                        />
                                      }
                                      fractions={2}
                                    />
                                  </div>
                                  {/* <p className="fsize18 sm-fsize14">
                                    CEO & Co Founder
                                  </p> */}
                                  <div className=" mt-0">
                                    <Rating
                                      initialRating={5}
                                      readonly={true}
                                      emptySymbol={
                                        <FeatherIcon
                                          icon="star"
                                          className="mr-1"
                                          size={22}
                                        />
                                      }
                                      fullSymbol={
                                        <FeatherIcon
                                          icon="star"
                                          className="mr-1 fillstroke"
                                          size={22}
                                        />
                                      }
                                      fractions={2}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </SwiperSlide>
                      );
                    })
                  : 'No Data'}
              </Swiper>
            </div>
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

export default Testimonial1;
