import React from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image, useColorMode } from '@chakra-ui/react';

const HeroSection6 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <section
      className={colorMode === 'light' ? 'bg-secondary' : 'cust-bgColor'}
    >
      <div className="lg:flex md:flex  block container mx-auto lg:px-16 lg:px-12 px-5 lg:py-16 md:py-16 py-8">
        <div
          id="7444089553"
          className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full lg:pr-12 md:pr-12 "
        >
          <Image
            src={data ? getImage(data['7444089553']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="hero"
            className="br-40px w-full h-450px sm-h-350px sm-h250px object-contain"
          />
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full  flex flex-col justify-center lg:py-0 md:py-0 py-4">
          <h1
            id="3911276752"
            className="dynamicStyle hero-header clr-fff lg:mb-4 md:mb-4 mb-2 cust-textColor"
          >
            {data
              ? data['3911276752']
              : 'Setting up a workplace in the office and at home'}
          </h1>
          <p
            id="3639680647"
            dangerouslySetInnerHTML={{
              __html: data ? data['3639680647'] : 'Lorem ipsum generated',
            }}
            className="fsize18 sm-fsize14 clr-fff dynamicStyle textColor-light"
          ></p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection6;
