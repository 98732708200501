import React from "react";
import { useSelector } from "react-redux/es/exports";
function HeroSectionVideo({ pageName }) {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  return (
    <div className="bg-secondary">
      <div className="relative ">
        <video width="100%" loop autoPlay muted className=" herovideo-height">
          <source
            src="https://nexuscluster.blob.core.windows.net/server01/nhrdn/linkdocument/document-l3eW9wZ_eHKPABUgpIjur-1713259181"
            type="video/mp4"
          />
        </video>

        <div className="absolute  h-full w-full bckdrop  top-0 left-0 flex sm-reverse sm-align-center lg:px-0 px-5 lg:py-0 md:py-8 py-6 items-center ">
          <div
            className=" lg:px-20 md:px-8 lg:mt-0 md:mt-0 mt-4 text-center w-full h-full justify-center flex items-center"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div className="lg:w-3/4 md:w-3/4 w-full mx-auto">
              <h2
                id="0901954745"
                className="dynamicStyle fsize35 sm-fsize20  text-white  font-semibold"
              >
                {data ? data["0901954745"] : "Lorem Ipsum"}
              </h2>
              <p
                id="0202637486"
                className="dynamicStyle text-white fsize20 sm-fsize14 opacity-70 lg:mt-4 md:mt-4 mt-2 lg:leading-7 md:leading-7 leading-6"
                dangerouslySetInnerHTML={{
                  __html: data ? data["0202637486"] : "no-data",
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSectionVideo;
