import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const FeatureCard3 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className="lg:py-14 md:py-16 py-8">
      <div className="text-center container mx-auto flex flex-col justify-center lg:mb-14 md:mb-14 mb-6 lg:p-0 md:p-0 p-6">
        <h3
          id="4555909181"
          className="dynamicStyle sm-fsize14 font-semibold clr-ddd9d8 text-primary cust-textColor mb-2"
        >
          {data ? data['4555909181'] : 'Lorem ipsum'}
        </h3>
        <h1
          id="7966782544"
          className="dynamicStyle fsize30 sm-fsize20 font-semibold mb-2 cust-textColor"
        >
          {data ? data['7966782544'] : 'Lorem ipsum'}
        </h1>
        <div className="lg:w-2/3 md:w-2/3  mx-auto">
          <p
            id="2085684026"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['2085684026']
                : ' publishing and graphic design, Lorem ipsum is a placeholder text',
            }}
            className="dynamicStyle sm-fsize14 textColor-light text-center lg:px-8 md:px-6"
          ></p>
        </div>
      </div>

      <div className="container mx-auto grid lg:grid-cols-2 md:grid-cols-2 lg:grid-cols-1 lg:mt-5 md:mt-5 w-full lg:gap-4 md:gap-4 gap-3 lg:px-12 md:px-12 px-5">
        <Card>
          <div
            className="custShadow1 h-300px md-h-100 sm-h-full lg:flex lg:gap-8 md:gap-8 gap-4 justify-center items-center lg:py-8 md:py-8 py-4 lg:px-6 md:px-6 px-3"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div
              id="2703743183"
              className="dynamicStyle2 w-30 md-w-full lg:h-full md:h-auto flex justify-center items-center lg:mb-0 md:mb-0 mb-4 lg:mx-0 md:mx-0 mx-auto"
            >
              <Image
                src={data ? getImage(data['2703743183']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt=""
                className="lg:h-32 md:h-32 h-24 lg:w-32 md:w-32 w-24  object-cover"
              />
            </div>
            <div className="w-70 md-w-100 lg:text-left md:text-left text-center">
              <h2
                id="5386927326"
                className="dynamicStyle  text-start fsize24 sm-fsize18 lg:mb-4 md:my-4 mb-2 font-semibold cust-textColor"
              >
                {data ? data['5386927326'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="6909171876"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['6909171876']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle text-start fsize16 sm-fsize14 textColor-light"
              ></p>
            </div>
          </div>
        </Card>
        <Card>
          <div
            className="custShadow1 h-300px md-h-100 sm-h-full lg:flex lg:gap-8 md:gap-8 gap-4 justify-center items-center lg:py-8 md:py-8 py-4 lg:px-6 md:px-6 px-3"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div
              id="4464845965"
              className="dynamicStyle2 w-30 md-w-full lg:h-full md:h-auto flex justify-center items-center lg:mb-0 md:mb-0 mb-4 lg:mx-0 md:mx-0 mx-auto"
            >
              <Image
                src={data ? getImage(data['4464845965']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt=""
                className="lg:h-32 md:h-32 h-24 lg:w-32 md:w-32 w-24 object-cover"
              />
            </div>
            <div className="w-70 md-w-100 lg:text-left md:text-left text-center">
              <h2
                id="6967402905"
                className="dynamicStyle text-start fsize24 sm-fsize18 lg:mb-4 md:my-4 mb-2 font-semibold cust-textColor"
              >
                {data ? data['6967402905'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="6104126080"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['6104126080']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle  text-start fsize16 sm-fsize14 textColor-light"
              ></p>
            </div>
          </div>
        </Card>
        <Card>
          <div
            className="custShadow1 h-300px md-h-100 sm-h-full lg:flex lg:gap-8 md:gap-8 gap-4 justify-center items-center lg:py-8 md:py-8 py-4 lg:px-6 md:px-6 px-3"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div
              id="0545143578"
              className="dynamicStyle2 w-30 md-w-full lg:h-full md:h-auto flex justify-center items-center lg:mb-0 md:mb-0 mb-4 lg:mx-0 md:mx-0 mx-auto"
            >
              <Image
                src={data ? getImage(data['0545143578']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt=""
                className="lg:h-32 md:h-32 h-24 lg:w-32 md:w-32 w-24  object-cover"
              />
            </div>
            <div className="w-70 md-w-100 lg:text-left md:text-left text-center">
              <h2
                id="3280765541"
                className="dynamicStyle text-start fsize24 sm-fsize18 lg:mb-4 md:my-4 mb-2 font-semibold cust-textColor"
              >
                {data ? data['3280765541'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="7112473555"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['7112473555']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle  text-start fsize16 sm-fsize14 textColor-light"
              ></p>
            </div>
          </div>
        </Card>
        <Card>
          <div
            className="custShadow1 h-300px md-h-100 sm-h-full lg:flex lg:gap-8 md:gap-8 gap-4 justify-center items-center lg:py-8 md:py-8 py-4 lg:px-6 md:px-6 px-3"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div
              id="9453386273"
              className="dynamicStyle2 w-30 md-w-full lg:h-full md:h-auto flex justify-center items-center lg:mb-0 md:mb-0 mb-4 lg:mx-0 md:mx-0 mx-auto"
            >
              <Image
                src={data ? getImage(data['9453386273']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt=""
                className="lg:h-32 md:h-32 h-24 lg:w-32 md:w-32 w-24  object-cover"
              />
            </div>
            <div className="w-70 md-w-100 lg:text-left md:text-left text-center">
              <h2
                id="7668538784"
                className="dynamicStyle text-start fsize24 sm-fsize18 lg:mb-4 md:my-4 mb-2 font-semibold cust-textColor"
              >
                {data ? data['7668538784'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="9487869032"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['9487869032']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle text-start fsize16 sm-fsize14 textColor-light"
              ></p>
            </div>
          </div>
        </Card>
      </div>
    </section>
  );
};

export default FeatureCard3;
