import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
// import Youtube from '../../../assets/images/youtube.png';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FaBars } from 'react-icons/fa';

const Header2 = () => {
  const [navbar, setnavbar] = useState(false);
  const [navbarside, setnavbarside] = useState(false);
  const setclick = () => setnavbar(false);
  let brandProfile = useSelector(state => state.brandDetails.brandProfile);
  let menuData = useSelector(state => state.menuState.menu);
  let userData = useSelector(state => state.AuthDetails.user);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  //primarylogo
  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }

  const changebackground = () => {
    if (window.scrollY < 1) {
      setnavbar(true);
    } else {
      setnavbar(false);
    }
  };

  useEffect(() => {
    changebackground();
    window.addEventListener('scroll', changebackground);
  });
  return (
    <section className="w-full z-50 fixed top-0 left-0 w-full ">
      <div className="p-2 bg-primary ">
        <p className="fsize14 sm-fsize12 text-center text-white">
          Fellowship application portal for SBI YFI Program is now live. Click
          here to know more
        </p>
      </div>
      <div
        className={
          navbar
            ? 'p-2 bg-transparent lg:block hidden'
            : 'p-2 bg-secondary lg:block hidden'
        }
      >
        <div className="container mx-auto lg:px-12 md:px-12 px-5">
          <div className="flex justify-between items-center">
            <a href="/">
              <Image
                src={getPrimaryImage(brandProfile)}
                alt="logo"
                className="w-32 h-12 object-contain"
              />
            </a>
            {/* <div>
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/Frame%203.png"
                alt="logo"
                className="sbi-logo"
              />
            </div> */}
            <div className="lg:flex hidden items-center gap-6 dropdowns">
              {menuData
                ? menuData.map((e, i) => {
                    return (
                      <div key={i}>
                        {e.menutype === 'page' && e.subMenu.length < 1 ? (
                          <NavLink
                            to={`/${e.pageData[0].page}`}
                            onClick={() => setclick(e)}
                          >
                            <p className="text-white">{e.menuName}</p>
                          </NavLink>
                        ) : (
                          ''
                        )}

                        {e.menutype === 'page' && e.subMenu.length > 0 ? (
                          <Menu>
                            <MenuButton
                              px={0}
                              py={2}
                              transition="all 0.2s"
                              borderRadius="md"
                              className="font-medium"
                              borderWidth="0px"
                              // _hover={{ bg: 'gray.400' }}
                              // _expanded={{ bg: 'blue.400' }}
                              // _focus={{ boxShadow: 'outline' }}
                            >
                              <p className="font-medium text-white cursor-pointer">
                                {e.menuName} <ChevronDownIcon />
                              </p>{' '}
                            </MenuButton>
                            <MenuList>
                              {e.subMenu.map(sub => {
                                return (
                                  <NavLink
                                    to={`/${sub.pageData[0].page}`}
                                    onClick={() => setclick(e)}
                                  >
                                    <MenuItem>
                                      <p className="font-medium cursor-pointer">
                                        {sub.menuName}
                                      </p>
                                    </MenuItem>
                                  </NavLink>
                                );
                              })}
                            </MenuList>
                          </Menu>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })
                : ''}
            </div>
            <div className="flex items-center gap-4">
              <a
                href="https://www.instagram.com/sbifoundation/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/004-instagram.png"
                  alt="Instagram"
                  className="w-4 object-contain h-4"
                />
              </a>
              <a
                href="https://www.facebook.com/SBIFoundationIndia?ref=aymt_homepage_panel"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/001-facebook.png"
                  alt="facebook"
                  className="w-4 object-contain h-4"
                />
              </a>
              <a
                href="https://twitter.com/SBI_FOUNDATION"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/twitter2.svg"
                  alt="twitter"
                  className="w-4 object-contain h-4"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/sbi-foundation?originalSubdomain=in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/Vector.png"
                  alt="in"
                  className="w-4 object-contain h-4"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UC7DDPVZdE_bV15bqauKQODQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <img
                  src={Youtube}
                  className="w-4 object-contain h-4"
                  alt="youtube"
                /> */}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden  block px-4 bg-white">
        <div className="flex items-center md:h-20 w-full">
          <div className="w-1/2">
            <a href="/">
              <Image
                src={getPrimaryImage(brandProfile)}
                alt="logo"
                className="w-32 object-contain h-16"
              />
            </a>
          </div>
          <div className="w-1/2 flex justify-end">
            <div className="px-0 py-4  bg-white">
              <Button ref={btnRef} onClick={onOpen}>
                <FaBars />
              </Button>
              <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>
                    <a href="/">
                      <Image
                        src={getPrimaryImage(brandProfile)}
                        alt="logo"
                        className="w-24  h-8 object-contain"
                      />
                    </a>
                  </DrawerHeader>

                  <DrawerBody>
                    <div className=" relative h-100vh">
                      <div className="pt-4">
                        <a href="/">
                          <div className="flex gap-2 items-center ">
                            <h2 className=" font-semibold fsize16">
                              {userData.firstName}
                            </h2>
                          </div>
                        </a>
                        <div className="mt-4 ">
                          {menuData
                            ? menuData.map((e, i) => {
                                return (
                                  <div key={i}>
                                    {e.menutype === 'page' &&
                                    e.subMenu.length < 1 ? (
                                      <NavLink
                                        to={`/${e.pageData[0].page}`}
                                        onClick={() => setclick(e)}
                                      >
                                        <p
                                          className={
                                            navbar === e
                                              ? 'menuHover font-semibold  cursor-pointer'
                                              : ' font-medium cursor-pointer mb-4'
                                          }
                                        >
                                          {e.menuName}
                                        </p>
                                      </NavLink>
                                    ) : (
                                      ''
                                    )}

                                    {e.menutype === 'page' &&
                                    e.subMenu.length > 1 ? (
                                      <Menu>
                                        <MenuButton
                                          px={0}
                                          py={2}
                                          transition="all 0.2s"
                                          borderRadius="md"
                                          borderWidth="0px"

                                          // _hover={{ bg: 'gray.400' }}
                                          // _expanded={{ bg: 'blue.400' }}
                                          // _focus={{ boxShadow: 'outline' }}
                                        >
                                          <p className="font-medium cursor-pointer">
                                            {e.menuName} <ChevronDownIcon />
                                          </p>{' '}
                                        </MenuButton>
                                        <MenuList>
                                          {e.subMenu.map(sub => {
                                            return (
                                              <MenuItem>
                                                {sub.menuName}
                                              </MenuItem>
                                            );
                                          })}
                                        </MenuList>
                                      </Menu>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                );
                              })
                            : ''}
                        </div>
                      </div>
                      <div className="fixed w-11/12 ml-4 mr-3  left-0 bottom-4 ">
                        <div className="w-full bg-primary p-3">
                          <div className="flex items-center gap-4">
                            <a
                              href="https://www.instagram.com/sbifoundation/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Image
                                src="https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/004-instagram.png"
                                alt="Instagram"
                                className="w-6 object-contain h-6"
                              />
                            </a>
                            <a
                              href="https://www.facebook.com/SBIFoundationIndia?ref=aymt_homepage_panel"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Image
                                src="https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/001-facebook.png"
                                alt="facebook"
                                className="w-6 object-contain h-6"
                              />
                            </a>
                            <a
                              href="https://twitter.com/SBI_FOUNDATION"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Image
                                src="https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/twitter2.svg"
                                alt="twitter"
                                className="w-6 object-contain h-6"
                              />
                            </a>
                            <a
                              href="https://www.linkedin.com/company/sbi-foundation?originalSubdomain=in"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Image
                                src="https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/Vector.png"
                                alt="in"
                                className="w-6 object-contain h-6"
                              />
                            </a>
                            <a
                              href="https://www.youtube.com/channel/UC7DDPVZdE_bV15bqauKQODQ"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {/* <img
                  src={Youtube}
                  className="w-4 object-contain h-4"
                  alt="youtube"
                /> */}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {userData && userData._id && userData.token ? (
                    <button
                      onClick={() => handleLogout()}
                      className="flex items-center"
                    >
                      {' '}
                      <FeatherIcon className="mr-2" icon="log-out" size={18} />
                      Logout
                    </button>
                  ) : (
                    <Button
                      px="8"
                      colorScheme="primary"
                      onClick={() => {
                        navigate('/login');
                        onClose();
                      }}
                      className="mt-4 py-2 bg-secondary text-white font-semibold px-8"
                    >
                      Sign In
                    </Button>
                  )} */}

                    {/* <button
                    onClick={() => navigate('/login')}
                    className="mt-4 py-2 bg-secondary text-white font-semibold px-8"
                  >
                    Sign In
                  </button> */}
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header2;
