import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Button } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';

const AboutSection15 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="container mx-auto lg:px-12 md:px-10 lg:py-28 md:py-20 px-4 py-20">
      <div className="lg:flex md:flex items-center">
        <div className="lg:w-5/12 md:w-5/12">
          <div className="cards-home relative rounded-2xl bg-primary cust-bgColor lg:w-3/4 md:w-11/12">
            <div
              id="1727455261"
              className="dynamicStyle2 lg:ml-8 md:ml-8  cards-img  z-10 rounded-2xl w-full"
            >
              <Image
                src={data ? getImage(data['1727455261']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="cards-img object-cover absolute-cards lg:absolute md:absolute z-20 rounded-2xl"
                alt="cards"
              />
            </div>
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/SBI-foundation/SBI-foundation/home/Frame%20125.png"
              className="w-24 h-20 absolute bottom-0 left-0"
              alt="side"
            />
          </div>
        </div>
        <div
          className="lg:w-7/12  md:w-7/12 md:pl-10  lg:pl-0 lg:mt-0 md:mt-0 mt-6"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <h2
            id="5060504169"
            className="dynamicStyle fsize30 sm-fsize20 font-semibold textprimary cust-textColor"
          >
            {data ? data['5060504169'] : 'Message from the Chairman, SBI'}
          </h2>
          <p
            id="7662101065"
            className=" fsize22 sm-fsize12 col-9e mt-1 lg:mt-2 dynamicStyle"
          >
            {data ? data['7662101065'] : 'Dinesh Khara'}
          </p>
          <p
            id="8772849817"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['8772849817']
                : 'We, at the State Bank of India are representative of the trust and confidence placed by the public in us. Any activity undertaken at the SBI is done with a sense of responsibility, keeping the interest of the public at heart. Our long-held traditional values of providing services beyond banking have made a way for the SBI foundation. The SBI Foundation is founded to create a deep social impact and we look for opportunities to maximize the positive impact on the world around us in various areas like healthcare, education and environment etc. Our efforts are aimed at creating equal opportunities for all sections of the society and act as a catalyst for those who share our vision and seek active collaboration with them.',
            }}
            className="dynamicStyle textColor-light fsize18 md-fsize16 sm-fsize14 mt-2 lg:mt-3"
          ></p>

          {/* <Button
            variant="outline"
            colorScheme="primary"
            className="dynamicStyle mt-6 textprimary cust-textColor sm-fsize14"
            size="lg"
            id=""
          >
            {data ? data[''] : 'Know More'}
            <FeatherIcon
              className="textprimary cust-textColor ml-3"
              size={18}
              icon="arrow-right"
            />
          </Button> */}
        </div>
      </div>
    </section>
  );
};

export default AboutSection15;
