import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image } from '@chakra-ui/react';

const FeatureCard5 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section>
      <div className="container mx-auto lg:px-12 px-4 py-8 lg:py-16 md:py-16 py-8">
        <p
          className="text-primary text-center sm-fsize14 dynamicStyle cust-textColor mb-2"
          id="1059803787"
        >
          {data ? data['1059803787'] : 'Lorem Ipsum'}
        </p>
        <h2
          className="fsize30 sm-fsize20 font-semibold text-center mb-2 text-primary  cust-textColor dynamicStyle"
          id="5182522828"
        >
          {data ? data['5182522828'] : 'Lorem Ipsum'}
        </h2>

        <div
          className="lg:mt-14 md:mt-14 mt-6 lg:flex md:flex gap-4 flex-wrap justify-center w-full"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <Card className="lg:w-1/4 md:w-1/4 w-full lg:mb-0 mb-5">
            <div className="dynamicStyle2 w-full" id="0888657655">
              <Image
                src={data ? getImage(data['0888657655']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full h-40"
                alt="board"
              />
            </div>
            <p
              className="text-primary text-center fsize18 mt-3 mb-1 dynamicStyle cust-textColor"
              id="5584618365"
            >
              {data ? data['5584618365'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card className="lg:w-1/4 md:w-1/4 w-full lg:mb-0 mb-5">
            <div className="dynamicStyle2 w-full" id="3984027752">
              <Image
                src={data ? getImage(data['3984027752']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full h-40"
                alt="board"
              />
            </div>
            <p
              className="text-primary text-center fsize18 mt-3 mb-1 dynamicStyle cust-textColor"
              id="0292603896"
            >
              {data ? data['0292603896'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card className="lg:w-1/4 md:w-1/4 w-full lg:mb-0 mb-5">
            <div className="dynamicStyle2 w-full" id="3492647782">
              <Image
                src={data ? getImage(data['3492647782']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full h-40"
                alt="board"
              />
            </div>
            <p
              className="text-primary text-center fsize18 mt-3 mb-1 dynamicStyle cust-textColor"
              id="4712659864"
            >
              {data ? data['4712659864'] : 'Lorem Ipsum'}
            </p>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard5;
