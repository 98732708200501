import React, { useEffect } from 'react';
import 'swiper/css';
import { Button, Image } from '@chakra-ui/react';
import 'swiper/css/effect-coverflow';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import FeatherIcon from 'feather-icons-react';
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux/es/exports';
import PaymentCheckout from '../../../components/Payment/Payment';
const SubscriptionGradient = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const plans = [
    {
      id: '01',
      planType: 'Fit2Coach',
      cost: '1',
      description:
        "The only compass you'll ever need to guide you through your caregiving journey.",
      title: 'Subscribe',

      feathers: [
        {
          icon: 'check',
          name: 'For Mobile App User',
        },
        {
          icon: 'check',
          name: 'For Website User',
        },
        {
          icon: 'x',
          name: 'Newsletter Subscription (Updates on Caregiver Saathi)',
        },
        {
          icon: 'x',
          name: 'Diy Tools',
        },
        {
          icon: 'x',
          name: 'Electronic medical records',
        },
        {
          icon: 'x',
          name: 'Guidelines on living will and recording it',
        },
      ],
    },

    {
      id: '02',
      planType: 'Fit2Coach Pro',
      cost: '2000',
      description:
        "The only compass you'll ever need to guide you through your caregiving journey.",
      title: 'Subscribe',

      feathers: [
        {
          icon: 'check',
          name: 'For Mobile App User',
        },
        {
          icon: 'check',
          name: 'For Website User',
        },
        {
          icon: 'check',
          name: 'Newsletter Subscription (Updates on Caregiver Saathi)',
        },
        {
          icon: 'x',
          name: 'Diy Tools',
        },
        {
          icon: 'x',
          name: 'Electronic medical records',
        },
        {
          icon: 'x',
          name: 'Guidelines on living will and recording it',
        },
      ],
    },

    {
      id: '03',
      planType: 'Public',
      cost: '2000',
      description:
        "The only compass you'll ever need to guide you through your caregiving journey.",
      title: 'Subscribe',

      feathers: [
        {
          icon: 'check',
          name: 'For Mobile App User',
        },
        {
          icon: 'check',
          name: 'For Website User',
        },
        {
          icon: 'x',
          name: 'Newsletter Subscription (Updates on Caregiver Saathi)',
        },
        {
          icon: 'check',
          name: 'Diy Tools',
        },
        {
          icon: 'check',
          name: 'Electronic medical records',
        },
        {
          icon: 'check',
          name: 'Guidelines on living will and recording it',
        },
      ],
    },

    {
      id: '04',
      planType: 'Corporate Account',
      cost: '2000',
      description:
        "The only compass you'll ever need to guide you through your caregiving journey.",
      title: 'Subscribe',

      feathers: [
        {
          icon: 'check',
          name: 'For Mobile App User',
        },
        {
          icon: 'check',
          name: 'For Website User',
        },
        {
          icon: 'check',
          name: 'Newsletter Subscription (Updates on Caregiver Saathi)',
        },
        {
          icon: 'check',
          name: 'Diy Tools',
        },
        {
          icon: 'check',
          name: 'Electronic medical records',
        },
        {
          icon: 'check',
          name: 'Guidelines on living will and recording it',
        },
      ],
    },
  ];

  function getItemList(item) {
    return [
      {
        name: item.planType,
        quantity: 1,
        price: parseInt(item.cost),
      },
    ];
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="bggradient-50 relative">
      <div className="container mx-auto zindex9 relative lg:py-16 md:py-16 py-8 lg:px-12 md:px-12 px-5">
        <div className="text-center lg:mb-12 md:mb-12 mb-12 lg:mt-0 md:mt-0 mt-4">
          <h2
            id="4105908131"
            className="
           dynamicStyle text-white lg:text-4xl text-2xl md:text-3xl font-semibold"
          >
            {data ? data['4105908131'] : 'Get Listed On Coach Community'}
          </h2>
          <p
            id="7079468661"
            className="dynamicStyle mt-4 text-white"
            dangerouslySetInnerHTML={{
              __html: data ? data['7079468661'] : 'no-data',
            }}
          ></p>
        </div>

        <Swiper
          spaceBetween={20}
          modules={[Navigation]}
          autoplay={{
            delay: '1000',
          }}
          navigation={{
            nextEl: '.test-swiper-button-next',
            prevEl: '.test-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 4.2,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 4.2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2.8,
              spaceBetween: 10,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            300: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
          }}
        >
          {/* <div className="swiper-button  test-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button test-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div> */}

          {plans.map(item => (
            <SwiperSlide className="bg-plancard px-6 py-4">
              <div>
                <p className="text-white border fsize12 p-1 fitcontent">
                  {item.planType}{' '}
                </p>
                <h2 className="text-primary lg:text-3xl md:text-3xl text-2xl font-semibold mt-4 lg:mb-3 md:mb-3 mb-4">
                  $$ {item.cost}/<span className="fsize16">Month </span>
                </h2>
                <p className="text-white fsize12 lg:mb-4 md:mb-4 mb-4">
                  {item.description}{' '}
                </p>
                {/* <Button
                  colorScheme="primary"
                  className="w-full font-semibold fsize14"
                >
                  {' '}
                  {item.title}
                </Button> */}
                <PaymentCheckout
                  button={item.title}
                  buttonClass={'w-full font-semibold fsize14'}
                  buttonColor={'primary'}
                  itemList={getItemList(item)}
                  successUrl={'home'}
                  cancelUrl={'home'}
                ></PaymentCheckout>
                <div>
                  <div className=" lg:mt-6 md:mt-6 mt-4 ">
                    {item.feathers.map(e => (
                      <div className=" mb-4 flex items-center gap-2">
                        <div className="w-1/12">
                          <FeatherIcon
                            size={18}
                            icon={e.icon}
                            className="cursor-pointer text-white"
                          />
                        </div>
                        <div className="w-11/12">
                          <p className="text-white fsize12">{e.name}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <Image
                   
                   fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                   src={e.image}
                 
                 className="rounded-full roundetest cursor-pointer activeuser"
                   alt="heroImage"
                 /> */}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="lg:mt-12 flex justify-center md:mt-12 mt-8">
          <Button
            id="9123214596"
            className="dynamicStyle trans-btn  rounded-full fsize14 text-center "
            py="4"
          >
            <Link to="/subscription">
              {data ? data['9123214596'] : 'no-data'}
            </Link>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionGradient;
