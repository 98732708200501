import React, { useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Testimonial4 = ({ pageName }) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const allTestimonials = useSelector(state => state.testimonial.Testimonial);

  useEffect(() => {
    dispatch(getTestimonal());
    Aos.init();
  }, []);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <>
      {allTestimonials ? (
        <section className="container mx-auto lg:py-8">
          <div className="text-center  flex flex-col justify-center lg:mb-16">
            <h3
              id="3294533169"
              className="dynamicStyle letter-spacing4px fsize18 md-fsize16 sm-fsize16 font-semibold clr-ddd9d8 "
            >
              {data ? data['3294533169'] : 'Lorem Ipsum'}
            </h3>
            <h1
              id="3925329493"
              className="dynamicStyle fsize40 md-fsize28 sm-fsize24 font-semibold  "
            >
              {data ? data['3925329493'] : 'Lorem Ipsum'}
            </h1>
            <div className="">
              <p
                id="8454975100"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['8454975100']
                    : ' In publishing and graphic design, Lorem ipsum is a placeholder text commonly',
                }}
                className="dynamicStyle w-4/5 mx-auto fsize18 md-fsize14 sm-fsize14 text-center"
              ></p>
            </div>
          </div>

          <div className="w-60 md-w-80 md-wid-100 sm-w-90 mx-auto lg:py-0 md:py-4 py-8 md:px-12">
            <Swiper
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              navigation={{
                nextEl: '.image-swiper-button-next',
                prevEl: '.image-swiper-button-prev',
              }}
              className="mySwiper"
              breakpoints={{
                1536: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                991: {
                  slidesPerView: 21,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 1.2,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 1.2,
                  spaceBetween: 25,
                },
                325: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {allTestimonials && allTestimonials.data.result.length > 0
                ? allTestimonials.data.result.map(item => (
                    <SwiperSlide>
                      <div>
                      <div className="testimonial-card border rounded-lg lg:flex md:flex md:gap-2 block">
                        <div className="w-30 sm-wfull flex flex-col items-center justify-center">
                          <div className="testimonial-div ">
                            <Image
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                              alt="testimonial-image"
                              className="testimonial-img rounded-full object-cover mx-auto"
                            />
                          </div>
                          <div className="w-full text-center">
                            {/* <h2 className="fsize24 md-fsize18 sm-fsize18 font-semibold ">
                              {`${item.firstName} ${item.lastName}`}
                            </h2> */}
                            <h2 className="fsize20 md-fsize18 sm-fsize16 font-semibold cust-textColor">
                              {item.name}
                            </h2>
                            {/* <p className="fsize16 md-fsize14 sm-fsize14">
                              CEO & Co Founder
                            </p> */}
                          </div>
                        </div>
                        <div className="w-70 sm-wfull">
                          <p className="fsize16 md-fsize14 sm-fsize14 pt-4 pb-4 textColor-light">
                            {item.testimonial}
                          </p>
                        </div>
                      </div>
                      </div>
                    </SwiperSlide>
                  ))
                : 'no-data'}
            </Swiper>
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

export default Testimonial4;
