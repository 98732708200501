import React, { useEffect } from 'react';
import { Image, Button } from '@chakra-ui/react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import { getAllElearningCategory } from '../../../../redux/elearningProduct/products-action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ProductCategories = ({ pageName }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllElearningCategory());
  }, []);

  let allCategory = useSelector(
    state => state.elearningProduct.elearningCategory
  );
  console.log(allCategory, 'allcat');
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const Categories = [
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      title: 'hfjh',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      title: 'hfjh',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      title: 'hfjh',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      title: 'hfjh',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      title: 'hfjh',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      title: 'hfjh',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      title: 'hfjh',
    },
  ];
  return (
    <div className="container m-auto lg:pt-28 md:pt-20 pt-16 lg:px-12 md:px-8 px-5 lg:pb-12 md:pb-12 pb-6">
      <h2
        id="8803931303"
        className="dynamicStyle  fsize30 sm-fsize24 text-center font-semibold"
      >
        {data ? data['8803931303'] : 'Popular Categories'}
      </h2>
      <p id="6008948217" className="dynamicStyle  sm-fsize14 text-center">
        {data
          ? data['6008948217']
          : 'Keep up with the Topics and Trends you care about the most'}
      </p>
      <div className="lg:py-12 md:py-8 py-6">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 3.8,
              spaceBetween: 25,
            },
            768: {
              slidesPerView: 3.8,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1.5,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1.5,
              spaceBetween: 15,
            },
            325: {
              slidesPerView: 1.5,
              spaceBetween: 15,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>

          {allCategory && allCategory.result && allCategory.result.length > 0
            ? allCategory.result.map(item => {
                return (
                  <SwiperSlide>
                    <div className="bg-primary rounded-md py-4">
                      <Image
                        src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                        alt="img"
                        className="rounded-md w-16 m-auto object-cover"
                      />
                      <p className="text-center sm-fsize14 text-white pt-4">
                        {item.name}
                      </p>
                    </div>
                  </SwiperSlide>
                );
              })
            : 'no-data'}
        </Swiper>
      </div>
      <div className=" flex justify-center">
        <Button
          size="lg"
          colorScheme="primary"
          className="dynamicStyle rounded-full text-white cursor-pointer"
          id="5445323610"
        >
          <span onClick={() => handleClick('5445323610')}>
          {data ? data['5445323610'] : 'View All'}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default ProductCategories;
