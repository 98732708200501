import { Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

const AboutSection1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className="">
      <div className="lg:flex md:flex gap-4 block container mx-auto lg:pt-32 lg:px-12 md:px-12 px-0 py-5 lg:pb-16">
        <div
          className="lg:w-1/3 md:w-1/3 w-full  lg:flex flex-col justify-center lg:px-4 md:px-4 px-6"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <p
            id="9612360112"
            className="dynamicStyle fsize18 sm-fsize16 lg:mb-2 clr-ddd9d8 cust-textColor letter-spacing4px font-semibold"
          >
            {data ? data['9612360112'] : 'Lorem ipsum'}
          </p>
          <p
            id="2158226426"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['2158226426']
                : ' publishing and graphic design, Lorem ipsum is a placeholder text',
            }}
            className="fsize16 sm-fsize14 textColor-light leading-6 dynamicStyle"
          ></p>
        </div>

        <div
          id="2345270135"
          className="dynamicStyle2 lg:w-1/3 md:w-1/3 w-full h-680px md-h-400px sm-h-350px lg:px-0 md:px-0 px-5 lg:mt-0 md:mt-0 mt-4"
        >
          <Image
            src={data ? getImage(data['2345270135']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="hero"
            className="br-40px w-full h-full object-cover bg-ddd9d8 border-6px"
          />
        </div>

        <div
          className="lg:w-1/3 md:w-1/3 w-full  lg:flex flex-col justify-center lg:px-4 md:px-4 px-6"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <p
            id="6527064395"
            className="cust-textColor fsize56 md-fsize26 sm-fsize18 leading-10 font-semibold lg:mb-4 md:mb-0 dynamicStyle"
          >
            {data ? data['6527064395'] : 'Lorem ipsum generate'}
          </p>
          <p
            id="0610906983"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['0610906983']
                : ' publishing and graphic design, Lorem ipsum is a placeholder text',
            }}
            className="fsize16 sm-fsize14 textColor-light leading-6 lg:mb-6 md:mb-6 mb-4 dynamicStyle"
          ></p>
          <Button
            className="smcust-btn dynamicStyle"
            width="180px"
            height="50px"
            colorScheme="primary"
            id="2724617783"
          >
            <span onClick={() => handleClick('2724617783')}>
            {data ? data['2724617783'] : 'Enquire Now'}
            </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default AboutSection1;
