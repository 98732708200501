import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const CardImage4 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="container mx-auto lg:py-16 py-8 lg:px-12 md:px-12 px-4">
      <p
        className="fsize16 text-secondary cust-textColor sm-fsize14 dynamicStyle"
        id="5580655447"
      >
        {data ? data['5580655447'] : 'Lorem Ipsum'}
      </p>
      <h2
        className="fsize30 sm-fsize20 font-semibold text-primary cust-textColor dynamicStyle"
        id="9312745632"
      >
        {data ? data['9312745632'] : 'Lorem Ipsum'}
      </h2>

      <Swiper
        spaceBetween={30}
        observer={true}
        observeParents={true}
        className="mySwiper mt-5 lg:mt-10"
        breakpoints={{
          1536: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          425: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          325: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
        }}
      >
        <SwiperSlide>
          <div className="">
            <div id="5829002104" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['5829002104']) : ''}
                className="lg:w-full lg:h-80 w-full h-72 rounded-lg"
                alt="women"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>

            <p
              className="mt-2 text-center text-secondary font-semibold dynamicStyle cust-textColor"
              id="2199402241"
            >
              {data ? data['2199402241'] : 'Lorem Ipsum'}
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="">
            <div id="9622622483" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['9622622483']) : ''}
                className="lg:w-full lg:h-80 w-full h-72 rounded-lg"
                alt="women"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <p
              className="mt-2 text-center text-secondary font-semibold dynamicStyle cust-textColor"
              id="1634585773"
            >
              {data ? data['1634585773'] : 'Lorem Ipsum'}
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="">
            <div id="0987311728" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['0987311728']) : ''}
                className="lg:w-full lg:h-80 w-full h-72 rounded-lg"
                alt="women"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <p
              className="mt-2 text-center text-secondary font-semibold dynamicStyle cust-textColor"
              id="8256041892"
            >
              {data ? data['8256041892'] : 'Lorem Ipsum'}
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="">
            <div id="3490645321" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['3490645321']) : ''}
                className="lg:w-full lg:h-80 w-full h-72 rounded-lg"
                alt="women"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <p
              className="mt-2 text-center text-secondary font-semibold dynamicStyle cust-textColor"
              id="4404486289"
            >
              {data ? data['4404486289'] : 'Lorem Ipsum'}
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default CardImage4;
