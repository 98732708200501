import { Image } from '@chakra-ui/react';
import React from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

const About = () => {
  return (
    <div className=" lg:pt-0 md:pt-0 pt-8">
      <div className=" w-full bg-white br-top-20px lg:px-12 lg:py-8 md:px-8 md:py-6 px-5 pb-5">
        <div className="flex">
          <h2 className="fsize32 sm-fsize24 font-semibold">About</h2>
        </div>
        <div className="lg:pr-20 md:pr-12">
          <p className="fsize14">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed alias
            beatae nam voluptatem ducimus repellendus modi necessitatibus
            doloribus animi eveniet quas dicta, vitae cum neque, saepe
            architecto dolorum perspiciatis.
          </p>
          <p className="fsize14 mt-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed alias
            beatae nam voluptatem ducimus repellendus modi necessitatibus
            doloribus animi eveniet quas dicta, vitae cum neque, saepe
            architecto dolorum perspiciatis.
          </p>
        </div>
        <div className="mt-3">
          <h2 className="fsize24 sm-fsize18">What I Do!</h2>
          <div className="lg:mt-8 md:mt-6 mt-4 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 w-full gap-6">
            <div className="lg:flex md:flex gap-4 bg-f3f6f6 lg:px-4 lg:py-4 md:px-4 md:py-4 p-3 rounded-lg">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="w-12 h-12 object-cover lg:mb-0 md:mb-0 mb-3"
              />
              <div className="">
                <h2 className="fsize20 font-medium lg:mb-3 md:mb-2 mb-1">
                  Ui/Ux Development
                </h2>
                <p className="fsize14 lh24px">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maxime, quam. Doloremque ratione est veniam
                </p>
              </div>
            </div>
            <div className="lg:flex md:flex gap-4 bg-f3f6f6 lg:px-4 lg:py-4 md:px-4 md:py-4 p-3 rounded-lg">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="w-12 h-12 object-cover lg:mb-0 md:mb-0 mb-3"
              />
              <div className="">
                <h2 className="fsize20 font-medium lg:mb-3 md:mb-2 mb-1">
                  Photography
                </h2>
                <p className="fsize14 lh24px">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maxime, quam. Doloremque ratione est veniam
                </p>
              </div>
            </div>
            <div className="lg:flex md:flex gap-4 bg-f3f6f6 lg:px-4 lg:py-4 md:px-4 md:py-4 p-3 rounded-lg">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="w-12 h-12 object-cover lg:mb-0 md:mb-0 mb-3"
              />
              <div className="">
                <h2 className="fsize20 font-medium lg:mb-3 md:mb-2 mb-1">
                  App Development
                </h2>
                <p className="fsize14 lh24px">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maxime, quam. Doloremque ratione est veniam
                </p>
              </div>
            </div>
            <div className="lg:flex md:flex gap-4 bg-f3f6f6 lg:px-4 lg:py-4 md:px-4 md:py-4 p-3 rounded-lg">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="w-12 h-12 object-cover lg:mb-0 md:mb-0 mb-3"
              />
              <div className="">
                <h2 className="fsize20 font-medium lg:mb-3 md:mb-2 mb-1">
                  Web Development
                </h2>
                <p className="fsize14 lh24px">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maxime, quam. Doloremque ratione est veniam
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-f8fbfd br-bottom-20px lg:px-12 lg:py-8 md:px-8 md:py-6 px-5 py-5">
        <h2 className="fsize32 font-semibold text-center fsize32 sm-fsize24 ">
          Clients
        </h2>
        <div className=" lg:mt-8 md:mt-6 mt-4 ">
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            className="mySwiper"
            breakpoints={{
              1536: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 25,
              },
              820: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              325: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="clients"
                  className="cursor-pointer"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="clients"
                  className="cursor-pointer"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="clients"
                  className="cursor-pointer"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="clients"
                  className="cursor-pointer"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="clients"
                  className="cursor-pointer"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="clients"
                  className="cursor-pointer"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default About;
