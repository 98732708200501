import { Button, Image } from '@chakra-ui/react';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import 'swiper/css/pagination';
import FeatherIcon from 'feather-icons-react';

const HerosectionSwiper1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="direction">
      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        observer={true}
        observeParents={true}
        className="mySwiper"
        breakpoints={{
          1536: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          325: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
        }}
      >
        <SwiperSlide>
          <div className="relative home-h">
            <div
              id="6073987465"
              className="dynamicStyle2 cus-bannerdynamic w-full"
            >
              <Image
                src={data ? getImage(data['6073987465']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="heroImage"
                className="w-full home-h object-cover"
              />
            </div>
            <div className="absolute top-0 left-0 flex items-center home-h w-full">
              <div className="container mx-auto lg:px-12 md:px-10 px-5">
                <div className="lg:w-6/12">
                  <small
                    className="fsize23 sm-fsize15 text-left text-white dynamicStyle"
                    id="8037593494"
                  >
                    {data
                      ? data['8037593494']
                      : 'STAY INFORMED WITH OUR LATEST ARTICLES'}
                  </small>
                  <h2
                    className="fsize42 sm-fsize20 font-semibold text-white text-left dynamicStyle"
                    id="2610796810"
                  >
                    {data
                      ? data['2610796810']
                      : 'STAY INFORMED WITH OUR LATEST ARTICLES'}
                  </h2>
                  <p
                    className="fsize16 text-left mt-2 text-white dynamicStyle"
                    id="9889936518"
                    dangerouslySetInnerHTML={{
                      __html: data ? data['9889936518'] : 'no-data',
                    }}
                  ></p>

                  <Button
                    variant="outline"
                    colorScheme="light"
                    className="mt-6 sm-fsize14 text-white dynamicStyle"
                    id="7507140849"
                    size="lg"
                  >
                    {data
                      ? data['7507140849']
                      : 'STAY INFORMED WITH OUR LATEST ARTICLES'}
                    <FeatherIcon
                      className="text-white ml-3"
                      size={18}
                      icon="arrow-right"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative home-h">
            <div
              id="7567896530"
              className="dynamicStyle2 cus-bannerdynamic w-full"
            >
              <Image
                src={data ? getImage(data['7567896530']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="heroImage"
                className="w-full home-h object-cover"
              />
            </div>
            <div className="absolute top-0 left-0 flex items-center home-h w-full">
              <div className="container mx-auto lg:px-12 md:px-10 px-5">
                <div className="lg:w-6/12">
                  <small
                    className="fsize23 sm-fsize15 text-left text-white dynamicStyle"
                    id="9312501427"
                  >
                    {data
                      ? data['9312501427']
                      : 'STAY INFORMED WITH OUR LATEST ARTICLES'}
                  </small>
                  <h2
                    className="fsize42 sm-fsize20 font-semibold text-white text-left dynamicStyle"
                    id="8558692349"
                  >
                    {data
                      ? data['8558692349']
                      : 'STAY INFORMED WITH OUR LATEST ARTICLES'}
                  </h2>
                  <p
                    className="fsize16 text-left mt-2 text-white dynamicStyle"
                    id="1551296061"
                    dangerouslySetInnerHTML={{
                      __html: data ? data['1551296061'] : 'no-data',
                    }}
                  ></p>

                  <Button
                    variant="outline"
                    colorScheme="light"
                    className="mt-6 sm-fsize14 text-white dynamicStyle"
                    id="4172522824"
                    size="lg"
                  >
                    {data
                      ? data['4172522824']
                      : 'STAY INFORMED WITH OUR LATEST ARTICLES'}
                    <FeatherIcon
                      className="text-white ml-3"
                      size={18}
                      icon="arrow-right"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default HerosectionSwiper1;
