import {
  Button,
  Input,
  Textarea,
  FormErrorMessage,
  FormControl,
  Image,
  useColorMode,
} from "@chakra-ui/react";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { createContact } from "../../../redux/marcom/marcom-action";
import { useDispatch, useSelector } from "react-redux/es/exports";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import imageVector from "../../../assets/images/custom/Vector5.png";
import imageVectorBottom from "../../../assets/images/custom/Vector5.png";
import Fade from "react-reveal/Fade";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";

const ContactUs = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const [mobileView, setMobileView] = useState(null);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname === "/contact%20us") {
      const contactUsSection = document.getElementById("contact-form");
      if (contactUsSection) {
        contactUsSection.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, []);

  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    description: "",
    countryCode: "",
  };

  const validationRules = Yup.object({
    name: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .max(25, "Name must be at most 25 characters")
      .required("Name is required")
      .matches(/^[a-zA-Z ]*$/, "Only alphabets is allowed"),
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 50 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
    mobile: Yup.string()
      // .min(10, 'Mobile number must be 10 digits')
      // .max(10, 'Mobile number must be 10 digits')
      .required("Mobile number is required"),
    // .matches(/^\d{10}$/, 'Mobile number must be digits')
    // .matches(/^[6789]/, 'Mobile number is invalid')
    // .matches(/^[0-9]*$/, 'Only numeric value is allowed'),

    description: Yup.string()
      .trim()
      .min(3, "Message must be at least 3 characters")
      .max(100, "Message must be at most 100 characters")
      .required("Message is required"),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      name: values.name,
      email: values.email.toLowerCase(),
      mobile: values.mobile,
      description: values.description,
      countryCode: values.countryCode,
    };
    try {
      let data = await dispatch(createContact(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Success",
          text: `Will get back to you shortly`,
          icon: "success",
          timer: "2500",
        });
        toast.success(`Will get back to you shortly`);
        setMobileView(null);
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
          toast.error(data.message);
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
      toast.error("Something went wrong!");
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  const handlePhoneChange = async (value, country, e, formattedValue) => {
    setMobileView(value);
    const dialCountryCode = country.dialCode;
    const phoneNumber = value.replace(`${dialCountryCode}`, "");
    await formik.setFieldValue("mobile", phoneNumber);
    await formik.setFieldValue("countryCode", dialCountryCode);
  };

  return (
    <section
      id="contact-form"
      className={colorMode === "light" ? "bg-white" : ""}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="container relative zindex9 mx-auto md:px-12 lg:px-12 px-5 lg:py-16 py-8">
          <div className="lg:flex items-center">
            <div className="lg:w-6/12 md:w-3/4">
              <Fade left>
                <h3
                  id="8947028329"
                  className="dynamicStyle lg:text-3xl md:text-2xl text-2xl text font-semibold text-dark cust-textColor"
                >
                  {data ? data["8947028329"] : "WE CARE ABOUT YOU"}
                </h3>
                <p
                  id="9620338707"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["9620338707"] : "no-data",
                  }}
                  className="dynamicStyle textColor-light fsize14 text8787 font-normal my-3 sm-fsize14 "
                ></p>

                <div className="flex mt-8 items-start">
                  <div>
                    {/* <Image
                    src={data ? getImage(data["0555054763"]) : ""}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                    className="w-10 h-10 shadow object-contain"
                    alt="map"
                  /> */}

                    <FeatherIcon
                      icon="map-pin"
                      className="mt-1 text-primary cust-textColor"
                    />
                  </div>
                  <div className="lg:ml-4 md:ml-4 ml-4 lg:mt-0 mt-0">
                    <h5
                      id="3518121002"
                      className="dynamicStyle fsize20 sm-fsize16 font-semibold text-primary cust-textColor"
                    >
                      {data ? data["3518121002"] : "Address"}
                    </h5>
                    <p
                      id="7817684324"
                      dangerouslySetInnerHTML={{
                        __html: data ? data["7817684324"] : "no-data",
                      }}
                      className="dynamicStyle textColor-light fsize15 sm-fsize14 col-79 mt-1 sm-line-clamp3"
                    ></p>
                  </div>
                </div>
                <div className="flex items-start lg:mt-10 md:mt-10  mt-6">
                  <div>
                    {" "}
                    {/* <img
                    src={data ? getImage(data["2742587937"]) : ""}
                    className="w-10 h-10 shadow object-contain"
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                    alt="map"
                  /> */}
                    <FeatherIcon
                      icon="mail"
                      className="mt-1 text-primary cust-textColor"
                    />
                  </div>
                  <div className=" lg:ml-4 md:ml-4 ml-4 lg:mt-0 mt-0">
                    <h5
                      id="6880022362"
                      className="dynamicStyle fsize20 sm-fsize16 font-semibold text-primary cust-textColor"
                    >
                      {data ? data["6880022362"] : "Email"}
                    </h5>
                    <p
                      id="5382831938"
                      className="dynamicStyle fsize15 sm-fsize14 col-79 mt-1 textColor-light"
                    >
                      <a
                        // href={`mailto:${
                        //   data ? data["5382831938"] : "fit2goasia@gmail.com"
                        // }`}
                        href={`fit2goasia@gmail.com`}
                        dangerouslySetInnerHTML={{
                          __html: data
                            ? data["5382831938"]
                            : "fit2goasia@gmail.com",
                        }}
                      ></a>
                    </p>
                  </div>
                </div>
                <div className="flex items-start lg:mt-10 md:mt-10  mt-6">
                  <div>
                    {" "}
                    {/* <img
                    src={data ? getImage(data["7274746992"]) : ""}
                    className="w-10 h-10 shadow object-contain"
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                    alt="map"
                  /> */}
                    <FeatherIcon
                      icon="phone"
                      className="mt-1 text-primary cust-textColor"
                    />
                  </div>
                  <div className=" lg:ml-4 md:ml-4 ml-4 lg:mt-0 mt-0">
                    <h5
                      id="1347737073"
                      className="dynamicStyle fsize20 sm-fsize16 font-semibold text-primary cust-textColor"
                    >
                      {data ? data["1347737073"] : "Contact Us"}
                    </h5>
                    <p
                      id="5799582602"
                      className="dynamicStyle fsize15 sm-fsize14 col-79 mt-1 textColor-light"
                    >
                      <a
                        href="tel:+91 9807654321"
                        dangerouslySetInnerHTML={{
                          __html: data ? data["5799582602"] : "no-data",
                        }}
                        className="textColor-light"
                      ></a>
                    </p>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="lg:w-6/12 md:w-full lg:mt-0 mt-4 lg:pl-20 md:pl-0">
              <FormControl
                isInvalid={!!formik.errors.name && formik.touched.name}
              >
                <Input
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  placeholder="Enter Name"
                  className="lg:text-base md:text-base text-sm cust-textColor"
                />
                {formik.touched.name && formik.errors.name && (
                  <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                isInvalid={!!formik.errors.email && formik.touched.email}
              >
                <Input
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  placeholder="Email Address"
                  className="mt-4 lg:text-base md:text-base text-sm cust-textColor"
                />
                {formik.touched.email && formik.errors.email && (
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                )}
              </FormControl>

              {/* <FormControl
                isInvalid={!!formik.errors.mobile && formik.touched.mobile}
              >
                <Input
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  placeholder="Mobile No."
                  className="mt-4 lg:text-base md:text-base text-sm"
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <FormErrorMessage>{formik.errors.mobile}</FormErrorMessage>
                )}
              </FormControl> */}
              <FormControl
                isInvalid={!!formik.errors.mobile && formik.touched.mobile}
              >
                <PhoneInput
                  name="mobile"
                  country={"sg"}
                  enableSearch={true}
                  value={mobileView}
                  className={
                    colorMode === "light"
                      ? "mt-4 phoneinput w-full mt-2 themefamily"
                      : "mt-4 phoneinputDark w-full mt-2 themefamily"
                  }
                  onChange={handlePhoneChange}
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <FormErrorMessage>{formik.errors.mobile}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                isInvalid={
                  !!formik.errors.description && formik.touched.description
                }
              >
                <Textarea
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  placeholder="Message"
                  className="mt-4 lg:text-base md:text-base text-sm cust-textColor"
                />
                {formik.touched.description && formik.errors.description && (
                  <FormErrorMessage>
                    {formik.errors.description}
                  </FormErrorMessage>
                )}
              </FormControl>

              <div id="3361556184" className="dynamicStyle">
                {" "}
                <Button
                  type="submit"
                  size="lg"
                  className={
                    colorMode === "light"
                      ? "w-full mt-6 sm-fsize16px bg-primary clr-fff"
                      : "w-full mt-6 sm-fsize16px cust-textColor border-1px"
                  }
                >
                  {data ? data["3361556184"] : "Send"}
                </Button>
              </div>
            </div>
          </div>

          <iframe
            className="sm-h250px tb-h280px w-full h-96 mt-10"
            src="
https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7378664063194!2d103.8891686747245!3d1.3334415986539219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da17f2c29be8a3%3A0x1e18649cc9703ae9!2s81%20Ubi%20Ave%204%2C%20Singapore%20408830!5e0!3m2!1sen!2sin!4v1713264533735!5m2!1sen!2sin"
            width="100%"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </form>
    </section>
  );
};

export default ContactUs;
