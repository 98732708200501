import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { AspectRatio, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const ContactDetails1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="relative form-img">
      <div id="3163284879" className="dynamicStyle2 cus-hbanner w-full">
        <Image
          src={data ? getImage(data['3163284879']) : ''}
          className="form-img"
          alt="form"
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
        />
      </div>

      <div className="absolute top-0 left-0 w-full flex items-center form-img">
        <div className="container mx-auto lg:px-12 md:px-10 px-4">
          <div className="lg:flex md:flex items-center">
            <div className="lg:w-6/12 md:w-6/12 lg:pr-32">
              <div className="lg:flex items-start">
                <div className="lg:w-1/12 ">
                  <div className="contact-dot bg-primary">
                    <FeatherIcon
                      icon="map-pin"
                      className="text-white"
                      size={18}
                    />
                  </div>
                </div>
                <div className="lg:w-11/12 lg:ml-5 lg:mt-0 mt-4">
                  <h5
                    className="fsize20 sm-fsize16 font-semibold text-primary dynamicStyle"
                    id="8178921044"
                  >
                    {data ? data['8178921044'] : 'Lorem Ipsum'}
                  </h5>
                  <p
                    className="fsize15 sm-fsize14 col-79 mt-1 sm-line-clamp3 dynamicStyle"
                    id="1234443268"
                    dangerouslySetInnerHTML={{
                      __html: data
                        ? data['1234443268']
                        : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                    }}
                  ></p>
                </div>
              </div>
              <div className="lg:flex items-start lg:mt-10 mt-4">
                <div className="lg:w-1/12 ">
                  <div className="contact-dot bg-primary">
                    <FeatherIcon
                      icon="phone"
                      className="text-white"
                      size={18}
                    />
                  </div>
                </div>
                <div className="lg:w-11/12 lg:ml-5 lg:mt-0 mt-4">
                  <h5
                    className="fsize20 sm-fsize16 font-semibold text-primary dynamicStyle"
                    id="1565958090"
                  >
                    {data ? data['1565958090'] : 'Lorem Ipsum'}
                  </h5>
                  <p
                    className="fsize15 sm-fsize14 col-79 mt-1 dynamicStyle"
                    id="0174859434"
                    dangerouslySetInnerHTML={{
                      __html: data
                        ? data['0174859434']
                        : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                    }}
                  ></p>
                </div>
              </div>
              <div className="lg:flex items-start lg:mt-10 mt-4">
                <div className="lg:w-1/12 ">
                  <div className="contact-dot bg-primary">
                    <FeatherIcon icon="mail" className="text-white" size={18} />
                  </div>
                </div>
                <div className="lg:w-11/12 lg:ml-5 lg:mt-0 mt-4">
                  <h5
                    className="fsize20 sm-fsize16 font-semibold text-primary dynamicStyle"
                    id="7791685749"
                  >
                    {data ? data['7791685749'] : 'Lorem Ipsum'}
                  </h5>
                  <p
                    className="fsize15 sm-fsize14 col-79 mt-1 dynamicStyle"
                    id="6432296175"
                    dangerouslySetInnerHTML={{
                      __html: data
                        ? data['6432296175']
                        : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="lg:w-6/12 md:w-6/12 lg:mt-0 mt-4">
              <AspectRatio ratio={16 / 9} className="address-img shadow">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.952912260219!2d3.375295414770757!3d6.5276316452784755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b2ae68280c1%3A0xdc9e87a367c3d9cb!2sLagos!5e0!3m2!1sen!2sng!4v1567723392506!5m2!1sen!2sng" />
              </AspectRatio>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactDetails1;
