import { Image } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux/es/exports';

const AddBanner4 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <>
      <div className="container mx-auto lg:py-16 lg:px-12 md:py-8 md:pt-4 md:px-6 px-5 py-4">
        <div className="flex justify-center items-center lg:mb-8 md:mb-8 mb-4">
          <div className="text-center">
            <h2 id="0316623467" className="dynamicStyle fsize20 sm-fsize16">
              {' '}
              {data ? data['0316623467'] : 'Get Best Offers'}
            </h2>
            <h2
              id="5473550386"
              className="dynamicStyle fsize36 sm-fsize20 font-semibold"
            >
              {data ? data['5473550386'] : 'Explore The Awesome'}
            </h2>
          </div>
        </div>
        <div id="9145811232" className="dynamicStyle2 w-full">
          <Image
            src={data ? getImage(data['9145811232']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="Ad-image"
            className="object-cover w-full h-450px sm-h-350px br-16px"
          />
        </div>
      </div>
    </>
  );
};

export default AddBanner4;
