import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Button, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AboutSection13 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <>
      <div className="container mx-auto lg:flex md:flex lg:py-16 lg:px-12 md:py-10 md:px-6 px-5 pt-14 md:gap-6">
        <div className="lg:w-1/2 md:w-1/2">
          <div className=" grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 lg:gap-8 md:gap-6 gap-4">
            <div className="dynamicStyle2 w-full" id="7717096584">
              <Image
                src={data ? getImage(data['7717096584']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="banner-image"
                className="bg-primary object-cover br-16px h-200px md-h-150px sm-h-150px "
              />
            </div>
            <div className="dynamicStyle2 w-full" id="2462052310">
              <Image
                src={data ? getImage(data['2462052310']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="banner-image"
                className="bg-primary object-cover br-16px h-200px md-h-150px sm-h-150px "
              />
            </div>
            <div className="dynamicStyle2 w-full" id="5435108415">
              <Image
                src={data ? getImage(data['5435108415']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="banner-image"
                className="bg-primary object-cover br-16px h-200px md-h-150px sm-h-150px "
              />
            </div>
            <div className="dynamicStyle2 w-full" id="9306518570">
              <Image
                src={data ? getImage(data['9306518570']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="banner-image"
                className="bg-primary object-cover br-16px h-200px md-h-150px sm-h-150px "
              />
            </div>
          </div>
        </div>
        <div
          className="lg:w-1/2 md:w-1/2 sm-py-6 "
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="flex flex-col justify-center h-auto">
            <div className="lg:px-12 h-48">
              <h2
                id="9320302655"
                className="dynamicStyle fsize16 font-semibold md:pb-1 sm-pb-1  cust-textColor"
              >
                {data ? data['9320302655'] : 'Apple'}
              </h2>
              <h1
                id="6960265695"
                className="dynamicStyle fsize35 md-font-28 sm-fsize20 font-semibold leading-10  cust-textColor"
              >
                {data ? data['6960265695'] : 'Lorem ipsum dolor sit amet.'}
              </h1>
              <p
                id="2168995819"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['2168995819']
                    : ' Authorized Apple Distributor 2',
                }}
                className="dynamicStyle textColor-light sm-fsize14 lg:my-4 md:my-4 my-2 font-normal"
              >
                {/* Authorized Apple Distributor 2 */}
              </p>
              <Button
                colorScheme="primary"
                color="white"
                id="1264488637"
                px={8}
                py={6}
                className="dynamicStyle cust-bgColor text-white font-semibold sm-fsize14 "
              >
                <span onClick={() => handleClick('1264488637')}>
                  {data ? data['1264488637'] : 'Buy Now'}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection13;
