import {
  Button,
  Input,
  Select,
  Textarea,
  RadioGroup,
  HStack,
  Radio,
  Image,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import axios from "axios";
import {
  getFormSchema,
  getSchemaByFormId,
  getInputByFormId,
} from "../../../../redux/forms/forms-action";
import { useDispatch } from "react-redux";

import { useSearchParams } from "react-router-dom";

import { getData } from "../../../../redux/forms/forms-action";

const CareersForm = ({ pageName, formId }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const sectionName = useSelector((state) => state.cms.sectionNames[pageName]);
  let getallField = useSelector((state) => state.Forms.FormInput);
  let getFormName = useSelector((state) => state.Forms.Forms);
  // let getSchemaForm = useSelector((state) => state.Forms.FormSchemaByFormId);

  let userToken = useSelector((state) => state.AuthDetails.user.token);

  const [formFields, setForm] = useState({});
  const [change, setChange] = useState(false);
  const [formData, setFormData] = useState("");
  const [getSchemaForm, setGetSchemaForm] = useState("");

  const [params, setParams] = useSearchParams();
  const [jobDetails, setJobDetails] = useState({});

  useEffect(() => {
    async function mount() {
      if (params) {
        let jobId = params.get("ji");

        let payload = {
          projectId: process.env.REACT_APP_ID,
          projectName: process.env.REACT_APP_COMMUNITY_NAME,
          _id: jobId,
        };
        let data = await dispatch(getData("careers-job_postings", payload));
        if (data) setJobDetails(data.result[0]);
      }
      if (formId) {
        let payload = {
          formId: formId,
        };

        let formDetails = await dispatch(getInputByFormId(payload));
        setFormData(formDetails);
        let formSchema = await dispatch(getSchemaByFormId(payload));
        setGetSchemaForm(formSchema);
      } else {
        let formSection = sectionName.filter(
          (x) => x.sectionId === "1305438918"
        );

        if (formSection.length && formSection[0].form) {
          let payload = {
            formId: formSection[0].form,
          };

          let formDetails = await dispatch(getInputByFormId(payload));
          setFormData(formDetails);
          let formSchema = await dispatch(getSchemaByFormId(payload));
          setGetSchemaForm(formSchema);
        }
      }
    }
    mount();
  }, [dispatch]);
  console.log(formFields, "from value");
  //getallField[0].field.value,

  const handleClear = () => {
    if (getallField) {
      let tempForm = {};
      getallField.map((x) => {
        if (x.inputType != "button") tempForm[x.value] = "";
      });
      setForm(tempForm);
    }
  };

  const handleSubmit = async () => {
    console.log(getFormSchema, "jobdet");
    let payload = {
      projectId: process.env.REACT_APP_ID,
      projectName: process.env.REACT_APP_COMMUNITY_NAME,
      jobDetails: jobDetails._id,
    };

    payload = { ...formFields, ...payload };

    let key = Object.keys(payload);
    let value = Object.values(payload);
    let formData = new FormData();

    console.log(payload, "cloudpl");
    for (let i = 0; i < key.length; i++) {
      if (value[i] && value[i].type && value[i].type === "files") {
        for (let j = 0; j < value[i].files.length; j++) {
          formData.append(key[i], value[i].files[j]);
        }
      } else {
        formData.append(key[i], value[i]);
      }
    }

    let output = await axios.post(
      `${process.env.REACT_APP_BASE_URL}forms/create/${getSchemaForm[0].schemaName}`,
      formData,

      {
        headers: {
          "community-name": process.env.REACT_APP_COMMUNITY_NAME,
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (output && output.data) {
      handleClear();
    }
  };

  const handleChange = async (e, field, item) => {
    let fields;

    if (item === "file") {
      fields = { ...formFields, [field]: e.target.files[0] };
    } else if (item === "files") {
      fields = {
        ...formFields,
        [field]: { type: "files", files: e.target.files },
      };
    } else if (item === "radio") {
      fields = { ...formFields, [field]: e };
    } else {
      fields = { ...formFields, [field]: e.target.value };
      // console.log('ENtered else');
    }

    setForm(fields);
  };
  return (
    <section>
      <div className="cust-bgf5f5f5">
        <div className="container mx-auto">
          <div className="lg:flex md:flex block w-full lg:px-12 md:px-10 px-5 lg:py-16 md:py-16 py-10">
            <div className="lg:w-1/2 md:w-5/12 w-full lg:pr-5 md:pr-3">
              <h6
                id="5479190096"
                className="dynamicStyle fsize30 md-fsize24 sm-fsize17 font-semibold"
              >
                {data ? data["5479190096"] : " Apply for this job"}
              </h6>
              <p
                dangerouslySetInnerHTML={{
                  __html: data ? data["9130405014"] : "no-data",
                }}
                id="9130405014"
                className="dynamicStyle mt-4 fsize16 sm-fsize13 text-dark"
              ></p>
              <div className="dynamicStyle2" id="8452358833">
                <Image
                  src={data ? getImage(data["8452358833"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="heroImage"
                  className="dynamicStyle2 mt-3 w-full lg:h-80 md:h-40  h-32"
                />
              </div>
            </div>
            <div className="lg:w-1/2 md:w-7/12 w-full lg:pl-5 md:pl-3 lg:mt-0 md:mt-0 mt-4">
              <div className="bg-white cust-shadow rounded-lg lg:p-10 md:p-6 p-5">
                <div>
                  <div className="lg:grid md:grid grid-cols-2 gap-4 ">
                    {getallField
                      ? getallField.map((item, index) => {
                          return (
                            <>
                              {item.inputType !== "button" &&
                                item.inputType !== "mappedTable" && (
                                  <div className="lg:pb-0 md:pb-0 pb-3 ">
                                    {item.inputType !== "button" &&
                                      item.inputType !== "mappedTable" && (
                                        <p
                                          id="1437369070"
                                          className=" lg:pb-2 md:pb-2 pb-1 clr-191E22"
                                        >
                                          {item.label}
                                        </p>
                                      )}
                                    {item.inputType == "input" && (
                                      <Input
                                        className="p-2 inp-bdr w-full bg-white"
                                        placeholder={item.placeholder}
                                        value={formFields[item.value]}
                                        maxlength={item.maxLength}
                                        minlength={item.minLength}
                                        onChange={(e) => {
                                          handleChange(e, item.value);
                                        }}
                                      />
                                    )}
                                    {item.inputType == "multiline" && (
                                      <Textarea
                                        className="p-2 inp-bdr w-full"
                                        placeholder={item.placeholder}
                                        value={formFields[item.value]}
                                        maxlength={item.maxLength}
                                        minlength={item.minLength}
                                        onChange={(e) => {
                                          handleChange(e, item.value);
                                        }}
                                      />
                                    )}
                                    {item.inputType == "phone" && (
                                      <Input
                                        className="p-2 inp-bdr w-full"
                                        placeholder={item.placeholder}
                                        value={formFields[item.value]}
                                        maxlength={item.maxLength}
                                        minlength={item.minLength}
                                        onChange={(e) => {
                                          handleChange(e, item.value);
                                        }}
                                      />
                                    )}
                                    {item.inputType == "email" && (
                                      <Input
                                        type="email"
                                        className="p-2 inp-bdr w-full"
                                        placeholder={item.placeholder}
                                        value={formFields[item.value]}
                                        maxlength={item.maxLength}
                                        minlength={item.minLength}
                                        onChange={(e) => {
                                          handleChange(e, item.value);
                                        }}
                                      />
                                    )}
                                    {item.inputType == "url" && (
                                      <Input
                                        className="p-2 inp-bdr w-full"
                                        placeholder={item.placeholder}
                                        value={formFields[item.value]}
                                        maxlength={item.maxLength}
                                        minlength={item.minLength}
                                        onChange={(e) => {
                                          handleChange(e, item.value);
                                        }}
                                      />
                                    )}
                                    {item.inputType === "singleselect" && (
                                      <Select
                                        placeholder={item.placeholder}
                                        value={formFields[item.value]}
                                        className="fsize14"
                                        onChange={(e) => {
                                          handleChange(e, item.value);
                                        }}
                                      >
                                        {item.enum.map((i) => {
                                          return <option value={i}>{i}</option>;
                                        })}
                                      </Select>
                                    )}
                                    {item.inputType === "radiobutton" && (
                                      <RadioGroup
                                        defaultValue="Itachi"
                                        onChange={(e) => {
                                          handleChange(e, item.value, "radio");
                                        }}
                                      >
                                        <HStack spacing="24px">
                                          {item.enum.map((e) => {
                                            return <Radio value={e}>{e}</Radio>;
                                          })}
                                        </HStack>
                                      </RadioGroup>
                                    )}
                                    {item.inputType === "imagefile" && (
                                      <input
                                        type="file"
                                        className="p-2 inp-bdr w-full"
                                        placeholder={item.placeholder}
                                        onChange={(e) => {
                                          handleChange(e, item.value, "file");
                                        }}
                                      />
                                    )}
                                    {item.inputType === "file" && (
                                      <input
                                        type="file"
                                        className="p-2 inp-bdr w-full"
                                        placeholder={item.placeholder}
                                        onChange={(e) => {
                                          handleChange(e, item.value, "file");
                                        }}
                                      />
                                    )}

                                    {item.inputType === "multipleimagefile" && (
                                      <input
                                        type="file"
                                        multiple
                                        className="p-2 inp-bdr w-full"
                                        placeholder={item.placeholder}
                                        onChange={(e) => {
                                          handleChange(e, item.value, "files");
                                        }}
                                      />
                                    )}
                                    {/* {item.inputType == '"button"' && (
                      <Input
                        type="file"
                        className="p-2 inp-bdr w-full"
                        placeholder={item.placeholder}
                        value={item.value}
                        maxlength={item.maxLength}
                        minlength={item.minLength}
                      />
                    )} */}
                                  </div>
                                )}
                            </>
                          );
                        })
                      : ""}
                  </div>

                  {getallField
                    ? getallField.map((item) => {
                        return (
                          <div className=" text-center">
                            {item.inputType == "button" && (
                              <Button
                                onClick={() => handleSubmit()}
                                colorScheme="primary"
                                px="8"
                                className=" font-semibold  mt-6"
                              >
                                <span> {item.label}</span>
                              </Button>
                            )}
                          </div>
                        );
                      })
                    : "no-data"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareersForm;
