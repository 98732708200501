import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Team4 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="container mx-auto md:px-6 lg:px-12 px-5 lg:py-16 md:py-16 py-8">
      <div className="text-center lg:mb-16 md:mb-12 mb-8 lg:w-1/2 md:w-full w-full mx-auto">
        <h1
          id="4425122477"
          className="dynamicStyle cust-textColor fsize35 sm-fsize24 font-semibold"
        >
          {data ? data['4425122477'] : 'Meet Aur Professional teams'}
        </h1>

        <p id="4132953528" className="dynamicStyle textColor-light fsize18 sm-fsize14">
          {data ? data['4132953528'] : 'Lorem Ipsum'}
        </p>
      </div>

      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-8">
        <Card
        className={
          colorMode === 'light'
            ? 'border p-2 w-full rounded-lg'
            : 'p-2 w-full rounded-lg'
        }
      
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="0833839011" className="dynamicStyle2 overflow-hidden w-full">
            <Image
              src={data ? getImage(data['0833839011']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="teams-card"
              className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
            />
          </div>

          <p
            id="1238487956"
            className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2 cust-textColor"
          >
            {data ? data['1238487956'] : 'Name'}
          </p>
          <p id="4269037582" className="dynamicStyle fsize14 text-center textColor-light">
            {data ? data['4269037582'] : 'Designation'}
          </p>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'border p-2 w-full rounded-lg'
              : 'p-2 w-full rounded-lg'
          }
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="9697954774" className="dynamicStyle2 overflow-hidden w-full">
            <Image
              src={data ? getImage(data['9697954774']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="teams-card"
              className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
            />
          </div>

          <p
            id="5030403054"
            className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2 cust-textColor"
          >
            {data ? data['5030403054'] : 'Name'}
          </p>
          <p id="3726017778" className="dynamicStyle fsize14 text-center textColor-light">
            {data ? data['3726017778'] : 'Designation'}
          </p>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'border p-2 w-full rounded-lg'
              : 'p-2 w-full rounded-lg'
          }
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="7260685036" className="dynamicStyle2 overflow-hidden w-full">
            <Image
              src={data ? getImage(data['7260685036']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="teams-card"
              className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
            />
          </div>

          <p
            id="4419954649"
            className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2 cust-textColor"
          >
            {data ? data['4419954649'] : 'Name'}
          </p>
          <p id="4101880019" className="dynamicStyle fsize14 text-center textColor-light">
            {data ? data['4101880019'] : 'Designation'}
          </p>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'border p-2 w-full rounded-lg'
              : 'p-2 w-full rounded-lg'
          }
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="1440595073" className="dynamicStyle2 overflow-hidden w-full">
            <Image
              src={data ? getImage(data['1440595073']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="teams-card"
              className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
            />
          </div>

          <p
            id="8842669069"
            className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2 cust-textColor"
          >
            {data ? data['8842669069'] : 'Name'}
          </p>
          <p id="0717922668" className="dynamicStyle fsize14 text-center textColor-light">
            {data ? data['0717922668'] : 'Designation'}
          </p>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'border p-2 w-full rounded-lg'
              : 'p-2 w-full rounded-lg'
          }
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="2589246464" className="dynamicStyle2 overflow-hidden w-full">
            <Image
              src={data ? getImage(data['2589246464']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="teams-card"
              className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
            />
          </div>

          <p
            id="1457627200"
            className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2 cust-textColor"
          >
            {data ? data['1457627200'] : 'Name'}
          </p>
          <p id="0186939738" className="dynamicStyle fsize14 text-center textColor-light">
            {data ? data['0186939738'] : 'Designation'}
          </p>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'border p-2 w-full rounded-lg'
              : 'p-2 w-full rounded-lg'
          }
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="2731998124" className="dynamicStyle2 overflow-hidden w-full">
            <Image
              src={data ? getImage(data['2731998124']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="teams-card"
              className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
            />
          </div>

          <p
            id="8219658739"
            className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2 cust-textColor"
          >
            {data ? data['8219658739'] : 'Name'}
          </p>
          <p id="3565940364" className="dynamicStyle fsize14 text-center textColor-light">
            {data ? data['3565940364'] : 'Designation'}
          </p>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'border p-2 w-full rounded-lg'
              : 'p-2 w-full rounded-lg'
          }
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="0636077267" className="dynamicStyle2 overflow-hidden w-full">
            <Image
              src={data ? getImage(data['0636077267']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="teams-card"
              className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
            />
          </div>

          <p
            id="7249279118"
            className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2 cust-textColor"
          >
            {data ? data['7249279118'] : 'Name'}
          </p>
          <p id="3807967083" className="dynamicStyle fsize14 text-center textColor-light">
            {data ? data['3807967083'] : 'Designation'}
          </p>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'border p-2 w-full rounded-lg'
              : 'p-2 w-full rounded-lg'
          }
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="3278423164" className="dynamicStyle2 overflow-hidden w-full">
            <Image
              src={data ? getImage(data['3278423164']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="teams-card"
              className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
            />
          </div>

          <p
            id="3021033283"
            className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2 cust-textColor"
          >
            {data ? data['3021033283'] : 'Name'}
          </p>
          <p id="5932911232" className="dynamicStyle fsize14 text-center textColor-light">
            {data ? data['5932911232'] : 'Designation'}
          </p>
        </Card>
      </div>
    </section>
  );
};

export default Team4;
