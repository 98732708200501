import React, { useEffect } from 'react';
import cardBG from '../../../assets/images/CardBg.svg';
import { Image, CardBody, Card, useColorMode } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux/es/exports';
import Aos from 'aos';
import 'aos/dist/aos.css';
const FeatureCard = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section
    
    className={
      colorMode === 'light'
        ? 'bg-secondary'
        : 'cust-bgColor '
    }>
      {' '}
      <div className=" container mx-auto  lg:py-16 md:py-16 py-8 lg:px-12 md:px-6 px-5">
        <div className="text-center lg:mb-14 md:mb-14 mb-6 lg:mt-0 md:mt-0 mt-0">
          <h2
            id="4745715827"
            className="dynamicStyle lg:text-4xl md:text-2xl text-2xl text-white font-semibold mb-2"
          >
            {data ? data['4745715827'] : 'HOW FIT2GOASIA HELP YOU'}
          </h2>
          <p
            id="2752292551"
            className="dynamicStyle lg:text-base md:text-base text-sm mb-2 text-white"
          >
            {data
              ? data['2752292551']
              : 'Tailored Programs to Meet Your Unique Goals'}
          </p>
        </div>

        <div className="lg:grid md:grid lg:grid-cols-3  md:grid-cols-2 lg:gap-20 md:gap-12">
          <div
            className="relative"
            data-aos="zoom-in"
            data-aos-once="true"
            delay={1200}
          >
            {/* <Image
              src={cardBG}
              alt="image"
              className="cardbgset sm-none w-full"
            /> */}

            <Card bg="white" className=" cardimage ">
              <CardBody>
                <div className="lg:px-4 md:px-2 lg:py-8 md:py-6">
                  <h2
                    id="0410418952"
                    className="dynamicStyle font-semibold text-center lg:text-2xl md:text-xl text-xl "
                  >
                    {data ? data['0410418952'] : 'PERSONAL TRAINING'}
                  </h2>
                  <p
                    id="3462663024"
                    className="dynamicStyle text8787  lg:line-clamp-5  md:line-clamp-5 lg:text-base md:text-base text-sm mt-4 lg:leading-7 md:leading-6 leading-6"
                    dangerouslySetInnerHTML={{
                      __html: data ? data['3462663024'] : 'no-data',
                    }}
                  ></p>
                </div>
              </CardBody>
            </Card>
          </div>

          <div
            className="relative lg:mt-0 md:mt-0 mt-4"
            data-aos="zoom-in"
            data-aos-once="true"
            delay={1200}
          >
            {/* <Image
              src={cardBG}
              alt="image"
              className="cardbgset sm-none w-full"
            /> */}
            <Card bg="white" className=" cardimage ">
              <CardBody>
                <div className="lg:px-4 md:px-2 lg:py-8 md:py-6">
                  <h2
                    id="9572759646"
                    className="dynamicStyle font-semibold text-center lg:text-2xl md:text-xl text-xl "
                  >
                    {data ? data['9572759646'] : 'GROUP CLASSES'}
                  </h2>
                  <p
                    id="6392396673"
                    className="leading-6 dynamicStyle text8787  lg:line-clamp-5  md:line-clamp-5 lg:text-base md:text-base text-sm  mt-4 lg:leading-7 md:leading-6 leading-6"
                    dangerouslySetInnerHTML={{
                      __html: data ? data['6392396673'] : 'no-data',
                    }}
                  ></p>
                </div>
              </CardBody>
            </Card>
          </div>

          <div
            className="relative lg:mt-0 md:mt-0 mt-4"
            data-aos="zoom-in"
            data-aos-once="true"
            delay={1200}
          >
            {/* <Image
              src={cardBG}
              alt="image"
              className="cardbgset sm-none w-full"
            /> */}
            <Card bg="white" className=" cardimage ">
              <CardBody>
                <div className="lg:px-4 md:px-2 lg:py-8 md:py-6">
                  <h2
                    id="7320836968"
                    className="dynamicStyle font-semibold text-center lg:text-2xl md:text-xl text-xl "
                  >
                    {data ? data['7320836968'] : 'ONLINE COACHING'}
                  </h2>
                  <p
                    id="9711851814"
                    dangerouslySetInnerHTML={{
                      __html: data ? data['9711851814'] : 'no-data',
                    }}
                    className="leading-6 dynamicStyle text8787 lg:line-clamp-5  md:line-clamp-5  lg:text-base md:text-base text-sm mt-4 lg:leading-7 md:leading-6 leading-6"
                  ></p>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard;
