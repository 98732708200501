import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const CategoriesOption1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="container mx-auto lg:py-12 md:py-6 py-6 lg:px-12 md:px-8 px-5">
      <p
        id="1500315733"
        className="dynamicStyle fsize34 md:fsize26 sm-fsize22 font-medium mb-4 clr-0E1317"
      >
        {data ? data['1500315733'] : 'Categories'}
      </p>
      <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-6 md:gap-4 gap-2">
        <Link to="/african-fabrics" className="relative">
          <Image
            className="cat-wh w-full "
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
          />
          <div className="txt-absl">
            <p className="fsize28 md-fsize24 sm-fsize15 clr-fffaf0 font-semibold text-center">
              African Wax Fabrics
            </p>
          </div>
        </Link>
        <Link to="/printed-cotton" className="relative">
          <Image
            className="cat-wh w-full"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
          />
          <div className="txt-absl">
            <p className="fsize28 md-fsize24 sm-fsize15 clr-fffaf0 font-semibold text-center">
              Printed Cotton Fabric
            </p>
          </div>
        </Link>
        <Link to="/polyester" className="relative">
          <Image
            className="cat-wh w-full"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
          />
          <div className="txt-absl">
            <p className="fsize28 md-fsize24 sm-fsize15 clr-fffaf0 font-semibold text-center">
              Polyester Print Fabric
            </p>
          </div>
        </Link>
        <Link to="/tie-dye" className="relative">
          <Image
            className="cat-wh w-full"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
          />
          <div className="txt-absl">
            <p className="fsize28 md-fsize24 sm-fsize15 clr-fffaf0 font-semibold text-center">
              Tie Dye
            </p>
          </div>
        </Link>
        <Link to="/nighty-fabrics" className="relative">
          <Image
            className="cat-wh w-full"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
          />
          <div className="txt-absl">
            <p className="fsize28 md-fsize24 sm-fsize15 clr-fffaf0 font-semibold text-center">
              Nighty Printed Cotton Fabric
            </p>
          </div>
        </Link>
        <Link to="/nighty-fabrics" className="relative">
          <Image
            className="cat-wh w-full"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
          />
          <div className="txt-absl">
            <p className="fsize28 md-fsize24 sm-fsize15 clr-fffaf0 font-semibold text-center">
              Nighty Printed Cotton Fabric
            </p>
          </div>
        </Link>
        <Link to="/real-wax" className="relative">
          <Image
            className="cat-wh w-full"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
          />
          <div className="txt-absl">
            <p className="fsize28 md-fsize24 sm-fsize15 clr-fffaf0 font-semibold text-center">
              Lungi Cotton Fabrics
            </p>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default CategoriesOption1;
