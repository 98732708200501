import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVICE_BASE_URL;

const API = axios.create({
  baseURL,
  headers: {
    'community-name': process.env.REACT_APP_COMMUNITY_NAME,
    accept: 'application/json',
  },
});

export default API;
