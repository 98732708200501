import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';

import Aos from 'aos';
import 'aos/dist/aos.css';
import FeatherIcon from 'feather-icons-react';

const FeatureCard9 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className="lg:px-12 md:px-8 px-4 lg:py-20 md:py-16 py-8">
      <div className="container mx-auto">
        <h4
          className="fsize30 sm-fsize20 font-semibold text-center dynamicStyle cust-textColor mb-2"
          id="3079268107"
        >
          {data ? data['3079268107'] : 'Meet Aur Professional teams'}
        </h4>
        <div className="lg:w-7/12 md:w-9/12 mx-auto">
          <p
            id="3287910140"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['3287910140']
                : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus',
            }}
            className="fsize17 sm-fsize14 textColor-light font-medium text-center mt-3 dynamicStyle"
          ></p>
        </div>
        <div className="lg:mt-12 md:mt-9 mt-5 lg:w-11/12 mx-auto">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
            {data && data['6972014208']
              ? data['6972014208'].map(e => {
                  return (
                    <div
                      className="w-full rounded-lg relative hover-images"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      <div className=" lg:h-72 md:h-60 h-52 w-full rounded-lg borderfeature">
                        {/* <Image
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="imagee"
                          className="lg:h-72 md:h-60 h-52 w-full object-cover rounded-lg filter-b5 bg-d2d2d2 featureimage"
                        /> */}
                      </div>
                      <div className="absolute top-0 left-0 lg:p-4 md:p-4 p-3 colorcard">
                        <div className="flex items-center gap-1">
                          <FeatherIcon
                            icon="folder"
                            size={15}
                            className="flex"
                          />
                          <p className=" fsize15 font-medium sm-fsize14">
                            {e.field2}
                          </p>
                        </div>
                        <h2 className=" fsize22 md-fsize20 sm-fsize18 font-semibold lg:mt-2 mt-1">
                          {e.field3}
                        </h2>
                      </div>
                      <div className="absolute bottom-0 left-0 lg:p-4 md:p-4 p-3 colorcard">
                        <div className="flex items-center gap-1">
                          <FeatherIcon
                            icon="clock"
                            size={15}
                            className="flex"
                          />
                          <p className=" fsize15 font-medium sm-fsize14">
                            {e.field4}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              : 'no-data'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard9;
