import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { Button, Tooltip } from '@chakra-ui/react';
import Select from 'react-select';
import FeatherIcon from 'feather-icons-react';
import { setTargetLanguage } from '../../../../redux/Aiassist/Aiassist-actions';
import { ExporttoPdf } from './Core/ExporttoPdf';
const Chat = ({ section, onOpen }) => {
  const dispatch = useDispatch();
  const conversationRef = useRef();
  const [selectedOption, setSelectedOption] = useState({
    value: 'en-US',
    label: 'English',
  });
  let ConversationHistory = useSelector(
    state => state.Aiassist.ConversationHistory
  );
  let targetLanguage = useSelector(state => state.Aiassist.targetLanguage);
  let options = [
    { value: 'en-US', label: 'English' },
    { value: 'es-MX', label: 'Spanish' },
    { value: 'hi-IN', label: 'Hindi' },
  ];
  const handleChange = selectedOption => {
    console.log(selectedOption);
    setSelectedOption(selectedOption);
    dispatch(setTargetLanguage(selectedOption.value));
  };
  return (
    <div
      className={
        section === true
          ? 'bg-fa h-chat lg:p-5 p-3 w-full rounded-lg relative'
          : 'bg-fa h-chat lg:p-5 p-3 lg:w-7/12 md:w-7/12 w-full rounded-lg relative'
      }
    >
      <div className="flex justify-between items-center w-full">
        <div className="lg:w-4/12 w-6/12">
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
          />
        </div>
        <div className="lg:w-8/12 w-6/12 flex items-center justify-end gap-3">
          {/* <Tooltip label="Email transcript">
            <Button
              colorScheme="white"
              className="cust-shadow text-primary"
              px={2}
            >
              <FeatherIcon icon="mail" size="17" />
            </Button>
          </Tooltip> */}
          <Tooltip label="Download transcript">
            <Button
              colorScheme="white"
              className="cust-shadow text-primary"
              px={2}
              onClick={() => {
                ExporttoPdf(conversationRef);
              }}
            >
              <FeatherIcon icon="download" size="17" />
            </Button>
          </Tooltip>
          {section === true ? (
            <>
              <div className="lg:flex md:flex hidden">
                <Button colorScheme="primary" px={5} onClick={onOpen}>
                  <FeatherIcon icon="mic" size="16" className="mr-1 flex" />{' '}
                  Start new
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div ref={conversationRef} className="h-chatscroll pt-5 lg:pt-10">
        {ConversationHistory.map((bubble, index) => {
          if (index >= 1) {
            if (bubble.role == 'user') {
              return (
                <div className="lg:mt-6 mt-4 text-right flex justify-end">
                  <div className="">
                    <p className="fsize12 text-dark">You</p>
                    <div className="cust-bgf5f5f5 rounded-lg w-max max-chat px-4 py-2 my-1">
                      <p className="whitespace-pre-wrap fsize16 sm-fsize14 text-dark">
                        {bubble.content}
                      </p>
                    </div>
                    <p className="fsize12 text-dark">{bubble.timestamp}</p>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="lg:mt-6 mt-4 text-left flex justify-start">
                  <div>
                    <p className="fsize12 text-dark">Admin</p>
                    <div className="bg-primary rounded-lg w-max max-chat2 px-4 py-2 my-1">
                      <p className="whitespace-pre-wrap fsize16 sm-fsize14 text-white">
                        {bubble.content}
                      </p>
                    </div>
                    <p className="fsize12 text-dark">{bubble.timestamp}</p>
                  </div>
                </div>
              );
            }
          }
        })}
      </div>
      <div
        className={
          section === true ? '  p-3 absolute bottom-0 left-0 w-full' : 'hidden'
        }
      >
        <div className="bg-ffefd0  rounded-lg p-3">
          <p className="text-center  fsize14">
            You voice chat has been ended. You can
            <span className="text-primary mx-1">email or download</span>
            the transcript. <br></br>Please click on the start button to start a
            new voice chat.
          </p>
        </div>
        <div className="lg:hidden md:hidden block">
          <Button
            colorScheme="primary"
            className="w-full"
            px={6}
            mt={2}
            onClick={onOpen}
          >
            Start New
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Chat;
