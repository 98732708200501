import React, { useEffect, useState } from 'react';
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import { useSelector } from 'react-redux/es/exports';
import { setisGPTResponseReceived } from '../../../../../redux/Aiassist/Aiassist-actions';
const SpeakText = async (
  dispatch,
  synthensizer,
  emotion,
  selectedModel,
  inputText
) => {
  if (synthensizer) {
    await new Promise(resolve => {
      synthensizer.speakSsmlAsync(
        `
            <speak version="1.0" xmlns="https://www.w3.org/2001/10/synthesis"
            xml:lang="en-US">
            <voice style="${emotion}" name="${selectedModel}">
                ${inputText}
            </voice>
            </speak>
        `,
        result => {
          if (result.reason === ResultReason.SynthesizingAudioCompleted) {
            console.log(`Speech synthensis succeeded for :${inputText}`);

            dispatch(setisGPTResponseReceived(false));

            resolve();
          } else {
            console.log('Speech synthesis error', result.errorDetails);
            resolve();
          }
        }
      );
    });
  } else {
    console.error('Speech synthensizer not initialized');
  }
};
export default SpeakText;
