import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Cardtext2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="lg:py-12 py-8 container mx-auto px-4 lg:px-12 md:px-12">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
        <div
          className="border-primary rounded-lg p-5"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <h5
            className="fsize17 sm-fsize16 text-dark text-center font-semibold dynamicStyle"
            id="6714256754"
          >
            {data ? data['6714256754'] : 'Lorem Ipsum'}
          </h5>
          <div className="flex justify-center mt-2">
            <Button
              colorScheme="white"
              className="mt-2 bg-primary sm-fsize13 lg:px-4 px-5 dynamicStyle"
              size="md"
              id="8399951329"
            >
              {data ? data['8399951329'] : 'Lorem Ipsum'}
            </Button>
          </div>
        </div>

        <div
          className="border-primary rounded-lg p-5"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <h5
            className="fsize17 sm-fsize16 text-dark text-center font-semibold dynamicStyle"
            id="1321629097"
          >
            {data ? data['1321629097'] : 'Lorem Ipsum'}
          </h5>
          <div className="flex justify-center mt-2">
            <Button
              colorScheme="white"
              className="mt-2 bg-primary sm-fsize13 lg:px-4 px-5 dynamicStyle"
              size="md"
              id="3715346425"
            >
              {data ? data['3715346425'] : 'Lorem Ipsum'}
            </Button>
          </div>
        </div>

        <div
          className="border-primary rounded-lg p-5"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <h5
            className="fsize17 sm-fsize16 text-dark text-center font-semibold dynamicStyle"
            id="0974332954"
          >
            {data ? data['0974332954'] : 'Lorem Ipsum'}
          </h5>
          <div className="flex justify-center mt-2">
            <Button
              colorScheme="white"
              className="mt-2 bg-primary sm-fsize13 lg:px-4 px-5 dynamicStyle"
              size="md"
              id="3335115058"
            >
              {data ? data['3335115058'] : 'Lorem Ipsum'}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cardtext2;
