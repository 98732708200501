import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';

const Boardofdirectors2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-10 lg:py-16 py-8 px-4">
        <h2
          id="9045398871"
          className="dynamicStyle fsize35 sm-fsize24 font-semibold text-center text-primary"
        >
          {data ? data['9045398871'] : 'Key Management Team'}
        </h2>

        <div className="mt-5 lg:mt-10 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 grid gap-8">
          <div
            className="border rounded-lg overflow-hidden"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="3688270895" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['3688270895']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full h-40 object-contain lg:h-52"
                alt="board"
              />
            </div>
            <div className="p-5">
              <h6
                id="2207494494"
                className="dynamicStyle text-dark text-center font-semibold fsize18 sm-fsize16"
              >
                {data ? data['2207494494'] : 'Mr Dinesh Khara'}
              </h6>
              <p
                id="1369953031"
                className="dynamicStyle col-79 text-center fsize13 mt-1"
              >
                {data
                  ? data['1369953031']
                  : 'Nominee Director & Chairman of the Board'}
              </p>
            </div>
          </div>
          <div
            className="border rounded-lg overflow-hidden"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="7100296289" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['7100296289']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full h-40 object-contain lg:h-52"
                alt="board"
              />
            </div>
            <div className="p-5">
              <h6
                id="9309426218"
                className="dynamicStyle text-dark text-center font-semibold fsize18 sm-fsize16"
              >
                {data ? data['9309426218'] : 'Mr Dinesh Khara'}
              </h6>
              <p
                id="6278829433"
                className="dynamicStyle col-79 text-center fsize13 mt-1"
              >
                {data
                  ? data['6278829433']
                  : 'Nominee Director & Chairman of the Board'}
              </p>
            </div>
          </div>
          <div
            className="border rounded-lg overflow-hidden"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="1330311467" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['1330311467']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full h-40 object-contain lg:h-52"
                alt="board"
              />
            </div>
            <div className="p-5">
              <h6
                id="9943564981"
                className="dynamicStyle text-dark text-center font-semibold fsize18 sm-fsize16"
              >
                {data ? data['9943564981'] : 'Mr Dinesh Khara'}
              </h6>
              <p
                id="8513718892"
                className="dynamicStyle col-79 text-center fsize13 mt-1"
              >
                {data
                  ? data['8513718892']
                  : 'Nominee Director & Chairman of the Board'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Boardofdirectors2;
