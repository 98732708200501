import React, { useEffect } from 'react';
import { Image, Button } from '@chakra-ui/react';
import { Star } from 'react-feather';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { getAllCourses } from '../../../../redux/elearningProduct/products-action';
import { useDispatch } from 'react-redux';
const ProductSection1 = ({ pageName }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);
  let allCourse = useSelector(state => state.elearningProduct.Course);
  console.log(allCourse);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const navigate = useNavigate();

  return (
    <div className="container m-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
      <div>
        <h2
          id="1803087679"
          className="dynamicStyle  fsize30 sm-fsize24 text-center font-semibold"
        >
          {data ? data['1803087679'] : 'Popular Course'}
        </h2>
        <p id="2366074992" className="dynamicStyle  sm-fsize14 text-center">
          {data
            ? data['2366074992']
            : 'Lets join our famous class, the knowledge provided will'}
        </p>
        <div className=" lg:pt-12 md:pt-10 pt-8 lg:pb-12 md:pb-10 pb-8">
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            className="mySwiper"
            breakpoints={{
              1536: {
                slidesPerView: 3.2,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 3.2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3.2,
                spaceBetween: 25,
              },
              820: {
                slidesPerView: 2.2,
                spaceBetween: 25,
              },
              768: {
                slidesPerView: 2.2,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              325: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {allCourse && allCourse.result && allCourse.result.length > 0
              ? allCourse.result.map(item => {
                  return (
                    <SwiperSlide>
                      <div
                        className=""
                        onClick={() =>
                          navigate(`/course-details?ci=${item._id} `)
                        }
                      >
                        <div className="relative ">
                          <div className="overflow-hidden">
                            <Image
                              src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                              alt="img"
                              className="h-200px w-full m-auto object-cover  custimghover"
                            />
                          </div>
                          <div className="absolute top-0">
                            <div className="bg-white px-2 py-1 flex gap-1 items-center">
                              <p className=" fsize14">4.9/5 </p>
                              <span>
                                <Star className="w-4 h-4" />
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="px-5 bg-primary h-fit lg:py-10 md:py-6 py-4 custcardcourse">
                          <div className="flex items-center justify-between pb-2 ">
                            <p className="text-white fsize14">
                              {item['course name']}
                            </p>
                            <p className="text-white fsize14">
                              {item.category &&
                              item.category.length &&
                              item.category[0].length > 0 &&
                              item.category[0][0].name
                                ? item.category[0][0].name
                                : ''}
                            </p>
                          </div>
                          <p className="text-white fsize14 font-medium">
                            {item.coursecontent}
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              : 'no-data'}
          </Swiper>
        </div>
        <div className=" flex justify-center">
          <Button
            size="lg"
            colorScheme="primary"
            className="dynamicStyle rounded-full  text-white"
          >
            <a href="/elearning-course">
              {data ? data['9139129553'] : 'All Course'}
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductSection1;
