import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import {
  loginwithPassword,
  createMFA,
  verifyMFA,
  SaveBasicProfileDetails,
} from '../../../redux/auth/auth-action';
import {
  Image,
  Input,
  InputGroup,
  Button,
  InputRightElement,
  FormErrorMessage,
  FormControl,
  Checkbox,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import OTPInput from 'otp-input-react';
import OtherAuth from '../../auth/OtherAuth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import { getMetaByPageName } from '../../../redux/cms/cms-actions';
import SEO from '../../../cms/SEO';

const Login = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  let userData = useSelector(state => state.AuthDetails.user);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [MFA, setMFA] = useState(0);
  const handleClick = () => setShow(!show);
  const [otp, setOTP] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [mfaPrompt, setMfaPrompt] = useState(false);
  const [qrImage, setQrImage] = useState('');

  //===========SEO============
  useEffect(() => {
    //   const getSEO = async () => {
    //     let data = await dispatch(getMetaByPageName({ pageName: "Login" }));
    //     if (data.code === 200) {
    //       setMetaData(data.data);
    //     }
    //   };
    //   getSEO();

    setMFA(0);
  }, []);
  //===========SEO============

  const initialValues = {
    email: '',
    password: '',
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, 'Email must be at least 5 characters')
      .max(50, 'Email must be at most 100 characters')
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid'
      ),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
  });

  const handleOnSubmit = async values => {
    let payload = {
      email: values.email.toLowerCase(),
      password: values.password,
      isVerified: isVerified,
    };
    try {
      let data = await dispatch(loginwithPassword(payload));

      if (data && data.code == 200) {
        data.data.userAuth.mfaSecret != '' && data.data.userAuth.mfaPrompt
          ? setMFA(1)
          : data.data.userAuth.isMfaActive && data.data.userAuth.mfaSecret != ''
          ? setMFA(4)
          : !data.data.userAuth.isMfaActive &&
            data.data.userAuth.mfaSecret != ''
          ? navigate('/')
          : data.data.userAuth.mfaPrompt
          ? setMFA(1)
          : navigate('/');
        Swal.fire({
          title: 'Success',
          text: `Welcome back ${data.data.userAuth.firstName}`,
          icon: 'success',
          timer: '2500',
        });
        formik.handleReset();
        // navigate('/');
      } else {
        if (data.message) {
          Swal.fire({
            title: 'Error',
            text: data.message,
            icon: 'error',
          });
        }
      }
      setOTP();
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong!',
        icon: 'error',
      });
    }
  };

  const handleCreateMFA = async values => {
    try {
      let payload = {
        email: userData.email,
      };
      const data = await dispatch(createMFA(payload));
      setQrImage(data.data.qr);
      setMFA(2);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMFA_Update = async values => {
    try {
      let payload = {
        mfaPrompt: !mfaPrompt,
      };
      mfaPrompt && (await dispatch(SaveBasicProfileDetails(payload)));
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerify = async values => {
    try {
      let payload = {
        email: userData.email,
        token: otp,
        login: true,
      };
      const data = await dispatch(verifyMFA(payload));

      if (data.status) {
        setVerified(data.status);
        navigate('/ ');
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Invalid OTP',
          icon: 'error',
        });
      }
      setOTP();
    } catch (error) {
      console.log(error);
    }
  };

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <>
      <SEO metaData={metaData} />

      <section className="container mx-auto lg:h-screen md:h-screen lg:px-12 md:px-12 px-5">
        <div className="lg:flex md:flex items-center h-full ">
          <div
            id="4805490279"
            className="dynamicStyle2 lg:w-1/2 md:w-1/2 lg:block md:block hidden"
          >
            <Image
              maxH="600"
              src={data ? getImage(data['4805490279']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="loginImage"
              className="m-auto"
            />
          </div>

          {MFA == 0 ? (
            <div className="smcust-shadow lg:w-1/2 md:w-1/2 lg:py-4 md:py-4 py-8 lg:px-12 md:px-6 px-5 ">
              <div className="">
                <h1
                  fontSize="4xl"
                  id="4130656747"
                  className="dynamicStyle text-center lg:text-4xl md:text-2xl text-2xl font-semibold"
                >
                  {data ? data['4130656747'] : 'LOGIN'}
                </h1>

                <p className=" lg:text-base md:text-base text-sm text-center lg:mt-4 md:mt-4 mt-2">
                  <span
                    id="1084915749"
                    dangerouslySetInnerHTML={{
                      __html: data ? data['1084915749'] : 'no-data',
                    }}
                    className="dynamicStyle text333 opacity-70"
                  ></span>{' '}
                  <span className=" underline cursor-pointer font-semibold">
                    Term of use
                  </span>{' '}
                  and{' '}
                  <span className=" cursor-pointer underline font-semibold">
                    Privacy policy
                  </span>{' '}
                </p>

                <form onSubmit={formik.handleSubmit}>
                  <div className="lg:mt-16 md:mt-8 mt-8">
                    <div className="lg:mb-6 md:mb-4 mb-4">
                      <h3
                        id="7829710743"
                        className="dynamicStyle lg:text-xl md:text-base text-base text333 font-semibold"
                      >
                        {data ? data['7829710743'] : 'Email Address'}
                      </h3>
                      <FormControl
                        isInvalid={
                          !!formik.errors.email && formik.touched.email
                        }
                      >
                        <Input
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={handleTrim}
                          className="mt-2 lg:text-lg md:text-base text-sm"
                          placeholder="Enter your email address"
                        />
                        {formik.touched.email && formik.errors.email && (
                          <FormErrorMessage>
                            {formik.errors.email}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </div>

                    <div>
                      <h3
                        id="9378435345"
                        fontSize="xl"
                        className="dynamicStyle lg:text-xl md:text-base text-base text333 font-semibold"
                      >
                        {data ? data['9378435345'] : 'Password'}
                      </h3>
                      <FormControl
                        isInvalid={
                          !!formik.errors.password && formik.touched.password
                        }
                      >
                        <InputGroup>
                          <Input
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            pr="4.5rem"
                            type={show ? 'text' : 'password'}
                            className="mt-2 lg:text-lg md:text-base text-sm"
                            placeholder="Enter your password"
                          />
                          <InputRightElement width="4.5rem" paddingTop="5">
                            <Button
                              h="1.75rem"
                              size="sm"
                              onClick={() => {
                                handleClick();
                              }}
                            >
                              {' '}
                              {show ? 'Hide' : 'Show'}
                            </Button>
                          </InputRightElement>{' '}
                        </InputGroup>
                        {formik.touched.password && formik.errors.password && (
                          <FormErrorMessage>
                            {formik.errors.password}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </div>
                    <div className="pt-4">
                      <span className="fsize15 custcolor underline cursor-pointer">
                        <Link to="/forgot password"> Forgot Password?</Link>
                      </span>
                    </div>

                    <Button
                      colorScheme="primary"
                      className="dynamicStyle w-full mt-8 sm-fsize16px"
                      id="6896927244"
                      size="lg"
                      type="submit"
                    >
                      {/* onClick={handleLoginSubmit} */}{' '}
                      {data ? data['6896927244'] : 'Login'}
                    </Button>
                  </div>
                </form>

                <p className=" lg:text-base md:text-base text-sm text-center mt-4">
                  <span id="5028680478" className="dynamicStyle">
                    {' '}
                    {data ? data['5028680478'] : 'Create a New Account?'}
                  </span>
                  <span className=" underline cursor-pointer font-semibold pl-2">
                    <Link to="/register">Register</Link>
                  </span>{' '}
                </p>

                {/* <Box className="sm-px-30px" position="relative" padding="10">
                    <Divider />
                    <AbsoluteCenter bg="white" px="4">
                      <p id="8012950153" className="dynamicStyle">
                        {' '}
                        {data ? data['8012950153'] : 'or'}
                      </p>
                    </AbsoluteCenter>
                  </Box> */}

                <OtherAuth />
              </div>
            </div>
          ) : MFA == 1 ? (
            <div className="flex gap-2">
              <Button
                colorScheme="primary"
                onClick={() => {
                  handleCreateMFA();
                }}
              >
                Setup MFA
              </Button>
              <Button
                onClick={() => {
                  handleMFA_Update();
                }}
              >
                Skip
              </Button>
              <div className="flex gap-2 items-center">
                {' '}
                <Checkbox onChange={e => setMfaPrompt(e.target.checked)} />
                <p>
                  Don't show this setup again.
                  {/* You can setup MFA from your
                  profile settings. */}
                </p>
              </div>
            </div>
          ) : MFA == 2 ? (
            <div>
              <Image
                maxH="100"
                src={qrImage}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="loginImage"
                className="m-auto"
              />
              <OTPInput
                name="otp"
                value={otp}
                onChange={e => {
                  setOTP(e);
                }}
                OTPLength={6}
                otpType="number"
                className="numb-otp"
                inputclassName="themefamily"
              />
              <div className="flex gap-2">
                <Button
                  colorScheme="primary"
                  onClick={() => otp.length == 6 && handleVerify()}
                >
                  Verify
                </Button>
                <Button
                  onClick={() => {
                    setMFA(1);
                  }}
                >
                  Skip
                </Button>
              </div>
            </div>
          ) : MFA == 4 ? (
            <div>
              <OTPInput
                name="otp"
                value={otp}
                onChange={e => {
                  setOTP(e);
                }}
                OTPLength={6}
                otpType="number"
                className="numb-otp"
                inputclassName="themefamily"
              />

              <button onClick={() => otp.length == 6 && handleVerify()}>
                Verify
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </section>
    </>
  );
};

export default Login;
