import {
  Button,
  Input,
  Select,
  Textarea,
  RadioGroup,
  HStack,
  Radio,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import axios from 'axios';
import { getFormSchema } from '../../../redux/forms/forms-action';
import { useDispatch } from 'react-redux';
const ContactForm2 = ({ pageName }) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  let getallField = useSelector(state => state.Forms.Forms);
  let getSchemaForm = useSelector(state => state.Forms);
  console.log(getallField, 'filed');

  useEffect(() => {
    async function mount() {
      let payload = {
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        formId: getallField[0]._id,
      };
      await dispatch(getFormSchema(payload));
    }
    mount();
  }, [dispatch]);
  //  getallField[0].field.value,
  const handleSubmit = async () => {
    let payload = {};
    let output = await axios.post(
      `${process.env.REACT_APP_BASE_URL}forms/create/${getSchemaForm.FormSchema[0].schemaName}`,
      getallField[0]._id,

      {
        headers: {
          'community-name': process.env.VUE_APP_COMMUNITY_NAME,
          // authorization: `Bearer ${localStorage.getItem('cmsToken')}`,
        },
      }
    );
  };

  return (
    <section className="bg-f4f5f6  lg:py-16 md:py-10 py-8  ">
      <div className="container mx-auto lg:px-12 md:px-8 px-5">
        <div className="text-center">
          <p
            id="3532025242"
            className="dynamicStyle fsize18 sm-fsize16 text-primary"
          >
            {data ? data['3532025242'] : 'Enquire'}
          </p>
          <p
            id="1801706606"
            className="dynamicStyle fsize34 md:fsize26 sm-fsize22 font-medium lg:pb-16 md:pb-10 pb-6 clr-191E22"
          >
            {data ? data['1801706606'] : 'Enquire now and get best solution'}
          </p>
        </div>
        <div>
          <div className="lg:w-3/5 m-auto lg:grid md:grid grid-cols-2 gap-4 ">
            {getallField && getallField[0].field.length > 0
              ? getallField[0].field.map((item, index) => {
                  return (
                    <div className="lg:pb-0 md:pb-0 pb-3 ">
                      {item.inputType !== 'button' && (
                        <p
                          id="1437369070"
                          className=" lg:pb-2 md:pb-2 pb-1 clr-191E22"
                        >
                          {item.label}
                        </p>
                      )}

                      {item.inputType == 'input' && (
                        <Input
                          className="p-2 inp-bdr w-full bg-white"
                          placeholder={item.placeholder}
                          v-model={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}

                      {item.inputType == 'multiline' && (
                        <Textarea
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          v-model={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}

                      {item.inputType == 'phone' && (
                        <Input
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          v-model={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}

                      {item.inputType == 'email' && (
                        <Input
                          type="email"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          v-model={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}

                      {item.inputType == 'url' && (
                        <Input
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          v-model={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}
                      {item.inputType === 'singleselect' && (
                        <Select
                          placeholder={item.placeholder}
                          v-model={item.value}
                          className="fsize14"
                        >
                          {item.enum.map(i => {
                            return <option value={i}>{i}</option>;
                          })}
                        </Select>
                      )}

                      {item.inputType === 'radiobutton' && (
                        <RadioGroup defaultValue="Itachi">
                          <HStack spacing="24px">
                            {item.enum.map(e => {
                              return <Radio value={e}>{e}</Radio>;
                            })}
                          </HStack>
                        </RadioGroup>
                      )}

                      {item.inputType === '"imagefile"' && (
                        <Input
                          type="file"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          v-model={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}

                      {/* {item.inputType == '"button"' && (
                      <Input
                        type="file"
                        className="p-2 inp-bdr w-full"
                        placeholder={item.placeholder}
                        v-model={item.value}
                        maxlength={item.maxLength}
                        minlength={item.minLength}
                      />
                    )} */}
                    </div>
                  );
                })
              : ''}
          </div>

          {getallField && getallField[0].field.length > 0
            ? getallField[0].field.map(item => {
                return (
                  <div className=" text-center">
                    {item.inputType == 'button' && (
                      <Button
                        onClick={() => handleSubmit()}
                        colorScheme="primary"
                        className=" font-semibold bg-da8f2c py-2 px-4 mt-6"
                      >
                        <span> {item.label}</span>
                      </Button>
                    )}
                  </div>
                );
              })
            : 'no-data'}
        </div>
      </div>
    </section>
  );
};

export default ContactForm2;
