import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux/es/exports';
import { Image, Card } from '@chakra-ui/react';

const FeatureCard4 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="relative falgship-h">
      <div
        id="9231512252"
        className="dynamicStyle2 w-full falgship-h cus-featuredynamic"
      >
        <Image
          src={data ? getImage(data['9231512252']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          className="w-full falgship-h"
          alt="falgship"
        />
      </div>
      <div className="absolute top-0 left-0 w-full">
        <div className="lg:px-12 md:px-10 lg:py-12 md:py-12 container mx-auto py-8 px-4">
          <h2
            id="8019995675"
            className="dynamicStyle fsize30 sm-fsize20 font-semibold text-white text-center cust-textColor mb-2"
          >
            {data ? data['8019995675'] : 'Flagship Programs'}
          </h2>
          <p
            id="3779168579"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['3779168579']
                : 'This strategy aims at SBI Foundation having a focused implementation model and objectives against set targets and goals.',
            }}
            className="dynamicStyle sm-fsize14 lg:w-7/12 mx-auto text-white text-center mb-2 textColor-light"
          ></p>

          <Swiper
            spaceBetween={30}
            observer={true}
            observeParents={true}
            className="mySwiper mt-5 lg:mt-14 md:mt-14 mt-6"
            breakpoints={{
              1536: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 2,
                spaceBetween: 12,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 12,
              },
              425: {
                slidesPerView: 2,
                spaceBetween: 12,
              },
              325: {
                slidesPerView: 2,
                spaceBetween: 12,
              },
            }}
          >
            {/* <div class="swiper-button image-swiper-button-prev bg-ffffffa8 rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div class="swiper-button image-swiper-button-next bg-ffffffa8 rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div> */}

            <SwiperSlide>
              <Card
                id="1562680752"
                className="lg:px-8 lg:py-10 px-4 py-4 cust-bgColor rounded-lg flex justify-center items-center dynamicStyle2 w-full"
              >
                <Image
                  src={data ? getImage(data['1562680752']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="w-70per lg:h-40 md:h-40 h-32 object-contain"
                  alt="falgship"
                />
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card
                id="5334818910"
                className="lg:px-8 w-full lg:py-10 px-4 py-4 cust-bgColor rounded-lg flex justify-center items-center dynamicStyle2"
              >
                <Image
                  src={data ? getImage(data['5334818910']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="w-70per lg:h-40 md:h-40 h-32 object-contain"
                  alt="falgship"
                />
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card
                id="5010416854"
                className="lg:px-8 w-full lg:py-10 px-4 py-4 cust-bgColor rounded-lg flex justify-center items-center dynamicStyle2"
              >
                <Image
                  src={data ? getImage(data['5010416854']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="w-70per lg:h-40 md:h-40 h-32 object-contain"
                  alt="falgship"
                />
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card
                id="6027974854"
                className="lg:px-8 w-full lg:py-10 px-4 py-4 cust-bgColor rounded-lg flex justify-center items-center dynamicStyle2"
              >
                <Image
                  src={data ? getImage(data['6027974854']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="w-70per lg:h-40 md:h-40 h-32 object-contain"
                  alt="falgship"
                />
              </Card>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard4;
