import { Button, Image } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';

const Team5 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section className="relative chairman-h">
      <div id="8329931294" className="dynamicStyle2 w-full">
        <Image
          src={data ? getImage(data['8329931294']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          className="w-full chairman-h lg:block hidden"
          alt="chairman-h"
        />
      </div>
      <div className=" lg:absolute top-0 left-0 w-full">
        <div className="lg:flex items-center container mx-auto lg:px-12 lg:py-28 px-4 py-12">
          <div
            className="lg:w-7/12 lg:pr-12"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <h2
              id="3476048990"
              className="dynamicStyle cust-textColor fsize30 sm-fsize20 font-semibold text-primary"
            >
              {data ? data['3476048990'] : 'Lorem Ipsum'}
            </h2>
            <p
              id="6548547145"
              className="dynamicStyle cust-textColor fsize22 sm-fsize14 col-9e mt-1 lg:mt-2"
            >
              {data ? data['6548547145'] : 'Lorem Ipsum'}
            </p>
            <p
              id="8393953154"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['8393953154']
                  : 'Authorized Apple Distributor',
              }}
              className="dynamicStyle textColor-lightv,8 fsize18 sm-fsize14 col-79 mt-2 lg:mt-3"
            >
              {/* We, at the State Bank of India are representative of the trust and
              confidence placed by the public in us. Any activity undertaken at
              the SBI is done with a sense of responsibility, keeping the
              interest of the public at heart. Our long-held traditional values
              of providing services beyond banking have made a way for the SBI
              foundation. The SBI Foundation is founded to create a deep social
              impact and we look for opportunities to maximise the positive
              impact on the world around us in various areas like healthcare,
              education and environment etc. Our efforts are aimed at creating
              equal opportunities for all sections of the society and act as a
              catalyst for those who share our vision and seek active
              collaboration with them. */}
            </p>

            <Button
              variant="outline"
              colorScheme="primary"
              className="dynamicStyle text-primary sm-fsize14 mt-6"
              size="lg"
              id="2124959190"
            >
              <span
              className="flex items-center"
              onClick={() => handleClick('2124959190')}
            >
              {data ? data['2124959190'] : 'Lorem Ipsum'}
              <FeatherIcon
                className="text-primary ml-3"
                size={18}
                icon="arrow-right"
              />
              </span>
            </Button>
          </div>
          <div className="lg:w-5/12 lg:mt-0 mt-16">
            <div className="cards-about relative rounded-2xl bg-primary cust-bgColor">
              <div id="7982718256" className="dynamicStyle2 w-full">
                <Image
                  src={data ? getImage(data['7982718256']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="cards2-img absolute2-cards absolute z-10 rounded-2xl"
                  alt="cards"
                />
              </div>
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/SBI-foundation/SBI-foundation/home/Frame%20125.png"
                className="w-24 h-20 absolute bottom-0 left-0"
                alt="side"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team5;
