import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Image } from '@chakra-ui/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useSelector } from 'react-redux/es/exports';

const GallerySection4 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="container mx-auto lg:pt-8 md:pt-4 pt-2 lg:pb-16 md:pb-10 pb-6 lg:px-12 md:px-8 px-5">
      <div className="lg:flex justify-between items-center  container mx-auto">
        <div className="">
          <p id="7748566881" className="dynamicStyle text-primary cust-textColor">
            {data ? data['7748566881'] : 'Gallery'}
          </p>
          <p
            id="3172720406"
            className="dynamicStyle cust-textColor fsize34 md:fsize26 font-medium sm-fsize22 clr-191E22"
          >
            {data ? data['3172720406'] : 'Our Latest Fabric Print Designs'}
          </p>
        </div>
        <div id="9579943303" className="dynamicStyle lg:mt-0 mt-2">
          <p className="text-primary cust-textColor font-semibold sm-fsize14 cursor-pointer">
            {data ? data['9579943303'] : 'View All'}
          </p>
        </div>
      </div>

      <div className="lg:pt-8 md:pt-8 pt-8 ">
        <Swiper
          spaceBetween={30}
          navigation={true}
          modules={[Navigation]}
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
          }}
        >
          <SwiperSlide>
            <div id="7251936812" className="dynamicStyle2 sm-pb-4 w-full">
              <Image
                className="gal-img"
                src={data ? getImage(data['7251936812']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div id="9851212394" className="dynamicStyle2 sm-pb-4 w-full">
              <Image
                className="gal-img"
                src={data ? getImage(data['9851212394']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div id="0783496851" className="dynamicStyle2 sm-pb-4 w-full">
              <Image
                className="gal-img"
                src={data ? getImage(data['0783496851']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div id="2838056501" className="dynamicStyle2 sm-pb-4 w-full">
              <Image
                className="gal-img"
                src={data ? getImage(data['2838056501']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default GallerySection4;
