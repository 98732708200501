import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';

const HeroSection1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    Aos.init();
  }, []);

  const navigate = useNavigate();
  return (
    <section
      className={colorMode === 'light' ? 'bg-secondary' : 'cust-bgColor'}
    >
      <div className="lg:flex md:flex sm-reverse items-center">
        <div
          className="lg:w-1/2 md:w-1/2 w-full lg:pl-20 md:pl-10 pl-6 lg:pr-0 md:pr-0 pr-6 lg:py-0 md:py-0 py-10"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="flex items-center gap-4">
            <p
              className="lg:text-base md:text-sm text-sm text-white dynamicStyle"
              id="4532162874"
            >
              {data ? data['4532162874'] : 'EDUCATE'}
            </p>
            <span className="block h-2 w-2 bg-primary rounded-full"> </span>
            <p
              className="lg:text-base md:text-sm text-sm text-white dynamicStyle"
              id="2494898441"
            >
              {data ? data['2494898441'] : 'CONNECT'}
            </p>
            <span className="block h-2 w-2 bg-primary rounded-full"> </span>
            <p
              className="lg:text-base md:text-sm text-sm text-white dynamicStyle"
              id="8347387826"
            >
              {data ? data['8347387826'] : 'MOTIVATE'}
            </p>
          </div>

          <div className="mt-2">
            <h2
              class="lg:text-6xl text-end md:text-3xl text-xl text-white font-semibold dynamicStyle"
              id="2067155663"
            >
              {data ? data['2067155663'] : 'Lorem Ipsum'}
            </h2>
          </div>

          <div className="text-end lg:pl-20 lg:mt-8 md:mt-4 md:pl-0">
            <p
              className="lg:text-base md:text-base text-sm text-white opacity-70 dynamicStyle"
              id="8645751504"
              dangerouslySetInnerHTML={{
                __html: data ? data['8645751504'] : 'no-data',
              }}
            ></p>
            <div
              // onClick={() => navigate('/coach')}
              className="lg:pr-8 md:pr-8 relative md:pl-0 lg:mt-6 md:mt-6 mt-6"
            >
              {/* <input
                className="inputsearch text-white"
                placeholder="Search for trainers....."
              />
              <div className="absolute lg:right-12 md:right-12 right-4 top11">
                <FeatherIcon
                  icon="search"
                  size={20}
                  className="text-white ml-2"
                />
              </div> */}

              <Button
                // onClick={() => navigate('/coach')}
                className="trans-btn  rounded-full  text-center dynamicStyle"
                id="4443636199"
                py="6"
              >
                <span onClick={() => handleClick('4443636199')}>
                  {data ? data['4443636199'] : 'FIND TRAINER'}
                </span>
              </Button>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full lg:flex  md:flex justify-end">
          <div id="8392435683" className="dynamicStyle2 w-full">
            {' '}
            <Image
              src={data ? getImage(data['8392435683']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              className="w-full minh500px object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection1;
