import React from 'react';
import { ArrowRight } from 'react-feather';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const GridCard2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="container mx-auto lg:py-16 md:py-8 py-4 lg:px-12 md:px-8 px-5">
      <p id="5539013178" className="dynamicStyle font-semibold text-primary">
        {data ? data['5539013178'] : 'Lorem Ipsum'}
      </p>
      <p
        id="5941461009"
        className="dynamicStyle fsize34 md:fsize26 sm-fsize22 font-medium lg:mb-8 md:mb-8 mb-4 clr-191E22"
      >
        {data ? data['5941461009'] : 'Lorem Ipsum generated'}
      </p>
      <div className="w-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-12 md:gap-4 gap-4">
        <div className="">
          <Image
            className="h-380 lg:mb-4 md:mb-4 mb-2"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt=""
          />
          <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317">
            190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
            Fabric)
          </p>
          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317">
            Multicolor • Cotton • Made in India
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer">
            <p className="text-primary sm-fsize14">Enquire</p>
            <ArrowRight className="text-primary ml-2 sm-arrow" />
          </div>
        </div>
        <div className="">
          <Image
            className="h-380 lg:mb-4 md:mb-4 mb-2"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt=""
          />
          <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317">
            190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
            Fabric)
          </p>
          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317">
            Multicolor • Cotton • Made in India
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer">
            <p className="text-primary  sm-fsize14">Enquire</p>
            <ArrowRight className="text-primary ml-2 sm-arrow" />
          </div>
        </div>
        <div className="">
          <Image
            className="h-380 lg:mb-4 md:mb-4 mb-2"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt=""
          />
          <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317">
            190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
            Fabric)
          </p>
          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317">
            Multicolor • Cotton • Made in India
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer">
            <p className="text-primary sm-fsize14">Enquire</p>
            <ArrowRight className="text-primary ml-2 sm-arrow" />
          </div>
        </div>
        <div className="">
          <Image
            className="h-380 lg:mb-4 md:mb-4 mb-2"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt=""
          />
          <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317">
            190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
            Fabric)
          </p>
          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317">
            Multicolor • Cotton • Made in India
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer">
            <p className="text-primary sm-fsize14">Enquire</p>
            <ArrowRight className="text-primary ml-2 sm-arrow" />
          </div>
        </div>
        <div className="">
          <Image
            className="h-380 lg:mb-4 md:mb-4 mb-2"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt=""
          />
          <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317">
            190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
            Fabric)
          </p>
          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317">
            Multicolor • Cotton • Made in India
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer">
            <p className="text-primary sm-fsize14">Enquire</p>
            <ArrowRight className="text-primary ml-2 sm-arrow" />
          </div>
        </div>
        <div className="">
          <Image
            className="h-380 lg:mb-4 md:mb-4 mb-2"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt=""
          />
          <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317">
            190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
            Fabric)
          </p>
          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317">
            Multicolor • Cotton • Made in India
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer">
            <p className="text-primary sm-fsize14">Enquire</p>
            <ArrowRight className="text-primary ml-2 sm-arrow" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GridCard2;
