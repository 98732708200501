import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import EditProfile from './components/EditProfile';
import Appointment from './components/Appointment';
import FindCoach from './components/FindCoach';
import ManageAccount from './components/ManageAccount';
import { Navigate } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../redux/auth/auth-action';
import HelpSupport from './components/HelpSupport';
import UserProfilePage from './components/UserProfilePage';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const Profile = () => {
  const dispatch = useDispatch();
  let userData = useSelector(state => state.AuthDetails.user);
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const btnRef = React.useRef();

  const menuItems = [
    {
      id: 1,
      name: 'Profile',
      icon: 'user',
    },
    // {
    //   id: 2,
    //   name: 'My Appointment',
    //   icon: 'calendar',
    // },
    // {
    //   id: 3,
    //   name: 'Find Coach',
    //   icon: 'search',
    // },
    // {
    //   id: 4,
    //   name: 'My Bookmarks',
    //   icon: 'bookmark',
    // },
    {
      id: 5,
      name: 'Manage Accounts',
      icon: 'settings',
    },
    {
      id: 6,
      name: 'Help & Support',
      icon: 'help-circle',
    },
  ];

  const [selectedItem, setSelectedItem] = useState(menuItems[0].name);

  const handleClick = item => {
    setSelectedItem(item);
  };

  const handleLogout = async () => {
    if (userData && userData.email) {
      let payload = {
        email: userData.email,
      };
      try {
        const data = await dispatch(logout(payload));
        if (data && data.code == 200) {
          await Swal.fire({
            title: 'Success',
            text: `Logged out successfully`,
            icon: 'success',
            timer: '2500',
          });
          Navigate('/');
        } else {
          if (data.message) {
            Swal.fire({
              title: 'Error',
              text: data.message,
              icon: 'error',
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <section className="container mx-auto lg:px-12 md:px-4 lg:py-6  md:py-6 py-6 px-5">
      <div className="lg:hidden zindex_menu md:hidden block text-right mb-2">
        <Menu>
          <MenuButton
            px={0}
            py={2}
            transition="all 0.2s"
            borderRadius="md"
            borderWidth="0px"
          >
            Profile <ChevronDownIcon />
          </MenuButton>
          <MenuList>
            <MenuItem>
              <div className="lg:flex md:flex justify-between  w-full">
                <div className=" w-full ">
                  <div className=" ">
                    <ul className="py-0">
                      {menuItems.map((item, index) => (
                        <li
                          key={index}
                          className={`py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 ${
                            selectedItem === item.name
                              ? 'selected text-primary font-semibold'
                              : ''
                          }`}
                          onClick={() => handleClick(item.name)}
                        >
                          <FeatherIcon icon={item.icon} className="w-4 h-4" />
                          {item.name}
                        </li>
                      ))}
                      <li
                        onClick={handleLogout}
                        className="py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 "
                      >
                        <FeatherIcon icon="log-out" className="w-4 h-4" />
                        Logout
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </MenuItem>
          </MenuList>
        </Menu>
        <Drawer
          isOpen={isOpenDrawer}
          placement="right"
          onClose={onCloseDrawer}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            {/* <DrawerHeader>Options</DrawerHeader> */}
            <DrawerBody className="sm-drawer-padding">
              <div className="lg:flex md:flex justify-between  w-full">
                <div className=" w-full ">
                  <div className=" ">
                    <ul className="py-6">
                      {menuItems.map((item, index) => (
                        <li
                          key={index}
                          className={`py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 ${
                            selectedItem === item.name
                              ? 'selected text-primary font-semibold'
                              : ''
                          }`}
                          onClick={() => handleClick(item.name)}
                        >
                          <FeatherIcon icon={item.icon} className="w-4 h-4" />
                          {item.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </div>
      <div className="lg:flex md:flex justify-between  w-full">
        <div className="lg:w-1/5 mob-hidden md:w-2/6 w-full lg:pr-4 md:pr-4 lg:mb-0 md:mb-0 mb-4">
          <div className="border rounded-3px">
            <ul className="py-2">
              {menuItems.map((item, index) => (
                <li
                  key={index}
                  className={`py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 ${
                    selectedItem === item.name ? 'selected text-primary cust-textColor' : ''
                  }`}
                  onClick={() => handleClick(item.name)}
                >
                  <FeatherIcon icon={item.icon} className="w-4 h-4" />
                  {item.name}
                </li>
              ))}
              <li
                onClick={handleLogout}
                className="py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 "
              >
                <FeatherIcon icon="log-out" className="w-4 h-4" />
                Logout
              </li>
            </ul>
          </div>
        </div>
        <div className="lg:w-4/5 md:w-4/6 w-full">
          {selectedItem === 'Profile' && <UserProfilePage />}
          {selectedItem === 'Find Coach' && <FindCoach />}
          {selectedItem === 'My Appointment' && <Appointment />}
          {selectedItem === 'Manage Accounts' && <ManageAccount />}
          {selectedItem === 'Help & Support' && <HelpSupport />}
        </div>
      </div>
    </section>
  );
};

export default Profile;
