import React from 'react';
import { Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const HeroSection5 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <section>
      <div className={colorMode === 'light' ? 'bg-secondary' : 'cust-bgColor'}>
        <div className="lg:flex md:flex sm-reverse lg:px-0 md:px-4 px-5 items-center lg:py-0 md:py-10 py-6">
          <div className="lg:w-3/5 md:w-3/5 lg:px-20 md:px-12 lg:pt-0 md:pt-0 pt-4">
            <p
              id="6397432808"
              className="dynamicStyle  text-white fsize12 pb-2"
            >
              {data ? data['6397432808'] : 'Dec 8,2023 .KC Chua'}
            </p>
            <h2
              id="2507312557"
              className="dynamicStyle lg:text-3xl md:text-2xl uppercase text-end  text-white text-xl font-semibold"
            >
              {data
                ? data['2507312557']
                : 'TRANSFORM YOUR BODY WITH STRENGTH AND CONDITIONING'}
            </h2>
            <p
              id="1380071393"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['1380071393']
                  : 'No we are not another workout site! We are a Fitness and HealthPlatform. Our mission is to Educate, Connect and Motivate you into living a fitter and healthier life.',
              }}
              className="dynamicStyle text-white lg:text-base md:text-base text-sm  opacity-70 lg:mt-4 md:mt-4 mt-2"
            ></p>
          </div>

          <div
            id="4094955361"
            className="dynamicStyle2 lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full"
          >
            <Image
              src={data ? getImage(data['4094955361']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              className=" object-cover w-full sm-h250px minh450px"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection5;
