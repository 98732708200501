import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { Button, Input, Image } from '@chakra-ui/react';
import { Heart, User } from 'react-feather';
import { FiChevronsDown } from 'react-icons/fi';

import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '@chakra-ui/icons';

export default function Header3() {
  let brandProfile = useSelector(state => state.brandDetails.brandProfile);

  //primarylogo
  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }
  return (
    <section>
      <div className="bg-0b0b0b lg:py-6 md:py-6 py-4 lg:px-12 md:px-12 px-5">
        <div className="bg-0b0b0b flex lg:gap-8 gap-4 justify-between">
          <a href="/">
            <Image
              src={getPrimaryImage(brandProfile)}
              alt="logo"
              className="lg:w-24 md:w-24 w-16 h-12 border "
            />
          </a>
          <div className="w-1/2 gap-12 items-center flex-start lg:pl-10 flex">
            <input
              placeholder="Search anything "
              className="w-full px-4 py-3 bg-343434 text-white rounded-full"
            />
          </div>

          <div className="flex lg:gap-6 gap-2 items-center">
            <div className="lg:flex md:flex gap-8 hidden">
              <Menu>
                <MenuButton>
                  <div className="flex items-center text-white">
                    Category
                    <FiChevronsDown />
                  </div>
                </MenuButton>

                <MenuList>
                  <MenuItem>Download</MenuItem>

                  <MenuItem>Create a Copy</MenuItem>

                  <MenuItem>Mark as Draft</MenuItem>

                  <MenuItem>Delete</MenuItem>

                  <MenuItem>Attend a Workshop</MenuItem>
                </MenuList>
              </Menu>
              <p className="text-white">Course</p>
            </div>
            <Heart className="text-white" />
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="header-3-profile-icon"
              >
                <User className="text-white" />
              </MenuButton>
              <MenuList>
                <MenuItem>Download</MenuItem>
                <MenuItem>Create a Copy</MenuItem>
                <MenuItem>Mark as Draft</MenuItem>
                <MenuItem>Delete</MenuItem>
                <MenuItem>Attend a Workshop</MenuItem>
              </MenuList>
            </Menu>

            {/* <div className="lg:hidden md:hidden block">
              <Menu className="">
                <MenuButton as={Button} rightIcon={<FaBars />}></MenuButton>
                <MenuList>
                  <MenuItem>
                    <a href="/">
                      <p className=" font-semibold">About Us</p>
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a href="/">
                      <p className=" font-semibold">Service</p>
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a href="/">
                      <p className=" font-semibold">Testimonial</p>
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a href="/">
                      <p className=" font-semibold">Contact us</p>
                    </a>
                  </MenuItem>
                </MenuList>
              </Menu>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
