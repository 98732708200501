import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image, useColorMode } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const HeroSection8 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className={colorMode === 'light' ? 'bg-primary' : 'cust-bgColor'}>
      <div className=" container mx-auto lg:px-0 md:px-0 px-6 lg:py-0 md:py-0 py-4">
        <div
          className=" w-full lg:py-10 md:py-10 py-4 flex flex-col justify-center items-center"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <h1
            id="7837996203"
            className="dynamicStyle hero-header text-center lg:mb-4 md:mb-4 mb-2 clr-fff cust-textColor"
          >
            {data ? data['7837996203'] : 'Lorem Ipsum'}
          </h1>
          <p
            id="8008669113"
            dangerouslySetInnerHTML={{
              __html: data ? data['8008669113'] : 'Lorem ipsum generated',
            }}
            className="fsize20 sm-fsize16 clr-fff dynamicStyle textColor-light"
          ></p>
          <div
            id="5386789530"
            className="dynamicStyle2 lg:w-4/5 md:w-4/5 w-full py-6"
          >
            <Image
              src={data ? getImage(data['5386789530']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="hero"
              className="w-full h-300px sm-h250px object-contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection8;
