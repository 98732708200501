import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
const ModalStartnew = ({ isOpen, onClose, reset }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <div className="p-3">
            <h4 className="text-center fsize20 font-semibold">
              Start New AI Conversion
            </h4>
            <p className="mt-2 text-gray text-center fsize15">
              All your previous chats will be deleted. Are you sure you want to
              proceed?
            </p>
            <div className="flex gap-2 mt-4">
              <Button
                colorScheme="primary"
                className="w-full"
                px={6}
                onClick={() => reset()}
              >
                Start New
              </Button>
              <Button
                colorScheme="primary"
                variant="outline"
                className="text-primary w-full"
                px={8}
              >
                Cancel
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ModalStartnew;
