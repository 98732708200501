import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import {
  Text,
  Image,
  Input,
  InputGroup,
  Button,
  InputRightElement,
  Divider,
  AbsoluteCenter,
  Box,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux/es/exports';
import { Link, NavLink, useNavigate } from 'react-router-dom';
const LeftImgRightText = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  const navigate = useNavigate();
  return (
    <section>
      <div className="lg:flex md:flex container mx-auto  lg:py-16 md:py-10 py-8 lg:px-12 md:px-6 px-5 relative">
        <div className="lg:w-1/2 md:w-1/2 w-full lg:pr-8 md:pr-8 relative zindex9">
          <div id="3787124660" className="dynamicStyle2 w-full">
            {' '}
            <Image
              src={data ? getImage(data['3787124660']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              className="w-full sm-h250px minh450px object-contain"
            />
          </div>
        </div>
        <div
          className="lg:w-1/2 md:w-1/2 lg:py-12 md:py-0 py-4 zindex9 flex flex-col justify-center"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <h2
            id="8001616167"
            className="dynamicStyle lg:text-4xl md:text-2xl  text-xl  font-bold"
          >
            {data ? data['8001616167'] : 'no-data'}
          </h2>

          <p
            id="3526496342"
            className=" dynamicStyle lg:mt-4 md:mt-4 mt-2 text030303 lg:text-base md:text-base text-sm lg:leading-7 md:leading-7 leading-6"
            dangerouslySetInnerHTML={{
              __html: data ? data['3526496342'] : 'no-data',
            }}
          ></p>

          <div className="zindex9 relative">
            <Button
              onClick={() => navigate('/becameacoach')}
              width="180px"
              height="50px"
              colorScheme="primary"
              className="dynamicStyle lg:mt-8 md:mt-8 mt-6 "
              id="8700048552"
            >
              {data ? data['8700048552'] : 'Find Coach'}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeftImgRightText;
