import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';

const GallerySection3 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-12 py-8 px-5 lg:py-16">
        <p
          id="1934690016"
          className="dynamicStyle text-secondary cust-textColor text-center fsize18 sm-fsize14"
        >
          {data ? data['1934690016'] : 'Our Work'}
        </p>
        <h2
          id="3170971433"
          className="dynamicStyle cust-textColor fsize30 sm-fsize20 font-semibold text-center mt-2 text-primary"
        >
          {data ? data['3170971433'] : 'SBIF Jivanam Initiatives'}
        </h2>

        <div className="lg:mt-10 mt-4 grid lg:grid-cols-6 md:grid-cols-4 grid-cols-1 w-full gap-3">
          <Card
           className={
            colorMode === 'light'
              ? 'w-full py-2 box-shadow-none'
              : 'w-full py-2'
          }
            
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div
              id="3293830277"
              className="dynamicStyle2 cus-hbanner w-full w-full"
            >
              <Image
                src={data ? getImage(data['3293830277']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-11/12 w-full mx-auto h-40"
                alt="board"
              />
            </div>

            <p
              className="text-secondary cust-textColor text-center fsize16 mt-3 dynamicStyle"
              id="9287006308"
            >
              {data ? data['9287006308'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card
            className={
            colorMode === 'light'
              ? 'w-full py-2 box-shadow-none'
              : 'w-full py-2'
          }
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div
              id="1526878701"
              className="dynamicStyle2 cus-hbanner w-full w-full"
            >
              <Image
                src={data ? getImage(data['1526878701']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-11/12 w-full mx-auto h-40"
                alt="board"
              />
            </div>

            <p
              className="text-secondary cust-textColor text-center fsize16 mt-3 dynamicStyle"
              id="8433481519"
            >
              {data ? data['8433481519'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card
            className={
            colorMode === 'light'
              ? 'w-full py-2 box-shadow-none'
              : 'w-full py-2'
          }
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="7728581613" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['7728581613']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-11/12 w-full mx-auto h-40"
                alt="board"
              />
            </div>

            <p
              className="text-secondary cust-textColor text-center fsize16 mt-3 dynamicStyle"
              id="4119449705"
            >
              {data ? data['4119449705'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card
            className={
            colorMode === 'light'
              ? 'w-full py-2 box-shadow-none'
              : 'w-full py-2'
          }
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="9781546922" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['9781546922']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-11/12 w-full mx-auto h-40"
                alt="board"
              />
            </div>

            <p
              className="text-secondary cust-textColor text-center fsize16 mt-3 dynamicStyle"
              id="4526349883"
            >
              {data ? data['4526349883'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card
            className={
            colorMode === 'light'
              ? 'w-full py-2 box-shadow-none'
              : 'w-full py-2'
          }
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="3122062331" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['3122062331']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-11/12 w-full mx-auto h-40"
                alt="board"
              />
            </div>

            <p
              className="text-secondary cust-textColor text-center fsize16 mt-3 dynamicStyle"
              id="5776040472"
            >
              {data ? data['5776040472'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card
            className={
            colorMode === 'light'
              ? 'w-full py-2 box-shadow-none'
              : 'w-full py-2'
          }
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="7506674394" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['7506674394']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-11/12 w-full mx-auto h-40"
                alt="board"
              />
            </div>

            <p
              className="text-secondary cust-textColor text-center fsize16 mt-3 dynamicStyle"
              id="1782050549"
            >
              {data ? data['1782050549'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card
            className={
            colorMode === 'light'
              ? 'w-full py-2 box-shadow-none'
              : 'w-full py-2'
          }
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="0551809988" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['0551809988']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-11/12 w-full mx-auto h-40"
                alt="board"
              />
            </div>

            <p
              className="text-secondary cust-textColor text-center fsize16 mt-3 dynamicStyle"
              id="4131592494"
            >
              {data ? data['4131592494'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card
            className={
            colorMode === 'light'
              ? 'w-full py-2 box-shadow-none'
              : 'w-full py-2'
          }
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="3197037745" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['3197037745']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-11/12 w-full mx-auto h-40"
                alt="board"
              />
            </div>

            <p
              className="text-secondary cust-textColor text-center fsize16 mt-3 dynamicStyle"
              id="6323196941"
            >
              {data ? data['6323196941'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card
            className={
            colorMode === 'light'
              ? 'w-full py-2 box-shadow-none'
              : 'w-full py-2'
          }
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="4633941928" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['4633941928']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-11/12 w-full mx-auto h-40"
                alt="board"
              />
            </div>

            <p
              className="text-secondary cust-textColor text-center fsize16 mt-3 dynamicStyle"
              id="7329730714"
            >
              {data ? data['7329730714'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card
            className={
            colorMode === 'light'
              ? 'w-full py-2 box-shadow-none'
              : 'w-full py-2'
          }
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="9867524179" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['9867524179']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-11/12 w-full mx-auto h-40"
                alt="board"
              />
            </div>

            <p
              className="text-secondary cust-textColor text-center fsize16 mt-3 dynamicStyle"
              id="6350039344"
            >
              {data ? data['6350039344'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card
            className={
            colorMode === 'light'
              ? 'w-full py-2 box-shadow-none'
              : 'w-full py-2'
          }
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="5538414318" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['5538414318']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-11/12 w-full mx-auto h-40"
                alt="board"
              />
            </div>

            <p
              className="text-secondary cust-textColor text-center fsize16 mt-3 dynamicStyle"
              id="9088567065"
            >
              {data ? data['9088567065'] : 'Lorem Ipsum'}
            </p>
          </Card>
          <Card
            className={
            colorMode === 'light'
              ? 'w-full py-2 box-shadow-none'
              : 'w-full py-2'
          }
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div id="4739878590" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['4739878590']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-11/12 w-full mx-auto h-40"
                alt="board"
              />
            </div>

            <p
              className="text-secondary cust-textColor text-center fsize16 mt-3 dynamicStyle"
              id="9114930038"
            >
              {data ? data['9114930038'] : 'Lorem Ipsum'}
            </p>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default GallerySection3;
