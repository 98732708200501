import React, { useEffect } from "react";
import { Image, Card } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import Aos from "aos";
import "aos/dist/aos.css";

const CardText = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  useEffect(() => {
    Aos.init();
  });
  return (
    <section>
      <div className="container mx-auto lg:pb-16 md:pb-16 pb-8 lg:pt-12 md:pt-12 pt-8 lg:px-12 md:px-12 px-5">
        <div className="text-center ">
          <h2
            id="4976480268"
            className="dynamicStyle lg:text-4xl  md:text-2xl text-2xl uppercase lheight text-primary cust-textColor  font-semibold pl-2"
          >
            {data ? data["4976480268"] : "What We Do"}
          </h2>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:mt-12 mt-6 lg:gap-8 md:gap-8 gap-6">
            <Card
              className="bg-white cust-shadow rounded-lg p-5"
              data-aos="zoom-in"
              data-aos-once="true"
              delay={1200}
            >
              <h2
                id="1213524592"
                className="dynamicStyle lg:text-2xl md:text-xl text-lg lheight text-primary cust-textColor  font-semibold pl-2"
              >
                {data ? data["1213524592"] : "Educate"}
              </h2>
              <p
                id="0111982049"
                dangerouslySetInnerHTML={{
                  __html: data ? data["0111982049"] : "no-data",
                }}
                className="dynamicStyle textColor-light fsize16 text-dark sm-fsize14 col-62 lg:leading-7 md:leading-7 leading-6"
              ></p>
            </Card>
            <Card
              className="bg-white cust-shadow rounded-lg p-5"
              data-aos="zoom-in"
              data-aos-once="true"
              delay={1200}
            >
              <h2
                id="2415028268"
                className="dynamicStyle cust-textColor lg:text-2xl md:text-xl text-lg lheight text-primary  font-semibold pl-2"
              >
                {data ? data["2415028268"] : "Educate"}
              </h2>
              <p
                id="6787662183"
                dangerouslySetInnerHTML={{
                  __html: data ? data["6787662183"] : "no-data",
                }}
                className=" dynamicStyle textColor-light fsize16 text-dark sm-fsize14 col-62 lg:leading-7 md:leading-7 leading-6"
              ></p>
            </Card>
            <Card
              className="bg-white cust-shadow rounded-lg p-5"
              data-aos="zoom-in"
              data-aos-once="true"
              delay={1200}
            >
              <h2
                id="9289452004"
                className="dynamicStyle  cust-textColor lg:text-2xl md:text-xl text-lg lheight text-primary  font-semibold pl-2"
              >
                {data ? data["9289452004"] : "Educate"}
              </h2>
              <p
                id="2638899178"
                dangerouslySetInnerHTML={{
                  __html: data ? data["2638899178"] : "no-data",
                }}
                className="dynamicStyle  textColor-light fsize16 text-dark sm-fsize14 col-62 lg:leading-7 md:leading-7 leading-6"
              ></p>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardText;
