import { Button } from '@chakra-ui/react';
import React from 'react';

const ContactUs = () => {
  return (
    <>
      <div className="lg:px-0 md:px-0 px-5 lg:pt-0 md:pt-0 pt-8">
        <div className="flex">
          <h2 className="fsize32 sm-fsize24 font-semibold">Contact</h2>
        </div>
        <div className="contact-area bg-f8fbfd mt-6">
          <h5 className="fsize25 sm-fsize16 mb-3">
            I'm Always Open To Discussing Product
          </h5>
          <h5 className="fsize25 sm-fsize16 font-semibold mb-10">
            design work or partnerships.
          </h5>
          <div className="">
            <div className="lg:mb-10 md:mb-8 mb-6">
              <p className="leading-none fsize16 sm-fsize14">Name *</p>
              <input className="input-box" />
            </div>
            <div className="lg:mb-10 md:mb-8 mb-6">
              <p className="leading-none fsize16 sm-fsize14">Email *</p>
              <input className="input-box" />
            </div>
            <div className="lg:mb-10 md:mb-8 mb-6">
              <p className="leading-none fsize16 sm-fsize14">Message *</p>
              <input type="textarea" className="input-box" />
            </div>
            <Button className="form-btn">Submit</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
