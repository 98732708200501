import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const HeroSection11 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section>
      <div className="relative h-450px sm-h-350px sm-h250px">
        <div id="8936215976" className="dynamicStyle2 w-full">
          <Image
            src={data ? getImage(data['8936215976']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="hero"
            className=" w-full h-450px sm-h-350px sm-h250px object-cover bg-ddd9d8 "
          />
        </div>
        <div className="absolute sm-wfull w-full top-0 left-0 h-450px sm-h-350px sm-h250px lg:px-0 md:px-0 px-4 flex items-center">
          <div
            className=" w-60 md-w-70 sm-wfull mx-auto flex flex-col justify-center items-center"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <p
              id="5873626118"
              className="fsize18 sm-fsize16 letter-spacing4px font-semibold clr-fff mb-2 dynamicStyle text-center cust-textColor"
            >
              {data ? data['5873626118'] : 'About Us'}
            </p>
            <h1
              id="5823783444"
              className="hero-header lg:mb-4 md:mb-4 mb-2 clr-fff text-center dynamicStyle cust-textColor"
            >
              {data ? data['5823783444'] : 'Lorem ipsum'}
            </h1>

            {/* <p className="fsize18 sm-fsize14 clr-fff text-center ">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection11;
