import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

const AboutSection = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section>
      <div className="text-center container mx-auto lg:px-12 md:px-10 px-5 lg:py-12 md:py-10 py-8  mx-auto lg:mb-16 md:mb-8 mb-6 ">
        <h3
          id="9392653432"
          className="dynamicStyle letter-spacing4px fsize18 font-semibold clr-ddd9d8 lg:mb-2"
        >
          {data ? data['9392653432'] : 'Lorem Ipsum'}
        </h3>
        <h3
          id="2809571636"
          className="dynamicStyle fsize36 md:fsize32 sm-fsize20 cust-textColor font-semibold mb-2"
        >
          {data ? data['2809571636'] : 'Lorem Ipsum'}
        </h3>
        <p
          id="8263046497"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['8263046497']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className="dynamicStyle fsize16 sm-fsize14 textColor-light"
        >
          {/* In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to demonstrate the visual form of a document or a
          typeface without relying on meaningful content. */}
        </p>
      </div>
      <div className="container mx-auto lg:mt-4 lg:px-12 md:px-12 px-5">
        <div
          id="6824273241"
          className="dynamicStyle2 w-full h-400px sm-h-350px"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <Image
            src={data ? getImage(data['6824273241']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="section2"
            className="br-28px bg-ddd9d8 w-full h-full object-cover"
          />
        </div>

        <div className="lg:mt-12 md:mt-8 mt-6 flex justify-center">
          <Button
            id="6662290131"
            colorScheme="primary"
            className="rounded-sm  fsize14 smcust-btn dynamicStyle"
          >
            <span onClick={() => handleClick('6662290131')}>
            {data ? data['6662290131'] : 'Enquire Now'}
            </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
