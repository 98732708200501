import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Founders = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  // console.log(data, 'idcheck');
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <div className="bggradient-right30 ">
        <div className="lg:flex md:flex items-center container mx-auto lg:py-24 md:py-24 py-12 lg:px-12 md:px-6 px-5">
          <div className="lg:w-1/2 md:w-1/2 lg:pr-4 md:pr-10">
            <div className="dynamicStyle2 w-full" id="2053287608">
              <Image
                src={data ? getImage(data['2053287608']) : ''}
                className="chua-img tb-h280px object-contain w-full"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="chua"
              />
            </div>
          </div>

          <div
            className="lg:w-1/2 md:w-1/2 lg:pl-10 md:pl-4 lg:mt-0 mt-6"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <h2
              id="8159973227"
              className="dynamicStyle lg:text-4xl md:text-2xl text-xl  font-semibold text-white"
            >
              {data ? data['8159973227'] : 'no-data'}
            </h2>
            <p
              id="0210835708"
              dangerouslySetInnerHTML={{
                __html: data ? data['0210835708'] : 'no-data',
              }}
              className="dynamicStyle fsize18 mt-3 sm-fsize14 text-white lg:mt-3 md:mt-0 font-normal opacity-70 lg:leading-7 md:leading-7 leading-6"
            ></p>
            <p
              id="4556735013"
              dangerouslySetInnerHTML={{
                __html: data ? data['4556735013'] : 'no-data',
              }}
              className="dynamicStyle fsize16 text-white sm-fsize14 opacity-70 lg:leading-7 md:leading-7 leading-6 mt-3"
            ></p>
          </div>
        </div>
      </div>
      <div className="bggradient-left30">
        <div className="lg:flex md:flex w-full sm-reverse items-center container mx-auto lg:py-24 md:py-24 py-12 lg:px-12 md:px-12 px-5">
          <div
            className="lg:w-1/2 md:w-full lg:pr-10 lg:mt-0 mt-6"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <h2
              id="9366882168"
              className="dynamicStyle lg:text-4xl md:text-2xl text-xl  font-semibold text-white"
            >
              {data ? data['9366882168'] : 'no-data'}
            </h2>
            <p
              id="5238377999"
              dangerouslySetInnerHTML={{
                __html: data ? data['5238377999'] : 'no-data',
              }}
              className="dynamicStyle fsize16 text-white sm-fsize14 opacity-70 lg:leading-7 md:leading-7 leading-6 mt-3"
            ></p>
          </div>
          <div className="lg:w-1/2  md:w-full w-full lg:pl-10 md:pl-10">
            <div id="2142586895" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['2142586895']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="chua-img tb-h280px object-contain w-full"
                alt="chua"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founders;
