import { Badge, Card, Image } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { ArrowRight } from 'react-feather';
import { useDispatch } from 'react-redux';
import { getAllBlogs } from '../../../redux/blogs/blogs-actions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

const Blogs = ({ pageName }) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);

  let allBlog = useSelector(state => state.BlogDetails.Blog);
  console.log(allBlog, 'SACHIN');
  useEffect(() => {
    dispatch(getAllBlogs());
  }, []);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="container mx-auto lg:px-12 md:px-12 px-5 lg:py-14 md:py-14 py-8">
      <div className="flex justify-center lg:pb-14 md:pb-14 pb-8">
        <div className="w-70">
          <p
            id="4980097514"
            className="dynamicStyle cust-textColor fsize32 sm-fsize24 text-primary text-center font-semibold"
          >
            {data ? data['4980097514'] : 'Lorem ipsum'}
          </p>
          <p
            id="4718020350"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['4718020350']
                : ' publishing and graphic design, Lorem ipsum is a placeholder text',
            }}
            className="dynamicStyle textColor-light text-center sm-fsize14 mb-4"
          >
            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis
            dolor quo atque eum, fugit voluptatem tempora pariatur asperiores
            alias voluptatum! */}
          </p>
          <hr className="hr-blg" />
        </div>
      </div>
      <Swiper
        spaceBetween={25}
        slidesPerView={3.2}
        observer={true}
        observeParents={true}
        modules={[Navigation]}
        className="mySwiper p-2"
        autoplay={{
          delay: '1000',
        }}
        loop
        navigation={{
          nextEl: '.image-swiper-button-next',
          prevEl: '.image-swiper-button-prev',
        }}
        breakpoints={{
          1536: {
            slidesPerView: 3.2,
            spaceBetween: 25,
          },
          1280: {
            slidesPerView: 3.2,
            spaceBetween: 25,
          },
          1024: {
            slidesPerView: 3.1,
            spaceBetween: 25,
          },
          991: {
            slidesPerView: 2.8,
            spaceBetween: 25,
          },
          767: {
            slidesPerView: 2,
            spaceBetween: 25,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 25,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 25,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 25,
          },
        }}
      >
        {allBlog
          ? allBlog.map(item => (
              <SwiperSlide>
                <Card
                  className="cursor-pointer cust-shadow rounded"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div id="" className="w-full ">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      className="w-full h-56 rounded"
                      alt="image"
                    />
                    <div className="blog-abs text-center blg-hov">
                      <p className="fsize14 font-semibold">
                        <p className=''>
                          {new Date(item.createdAt).toLocaleDateString(
                            'en-US',
                            {
                              month: 'short',
                              day: 'numeric',
                            }
                          )}
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="flex justify-between items-center">
                      <p className="fsize14 cust-textColor">
                        {new Date(item.createdAt).toLocaleTimeString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </p>

                      <div className="">
                        <Badge
                          borderRadius="full"
                          px="3"
                          py="0.5"
                          colorScheme="green"
                          className="fsize14 cust-textColor"
                        >
                          {item.categoryID[0].categoryName}
                        </Badge>
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="fsize22 sm-fsize20 font-semibold pb-2 cust-textColor">
                        {item.title}
                      </p>
                      <p
                        className="fsize14 lineClamp3 textColor-light"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></p>
                    </div>
                    <div className="flex  cursor-pointer">
                      <p className="text-primary cust-textColor fsize14">Read More</p>
                      <ArrowRight className="text-primary cust-textColor ml-2 arrow-r" />
                    </div>
                  </div>
                </Card>
              </SwiperSlide>
            ))
          : 'no-data'}
      </Swiper>
    </div>
  );
};

export default Blogs;
