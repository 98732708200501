import { Button, Image } from '@chakra-ui/react';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';

const DocumentSection2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="container mx-auto lg:px-12 md:px-12 px-5">
      <div className="flex items-center w-full mb-6">
        <div className="bg-primary px-5 py-2 rounded-lg md-w-20 sm-w-39">
          <p className=" text-white my-0 fsize15 sm-fsize14">2022 - 2023</p>
        </div>
        <hr className="w-10/12 ml-5"></hr>
      </div>
      <Swiper
        spaceBetween={30}
        observer={true}
        observeParents={true}
        modules={[Navigation]}
        navigation={{
          nextEl: '.image-swiper-button-next',
          prevEl: '.image-swiper-button-prev',
        }}
        className="mySwiper"
        breakpoints={{
          1536: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          425: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          325: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
        }}
      >
        <div className="swiper-button image-swiper-button-prev rounded-full p-1 cursor-pointer">
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </div>
        <div className="swiper-button image-swiper-button-next rounded-full p-1 cursor-pointer">
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
          </svg>
        </div>
        <SwiperSlide>
          <div className="cursor-pointer border-primary rounded-lg p-4">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              className="w-20 h-20 mx-auto"
              alt="women"
            />
            <p className="mt-4 text-center text-dark sm-fsize14">
              SBI Foundation Anual Report 2022 - 2023
            </p>
            <Button colorScheme="primary" className="bg-primary w-full mt-2">
              See Results
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="cursor-pointer border-primary rounded-lg p-4">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              className="w-20 h-20 mx-auto"
              alt="women"
            />
            <p className="mt-4 text-center text-dark sm-fsize14">
              SBI Foundation Anual Report 2022 - 2023
            </p>
            <Button colorScheme="primary" className="bg-primary w-full mt-2">
              See Results
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="cursor-pointer border-primary rounded-lg p-4">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              className="w-20 h-20 mx-auto"
              alt="women"
            />
            <p className="mt-4 text-center text-dark sm-fsize14">
              SBI Foundation Anual Report 2022 - 2023
            </p>
            <Button colorScheme="primary" className="bg-primary w-full mt-2">
              See Results
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="cursor-pointer border-primary rounded-lg p-4">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              className="w-20 h-20 mx-auto"
              alt="women"
            />
            <p className="mt-4 text-center text-dark sm-fsize14">
              SBI Foundation Anual Report 2022 - 2023
            </p>
            <Button colorScheme="primary" className="bg-primary w-full mt-2">
              See Results
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="cursor-pointer border-primary rounded-lg p-4">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              className="w-20 h-20 mx-auto"
              alt="women"
            />
            <p className="mt-4 text-center text-dark sm-fsize14">
              SBI Foundation Anual Report 2022 - 2023
            </p>
            <Button colorScheme="primary" className="bg-primary w-full mt-2">
              See Results
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="cursor-pointer border-primary rounded-lg p-4">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              className="w-20 h-20 mx-auto"
              alt="women"
            />
            <p className="mt-4 text-center text-dark sm-fsize14">
              SBI Foundation Anual Report 2022 - 2023
            </p>
            <Button colorScheme="primary" className="bg-primary w-full mt-2">
              See Results
            </Button>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="my-16">
        <div className="flex items-center w-full mb-6">
          <div className="bg-primary px-5 py-2 rounded-lg md-w-20 sm-w-39">
            <p className="text-white my-0 fsize15 sm-fsize14">2022 - 2023</p>
          </div>
          <hr className="w-10/12 ml-5"></hr>
        </div>
        <Swiper
          spaceBetween={30}
          observer={true}
          observeParents={true}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text-dark sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button colorScheme="primary" className="bg-primary w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text-dark sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button colorScheme="primary" className="bg-primary w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text-dark sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button colorScheme="primary" className="bg-primary w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text-dark sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button colorScheme="primary" className="bg-primary w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text-dark sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button colorScheme="primary" className="bg-primary w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text-dark sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button colorScheme="primary" className="bg-primary w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div>
        <div className="flex items-center w-full mb-6">
          <div className="bg-primary px-5 py-2 rounded-lg md-w-20 sm-w-39">
            <p className="text-white my-0 fsize15 sm-fsize14">2022 - 2023</p>
          </div>
          <hr className="w-10/12 ml-5"></hr>
        </div>
        <Swiper
          spaceBetween={30}
          observer={true}
          observeParents={true}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text-dark sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button colorScheme="primary" className="bg-primary w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text-dark sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button colorScheme="primary" className="bg-primary w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text-dark sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button colorScheme="primary" className="bg-primary w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text-dark sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button colorScheme="primary" className="bg-primary w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text-dark sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button colorScheme="primary" className="bg-primary w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text-dark sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button colorScheme="primary" className="bg-primary w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default DocumentSection2;
