const initialState = {
  recognizer: null,
  synthensizer: null,
  recognizedText: '',
  inputText: '',
  targetLanguage: 'en-US',
  selectedModel: '',
  isGPTResponseReceived: false,
  useGpt: false,
  ConversationHistory: [
    {
      role: 'system',
      content: 'You are helpful assistant',
    },
  ],
  emotion: 'calm',
  neuralModels: {
    'hi-IN': { model: 'hi-IN-SwaraNeural', speaker: 'Female' },
    'en-US': { model: 'en-US-JaneNeural', speaker: 'Female' },
    'es-MX': { model: 'es-MX-DaliaNeural', speaker: 'Female' },
  },
};

const Aiassist = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RECOGNIZER':
      return { ...state, recognizer: action.payload };

    case 'SET_SYNTHENSIZER':
      return { ...state, synthensizer: action.payload };

    case 'SET_RECOGNIZED_TEXT':
      return { ...state, recognizedText: action.payload };

    case 'SET_INPUT_TEXT':
      return { ...state, inputText: action.payload };

    case 'SET_TARGET_LANGUAGE':
      return { ...state, targetLanguage: action.payload };

    case 'SET_SELECTED_MODEL':
      return { ...state, selectedModel: action.payload };

    case 'SET_ISGPTRESPONSERECEIVED':
      return { ...state, isGPTResponseReceived: action.payload };

    case 'SET_USEGPT':
      return { ...state, useGpt: action.payload };

    case 'SET_CONVERSATION_HISTORY':
      return {
        ...state,
        ConversationHistory: [...state.ConversationHistory, action.payload],
      };

    case 'RESET_CONVERSATION_HISTORY':
      return {
        ...state,
        ConversationHistory: [action.payload],
      };

    case 'SET_EMOTION':
      return { ...state, emotion: action.payload };

    case 'SET_NEURAL_MODELS':
      return {
        ...state,
        neuralModels: [...state.neuralModels, action.payload],
      };
    default:
      return state;
  }
};
export default Aiassist;
