import React from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Text, Image, useColorMode } from '@chakra-ui/react';

const HeroSection3 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <section>
      <div className={colorMode === 'light' ? 'bg-secondary' : ''}>
        <div className="lg:flex md:flex sm-reverse h-full items-center">
          <div className="lg:px-20 md:px-12 px-5 lg:w-3/5 md:w-3/5 flex items-center banner-height">
            <div>
              <h2
                id="3245782186"
                className="dynamicStyle lg:text-4xl md:text-2xl uppercase text-end text-white text-xl font-semibold"
              >
                {data ? data['3245782186'] : 'Where to find Us'}
              </h2>
              <p
                id="1810046979"
                dangerouslySetInnerHTML={{
                  __html: data ? data['1810046979'] : 'no-data',
                }}
                className="dynamicStyle text-white  sm-fsize14 opacity-70 lg:mt-4 md:mt-4 mt-2"
              ></p>
            </div>
          </div>

          <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
            <div id="7853634472" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['7853634472']) : ''}
                alt="heroImage"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className=" object-cover w-full sm-h250px minh450px"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection3;
