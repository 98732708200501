import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { ArrowRight } from 'react-feather';
import { Card, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const FeatureCard6 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section className="container mx-auto lg:py-16 md:py-16 py-12 lg:px-12 md:px-8 px-5">
      <p
        id="4874395738"
        className="dynamicStyle font-semibold text-primary  cust-textColor"
      >
        {data ? data['4874395738'] : 'Fresh Arrivals'}
      </p>
      <p
        id="6594863381"
        className="dynamicStyle fsize34 md:fsize26 sm-fsize22 font-medium lg:mb-8 md:mb-8 mb-4 clr-191E22  cust-textColor"
      >
        {data ? data['6594863381'] : 'Discover New Fabric Prints'}
      </p>
      <div className="w-full grid lg:grid-cols-3 md:grid-cols-3 lg:gap-12 md:gap-4 gap-8">
        <Card className="" data-aos="zoom-in" data-aos-once="true">
          <div id="1739806776" className="dynamicStyle2 w-full">
            <Image
              className="h-380 lg:pb-4 md:pb-4 pb-2 sm-w-full css-0"
              src={data ? getImage(data['1739806776']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <p
            id="3470222065"
            className="dynamicStyle mb-2 font-semibold md-fsize14 sm-fsize14 cust-textColor dynamic_3470222065 px-2"
          >
            {data ? data['3470222065'] : 'Lorem Ipsum'}
          </p>
          <p
            id="8370982067"
            className="dynamicStyle fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 textColor-light px-2"
          >
            {data ? data['8370982067'] : 'Lorem Ipsum'}
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4  cursor-pointer text-primary  cust-textColor cust-textColor ml-2 mb-2">
            <p id="6071242330" className="dynamicStyle sm-fsize14">
              {data ? data['6071242330'] : 'Lorem Ipsum'}
            </p>
            <ArrowRight className=" ml-2 sm-arrow" />
          </div>
        </Card>
        <Card className="" data-aos="zoom-in" data-aos-once="true">
          <div id="9397843858" className="dynamicStyle2 w-full">
            <Image
              className="h-380 lg:pb-4 md:pb-4 pb-2 sm-w-full css-0"
              src={data ? getImage(data['9397843858']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <p
            id="0670466492"
            className="dynamicStyle mb-2 font-semibold md-fsize14 sm-fsize14 cust-textColor dynamic_3470222065 px-2"
          >
            {data ? data['0670466492'] : 'Lorem Ipsum'}
          </p>
          <p
            id="2261159466"
            className="dynamicStyle fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 textColor-light px-2"
          >
            {data ? data['2261159466'] : 'Lorem Ipsum'}
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer text-primary  cust-textColor cust-textColor ml-2 mb-2">
            <p id="9470875064" className="dynamicStyle sm-fsize14">
              {data ? data['9470875064'] : 'Lorem Ipsum'}
            </p>
            <ArrowRight className="ml-2 sm-arrow" />
          </div>
        </Card>
        <Card className="" data-aos="zoom-in" data-aos-once="true">
          <div id="3812667709" className="dynamicStyle2 w-full">
            <Image
              className="h-380 lg:pb-4 md:pb-4 pb-2 sm-w-full css-0"
              src={data ? getImage(data['3812667709']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <p
            id="4095040812"
            className="dynamicStyle mb-2 font-semibold md-fsize14 sm-fsize14 cust-textColor dynamic_3470222065 px-2"
          >
            {data ? data['4095040812'] : 'Lorem Ipsum'}
          </p>
          <p
            id="8771946214"
            className="dynamicStyle fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 textColor-light px-2"
          >
            {data ? data['8771946214'] : 'Lorem Ipsum'}
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer text-primary  cust-textColor cust-textColor ml-2 mb-2">
            <p id="0895084800" className="dynamicStyle sm-fsize14">
              {data ? data['0895084800'] : 'Lorem Ipsum'}
            </p>
            <ArrowRight className="ml-2 sm-arrow" />
          </div>
        </Card>
      </div>
    </section>
  );
};

export default FeatureCard6;
