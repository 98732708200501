import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image, useColorMode } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const HeroSection10 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    Aos.init();
  });
  return (
    <section
      className={colorMode === 'light' ? 'bg-secondary' : 'cust-bgColor'}
    >
      <div className="lg:flex gap-4 block container mx-auto lg:pt-32 lg:px-12 md:px-12 px-5 md:pt-20 pt-12 lg:pb-16 md:pb-16 pb-12">
        <div
          className="lg:w-1/3 md:w-full w-full  lg:flex flex-col justify-center lg:px-4 lg:mb-0 md:mb-6 mb-6"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <h1
            id="9631188310"
            className="dynamicStyle hero-header lg:mb-4 md:mb-4 mb-2 cust-textColor"
          >
            {data ? data['9631188310'] : 'Lorem ipsum generated'}
          </h1>
          <p
            id="6813605919"
            className="dynamicStyle fsize20 sm-fsize16 textColor-light"
          >
            {data ? data['6813605919'] : 'Lorem ipsum generated'}
          </p>
        </div>
        <div
          id="4567987237"
          className="dynamicStyle2 lg:w-1/3 md:w-full w-full h-680px md-h-450 sm-h-250px"
        >
          <Image
            src={data ? getImage(data['4567987237']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="hero"
            className="br-40px lg:w-full md:w-2/3 w-full mx-auto h-full md-h-450 object-cover"
          />
        </div>
        <div
          className="lg:w-1/3 md:w-full w-full  lg:flex flex-col justify-center lg:px-4 lg:mt-0 md:mt-4 mt-4"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <p
            id="7703235926"
            className="dynamicStyle fsize20 sm-fsize16 textColor-light"
          >
            {data ? data['7703235926'] : 'Lorem ipsum generated'}
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection10;
