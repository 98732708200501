import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const AboutSection12 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="lg:py-16 py-8">
      <div className="container mx-auto lg:px-12 px-4">
        <div className="lg:flex items-center">
          <div
            className="lg:w-6/12 lg:pr-10 lg:mt-0 mt-4 lg:mb-0 mb-4"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <p
              className="fsize18 sm-fsize15 textsecondary font-semibold dynamicStyle"
              id="5243023323"
            >
              {data ? data['5243023323'] : 'Lorem Ipsum'}
            </p>
            <h2
              className="fsize30 sm-fsize20 font-semibold textprimary cust-textColor dynamicStyle"
              id="4772448594"
            >
              {data ? data['4772448594'] : 'Lorem Ipsum'}
            </h2>
            <p
              className="fsize16 sm-fsize14 textColor-light lg:mt-3 md:mt-3 mt-1  dynamicStyle"
              id="9823441385"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['9823441385']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
            ></p>
          </div>
          <div className="lg:w-6/12 lg:pl-10">
            <div id="8824589259" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['8824589259']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="ilm-img"
                alt="ilm"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection12;
