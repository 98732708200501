import {
  Button,
  Checkbox,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { FormControl } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const MyCart = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const {
    isOpen: isOpenAddress,
    onOpen: onOpenAddress,
    onClose: onCloseAddress,
  } = useDisclosure();
  return (
    <div className="container mx-auto lg:px-12 md:px-6 px-5 lg:py-16 md:py-12 py-8 flex">
      <div className="w-full lg:flex md:flex gap-8">
        <div className="borderall rounded-3px  w-40 lg:py-6 lg:px-6 md:px-6 py-5 px-3 height-fit">
          <div className="lg:flex md:flex justify-between items-center lg:mb-3 md:mb-3">
            <h2 id="8151684187" className="dynamicStyle fsize20 sm-pb-4">
              {data ? data['8151684187'] : 'Delivery Address'}
            </h2>

            <Button
              colorScheme="primary"
              id="0159719583"
              className="dynamicStyle sm-mb-4 add-btn"
              onClick={onOpenAddress}
            >
              {data ? data['0159719583'] : 'Add New Address'}
            </Button>
          </div>
          <Modal isOpen={isOpenAddress} onClose={onCloseAddress}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add Address</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <>
                  <div className="">
                    <div className="bordbl">
                      <h2 className="uppercase fsize14 font-medium mb-4">
                        Contact Details
                      </h2>
                      <FormControl>
                        <Input
                          type="text"
                          className="mb-4 fsize14"
                          placeholder="Name*"
                        />
                      </FormControl>
                      <FormControl>
                        <Input
                          type="text"
                          className="mb-4 fsize14"
                          placeholder="Mobile no*"
                        />
                      </FormControl>
                    </div>

                    <div className="mt-2 bordbl">
                      <h2 className="uppercase fsize14 font-medium mb-4">
                        address
                      </h2>
                      <FormControl>
                        <Input
                          type="text"
                          className="mb-4 fsize14"
                          placeholder="Pincode"
                        />
                      </FormControl>
                      <FormControl>
                        <Input
                          type="text"
                          className="mb-4 fsize14"
                          placeholder="Address (House No, Building, Street, Area)*"
                        />
                      </FormControl>
                      <FormControl>
                        <Input
                          type="text"
                          className="mb-4 fsize14"
                          placeholder="Location / Town *"
                        />
                      </FormControl>
                      <div className="flex gap-4">
                        <FormControl>
                          <Input
                            type="text"
                            className="mb-4 fsize14"
                            placeholder="District"
                          />
                        </FormControl>
                        <FormControl>
                          <Input
                            type="text"
                            className="mb-4 fsize14"
                            placeholder="State"
                          />
                        </FormControl>
                      </div>
                    </div>

                    <div className="mt-2 ">
                      <h2 className="uppercase fsize14 font-medium mb-4">
                        save address as
                      </h2>
                      <div className="flex gap-4">
                        <Button
                          className="fsize12 rounded-100px"
                          py="1"
                          px="3"
                          variant="outline"
                          height="6"
                        >
                          Home
                        </Button>
                        <Button
                          className="fsize12 rounded-100px"
                          py="1"
                          px="3"
                          variant="outline"
                          height="6"
                        >
                          Work
                        </Button>
                      </div>
                      <Checkbox className="mt-4">
                        <span className="fsize12">
                          make this my default address
                        </span>
                      </Checkbox>
                    </div>
                  </div>

                  <div className="lg:my-6 md:my-6 my-4">
                    <Button
                      colorScheme="primary"
                      className="w-100per  sm-fsize14"
                    >
                      Add Address
                    </Button>
                  </div>
                </>
              </ModalBody>
            </ModalContent>
          </Modal>

          <div className=" lg:mb-6">
            <div className="flex items-start gap-2 bordbl py-3">
              <Radio size="md" className="mt-1"></Radio>
              <div className="">
                <h2 className="fsize16 font-medium">John Cena</h2>
                <p className="fsize12 text-gray">
                  Flat 102, Empire Building, Metro Andheri Station,
                </p>

                <p className="fsize12 text-gray">Andheri Kurla Rd, Gundwali,</p>

                <p className="fsize12 text-gray">Mumbai, Maharashtra 400069</p>
              </div>
            </div>
            <div className="flex items-start gap-2 bordbl py-3">
              <Radio size="md" className="mt-1"></Radio>
              <div className="">
                <h2 className="fsize16 font-medium">John Cena</h2>
                <p className="fsize12 text-gray">
                  Flat 102, Empire Building, Metro Andheri Station,
                </p>

                <p className="fsize12 text-gray">Andheri Kurla Rd, Gundwali,</p>

                <p className="fsize12 text-gray">Mumbai, Maharashtra 400069</p>
              </div>
            </div>
            <div className="w-full flex gap-2 mt-4">
              <Button
                colorScheme="primary"
                height="auto"
                py="1"
                className="dynamicStyle fsize14 sm-fsize14 "
                id="5287899132"
              >
                {data ? data['5287899132'] : 'Edit'}
              </Button>

              <Button
                colorScheme="primary"
                height="auto"
                py="1"
                variant="outline"
                className="dynamicStyle fsize14 sm-fsize14 "
                id="2988353466"
              >
                {data ? data['2988353466'] : 'Delete'}
              </Button>
            </div>
          </div>
        </div>
        <div className="w-60 sm-pt-8">
          <h2
            id="3028432367"
            className="dynamicStyle fsize20 font-semibold lg:mb-4 mb-4"
          >
            {data ? data['3028432367'] : 'My Cart'} (0)
          </h2>
          <div className="borderall rounded-3px  lg:p-5 p-4 relative lg:mb-6 md:mb-6 sm-mb-4">
            <div className="w-full lg:flex md:flex gap-4 justify-between">
              <div className="lg:w-1/4 md:w-1/4 sm-mb-4">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="prduct-image"
                  className="object-contain h-150px sm-w-full"
                />
              </div>
              <div className="lg:w-3/4 md:w-3/4">
                <h2 className="fsize16 font-medium mb-1">
                  Apple Iphone 14 Pro
                </h2>
                <p className="lineClamp4 fsize14 lg:mb-2 mb-2">
                  The iPhone 14 models come in blue, purple, midnight,
                  starlight, and (PRODUCT)RED, plus Apple added a new yellow
                  color in March. Unlike the iPhone 14 Pro models, the iPhone 14
                  and 14 Plus continue to have a notch at the top of the display
                  that houses the TrueDepth camera. Like the iPhone 13 models,
                  the iPhone 14 and iPhone 14 Plus include Super Retina XDR OLED
                  displays that support 1200 nits peak brightness, a 2,000,000:1
                  contrast ratio, Dolby Vision, and True Tone support for
                  matching the white balance of the display to the lighting in
                  the room.
                </p>
                <div className="flex items-center gap-4">
                  <p className="fsize16 font-medium">₹125991</p>
                  <p className="fsize16 sm-fsize14 font-medium strikethrough">
                    ₹125991
                  </p>
                </div>
                <div className="w-full lg:mt-4 md:mt-4 mt-2 flex items-center gap-4">
                  <h2 className="fsize16 font-medium ">Quantity :</h2>
                  <div className="counter1 ">
                    <div className="flex items-center">
                      <span className="cursor-pointer p-2">-</span>
                      <input type="text" className="counter-input " value="1" />
                      <span className="cursor-pointer p-2">+</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute top-3 right-3">
                <FeatherIcon icon="x" className="cursor-pointer w-4 h-4" />
              </div>
            </div>
          </div>
          <div className="borderall lg:p-5 p-4 relative lg:mb-6 md:mb-6 sm-mb-4">
            <div className="w-full lg:flex md:flex gap-4 justify-between">
              <div className="lg:w-1/4 md:w-1/4 sm-mb-4">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="prduct-image"
                  className="object-contain h-150px sm-w-full"
                />
              </div>
              <div className="lg:w-3/4 md:w-3/4">
                <h2 className="fsize16 font-medium mb-1">
                  Apple Iphone 14 Pro
                </h2>
                <p className="lineClamp4 fsize14 lg:mb-2 mb-2">
                  The iPhone 14 models come in blue, purple, midnight,
                  starlight, and (PRODUCT)RED, plus Apple added a new yellow
                  color in March. Unlike the iPhone 14 Pro models, the iPhone 14
                  and 14 Plus continue to have a notch at the top of the display
                  that houses the TrueDepth camera. Like the iPhone 13 models,
                  the iPhone 14 and iPhone 14 Plus include Super Retina XDR OLED
                  displays that support 1200 nits peak brightness, a 2,000,000:1
                  contrast ratio, Dolby Vision, and True Tone support for
                  matching the white balance of the display to the lighting in
                  the room.
                </p>
                <div className="flex items-center gap-4">
                  <p className="fsize16 font-medium">₹125991</p>
                  <p className="fsize16 sm-fsize14 font-medium strikethrough">
                    ₹125991
                  </p>
                </div>
                <div className="w-full lg:mt-4 md:mt-4 mt-2 flex items-center gap-4">
                  <h2 className="fsize16 font-medium ">Quantity :</h2>
                  <div className="counter1 ">
                    <div className="flex items-center">
                      <span className="cursor-pointer p-2">-</span>
                      <input type="text" className="counter-input " value="1" />
                      <span className="cursor-pointer p-2">+</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute top-3 right-3">
                <FeatherIcon icon="x" className="cursor-pointer w-4 h-4" />
              </div>
            </div>
          </div>
          <div className="lg:my-8 md:my-8 my-6">
            <div className="lg:mb-12 md:mb-6 mb-6">
              <h4
                id="0605333102"
                className="dynamicStyle fsize18 font-medium lg:mb-6 md:mb-3 mb-3"
              >
                {data ? data['0605333102'] : 'Promotional codes'}
              </h4>
              <div className="w-full relative">
                <Input
                  placeholder="Enter Code"
                  className="py-4 pl-4 pr-9rem fsize12 sm-fsize14 bg-white"
                  id=""
                />
                <div className=" absolute top-0 right-0">
                  <Button
                    colorScheme="primary"
                    fontSize="14"
                    id="1248066373"
                    className="dynamicStyle text-white sm-fsize14"
                  >
                    {data ? data['1248066373'] : 'Proceed to Buy'}
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center bordb-ececec lg:py-4 md:py-4 py-2">
              <p
                id="1946898174"
                className="dynamicStyle fsize14 font-medium pl-2"
              >
                {data ? data['1946898174'] : 'Total MRP'}
              </p>
              <p className="fsize14 font-medium">₹503,964.00/-</p>
            </div>
            <div className="flex justify-between items-center bordb-ececec lg:py-4 md:py-4 py-2">
              <p
                id="1532157759"
                className="dynamicStyle fsize14 font-medium pl-2"
              >
                {data ? data['1532157759'] : 'Coupon Discount'}
              </p>
              <p className="fsize14 font-medium">₹503,964.00/-</p>
            </div>
            <div className="flex justify-between items-center bordb-ececec lg:py-4 md:py-4 py-2">
              <p
                id="4597849121"
                className="dynamicStyle fsize14 font-medium pl-2"
              >
                {data ? data['4597849121'] : 'Tax and Fees'}
              </p>
              <p className="fsize14 font-medium">₹503,964.00/-</p>
            </div>
            <div className="flex justify-between items-center bordb-ececec lg:py-4 md:py-4 py-2">
              <p
                id="1353511121"
                className="dynamicStyle fsize14 font-medium pl-2"
              >
                {data ? data['1353511121'] : 'Total Amount'}
              </p>
              <p className="fsize14 font-medium">₹503,964.00/-</p>
            </div>
            <div className=" w-full lg:mt-8 md:mt-8 mt-6">
              <Button
                colorScheme="primary"
                py="6"
                className="dynamicStyle w-100per sm-fsize15"
                id="8991186252"
              >
                {data ? data['8991186252'] : 'Proceed to Buy'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCart;
