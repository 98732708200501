import React, { useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Team3 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });

  return (
    <section className="lg:px-12 md:px-12 p-6 lg:py-8">
      <div className="text-center container mx-auto flex flex-col justify-center lg:pb-16 md:pb-8 pb-4">
        <h1
          id="7787544779"
          className="dynamicStyle cust-textColor fsize36 font-semibold sm-fsize20 lg:mb-0 md:mb-0 mb-2"
        >
          {data ? data['7787544779'] : 'Meet Aur Professional teams'}
        </h1>
        <p
          id="0412520574"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['0412520574']
              : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus',
          }}
          className="dynamicStyle textColor-light fsize16 sm-fsize14 text-center textColor-light"
        ></p>
      </div>
      <div className="container mx-auto overflow-x-hidden">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            820: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <Card
              className={
                colorMode === 'light'
                  ? 'box-shadow-none p-2 w-full rounded-lg'
                  : 'p-2 w-full rounded-lg'
              }
            >
              <div
                id="0649316072"
                className="dynamicStyle2 flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['0649316072']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="imagee"
                  className="teams2-img rounded-full object-cover lg:mb-6 bg-d2d2d2"
                />
              </div>
              <h2
                id="1317111919"
                className="dynamicStyle fsize18 font-semibold lg:mb-2 text-center cust-textColor"
              >
                {data ? data['1317111919'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="3845102598"
                className="dynamicStyle text-gray fsize16 sm-fsize14 text-center textColor-light "
              >
                {data ? data['3845102598'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card
              className={
                colorMode === 'light'
                  ? 'box-shadow-none p-2 w-full rounded-lg'
                  : 'p-2 w-full rounded-lg'
              }
            >
              <div
                id="8042795352"
                className="dynamicStyle2 flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['8042795352']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="imagee"
                  className="teams2-img rounded-full object-cover lg:mb-6 bg-d2d2d2"
                />
              </div>
              <h2
                id="0842304786"
                className="dynamicStyle fsize18 font-semibold lg:mb-2 text-center cust-textColor"
              >
                {data ? data['0842304786'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="4810333020"
                className="dynamicStyle text-gray fsize16 sm-fsize14 text-center textColor-light "
              >
                {data ? data['4810333020'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card
              className={
                colorMode === 'light'
                  ? 'box-shadow-none p-2 w-full rounded-lg'
                  : 'p-2 w-full rounded-lg'
              }
            >
              <div
                id="4725390375"
                className="dynamicStyle2 flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['4725390375']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="imagee"
                  className="teams2-img rounded-full object-cover lg:mb-6 bg-d2d2d2"
                />
              </div>
              <h2
                id="4902244121"
                className="dynamicStyle fsize18 font-semibold lg:mb-2 text-center cust-textColor"
              >
                {data ? data['4902244121'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="0355533857"
                className="dynamicStyle text-gray fsize16 sm-fsize14 text-center textColor-light "
              >
                {data ? data['0355533857'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card
              className={
                colorMode === 'light'
                  ? 'box-shadow-none p-2 w-full rounded-lg'
                  : 'p-2 w-full rounded-lg'
              }
            >
              <div
                id="1958434052"
                className="dynamicStyle2 flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['1958434052']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="imagee"
                  className="teams2-img rounded-full object-cover lg:mb-6 bg-d2d2d2"
                />
              </div>
              <h2
                id="0807849678"
                className="dynamicStyle fsize18 font-semibold lg:mb-2 text-center cust-textColor"
              >
                {data ? data['0807849678'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="2467319295"
                className="dynamicStyle text-gray fsize16 sm-fsize14 text-center textColor-light "
              >
                {data ? data['2467319295'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card
              className={
                colorMode === 'light'
                  ? 'box-shadow-none p-2 w-full rounded-lg'
                  : 'p-2 w-full rounded-lg'
              }
            >
              <div
                id="6858823359"
                className="dynamicStyle2 flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['6858823359']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="imagee"
                  className="teams2-img rounded-full object-cover lg:mb-6 bg-d2d2d2"
                />
              </div>
              <h2
                id="4278880555"
                className="dynamicStyle fsize18 font-semibold lg:mb-2 text-center cust-textColor"
              >
                {data ? data['4278880555'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="9133571436"
                className="dynamicStyle text-gray fsize16 sm-fsize14 text-center textColor-light "
              >
                {data ? data['9133571436'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Team3;
