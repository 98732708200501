import {
  Avatar,
  Image,
  WrapItem,
  Textarea,
  Button,
  useDisclosure,
  Input,
  InputGroup,
  InputRightElement,
  useColorMode,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import {
  getAllBlogs,
  getSingleBlogs,
  setSelectedBlog,
} from '../../../redux/blogs/blogs-actions';
import FeatherIcon from 'feather-icons-react';
import { FaShareAlt } from 'react-icons/fa';
import ShareProfileSection2 from './ShareProfileSection2';
import { CopyIcon, LinkIcon } from '@chakra-ui/icons';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const BlogPost = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const { blogId } = useParams();
  const dispatch = useDispatch();
  // let allBlog = useSelector((state) => state.BlogDetails.Blog);
  let Blogs = useSelector(state => state.BlogDetails.Blogs);
  let selectedBlog = useSelector(state => state.BlogDetails.selectedBlog);
  // const [selectedBlog, setSelectedBlog] = useState({});
  const [blogHTMLData, setBlogHTMLData] = useState('');
  const [blogLink, setBlogLink] = useState(
    `${process.env.REACT_APP_FRONTEND_URL}/blog%20detail/${blogId}`
  );
  const [copied, setCopied] = useState(false);

  const {
    isOpen: isOpenShare,
    onOpen: onOpenShare,
    onClose: onCloseShare,
  } = useDisclosure();

  useEffect(() => {
    fetchBlogData();
    setBlogLink(window.location.href);
  }, [blogId]);

  const fetchBlogData = async () => {
    let { data } = await dispatch(getSingleBlogs({ blogId: blogId }));
    console.log(data);
    if (data && data.totalCount > 0 && data.result && data.result.length > 0) {
      console.log(data);
      dispatch(setSelectedBlog(data.result[0]));
      console.log(selectedBlog, 'Blogs.result');
      setBlogHTMLData(data.result[0].content);
    }
  };

  return (
    <section>
      <Modal isOpen={isOpenShare} onClose={onCloseShare}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <div className="mt-6 mb-2">
              <div className="flex justify-center ">
                <ShareProfileSection2 blogId={blogId} />
              </div>
              <InputGroup size="md">
                <Input pr="4.5rem" placeholder="Copy Link" value={blogLink} />
                <InputRightElement width="4.5rem" mr={2}>
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => {
                      setCopied(true);
                      setTimeout(x => {
                        setCopied(false);
                      }, 500);
                      navigator.clipboard.writeText(blogLink);
                    }}
                  >
                    {copied ? 'Copied' : 'Copy'}
                    <LinkIcon ml={1} className="fsize12" />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* banner */}
      <div
      className={
        colorMode === 'light'
          ? 'bg-secondary'
          : ''
      }
     >
        <div className="lg:flex md:flex sm-reverse lg:px-0 md:px-4 px-5 items-center lg:py-0 md:py-10 py-6">
          <div className="lg:w-3/5 md:w-2/4 lg:px-20 md:px-8 lg:pt-0 md:pt-0 pt-4">
            <p className="text-white fsize12 pb-2">
              {selectedBlog && selectedBlog.createdAt
                ? new Date(selectedBlog.createdAt).toDateString()
                : ''}
            </p>
            <h2 className="lg:text-3xl md:text-2xl uppercase text-end  text-white text-xl font-semibold">
              {selectedBlog && selectedBlog.title ? selectedBlog.title : ''}
            </h2>
          </div>

          <div className="lg:w-2/5 md:w-2/4 lg:flex md:flex justify-end w-full">
            <Image
              src={
                `${process.env.REACT_APP_STORAGE_URL}` +
                `${
                  selectedBlog && selectedBlog.image ? selectedBlog.image : ''
                }`
              }
              alt="heroImage"
              className=" w-full sm-h250px minh450px object-cover"
            />
          </div>
        </div>
      </div>

      {/* post */}
      <div className="relative">
        <div className=" block zindex9 relative container lg:px-12 md:px-8 px-5 mx-auto lg:py-16 py-6 md:py-16">
          {/* write here */}
          <div className="quill">
            <div className="flex justify-between items-center w-full">
              <h2 className="font-semibold lg:text-2xl md:text-2xl cust-textColor">
                {selectedBlog && selectedBlog.title ? selectedBlog.title : ''}
              </h2>
              <div className="">
                <FaShareAlt
                  className="fsize18 cursor-pointer"
                  onClick={onOpenShare}
                />
              </div>
            </div>

            <ReactQuill
              theme="bubble"
              readOnly
              value={blogHTMLData}
              onChange={setBlogHTMLData}
            />
            {/* <p
              dangerouslySetInnerHTML={{ __html: selectedBlog.content }}
              className=" lg:text-base md:text-base text-sm  opacity-70 lg:mt-4 md:mt-4 mt-2"
            ></p> */}
            {selectedBlog && selectedBlog.embedLink && (
              <div
                className="lg:mt-4 md:mt-4 mt-2"
                dangerouslySetInnerHTML={{
                  __html: selectedBlog.embedLink ? selectedBlog.embedLink : '',
                }}
              ></div>
            )}
          </div>

          <div className="mt-8">
            <hr />
            {/* <div className="flex gap-4 mt-4">
              <FeatherIcon
                icon="thumbs-up"
                className="text-gray cursor-pointer"
              />
              <FeatherIcon
                icon="message-circle"
                className="text-gray cursor-pointer"
              />
              <FeatherIcon
                icon="share-2"
                className="text-gray cursor-pointer"
              />
            </div>

            <div className="flex gap-4 mt-8">
              <WrapItem>
                <Avatar name="Jon Doe"></Avatar>
              </WrapItem>
              <div className="w-full">
                {" "}
                <Textarea
                  placeholder="Please write here...."
                  className="h-120px"
                />
                <div className="flex justify-end mt-6">
                  <Button colorScheme="primary" px="8" py="5">
                    Comment{" "}
                  </Button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogPost;
