export const setRecognizer = data => {
  return dispatch => {
    dispatch({ type: 'SET_RECOGNIZER', payload: data });
  };
};
export const setSynthensizer = data => {
  return dispatch => {
    dispatch({ type: 'SET_SYNTHENSIZER', payload: data });
  };
};

export const setRecognizedText = data => {
  return dispatch => {
    dispatch({ type: 'SET_RECOGNIZED_TEXT', payload: data });
  };
};

export const setInputText = data => {
  return dispatch => {
    dispatch({ type: 'SET_INPUT_TEXT', payload: data });
  };
};

export const setTargetLanguage = data => {
  return dispatch => {
    dispatch({ type: 'SET_TARGET_LANGUAGE', payload: data });
  };
};

export const setSelectedModel = data => {
  return dispatch => {
    dispatch({ type: 'SET_SELECTED_MODEL', payload: data });
  };
};

export const setisGPTResponseReceived = data => {
  return dispatch => {
    dispatch({ type: 'SET_ISGPTRESPONSERECEIVED', payload: data });
  };
};

export const setuseGpt = data => {
  return dispatch => {
    dispatch({ type: 'SET_USEGPT', payload: data });
  };
};

export const setConversationHistory = data => {
  return dispatch => {
    dispatch({ type: 'SET_CONVERSATION_HISTORY', payload: data });
  };
};

export const ResetConversationHistory = data => {
  return dispatch => {
    dispatch({
      type: 'RESET_CONVERSATION_HISTORY',
      payload: {
        role: 'system',
        content: 'You are helpful assistant',
      },
    });
  };
};

export const setEmotion = data => {
  return dispatch => {
    dispatch({ type: 'SET_EMOTION', payload: data });
  };
};

export const setNeuralModels = data => {
  return dispatch => {
    dispatch({ tsype: 'SET_NEURAL_MODELS', payload: data });
  };
};
