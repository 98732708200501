import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";

const AboutSection11 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="lg:py-16 py-8">
      <div className="container mx-auto lg:px-12 md:px-10 px-4">
        <div className="lg:flex md:flex items-center">
          <div className="lg:w-6/12 md:w-6/12 w-full lg:pr-10">
            <div id="9785524978" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data["9785524978"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="working-img rounded-lg"
                alt="working"
              />
            </div>
          </div>

          <div
            className="lg:w-6/12 md:w-6/12 w-full lg:pl-10 md:pl-4 lg:mt-0 mt-6"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <h2
              className="fsize36 sm-fsize20 font-semibold textprimary cust-textColor dynamicStyle"
              id="1529794711"
            >
              {data ? data["1529794711"] : "Lorem Ipsum"}
            </h2>
            <p
              className="fsize16 sm-fsize14 textColor-light mt-2 dynamicStyle pb-0"
              id="0451643108"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["0451643108"]
                  : " publishing and graphic design, Lorem ipsum is a placeholder text",
              }}
            ></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection11;
