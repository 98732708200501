import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Mail, Phone } from 'react-feather';
import { Button, Card, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';

const AboutSection7 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section className="container mx-auto lg:px-12 md:px-12 px-5 lg:py-24 md:py-16 py-10  lg:flex md:flex lg:gap-6 md:gap-2 gap-4">
      <div
        className="lg:w-1/2 md:w-1/2 pb-8"
        data-aos="fade-up"
        data-aos-once="true"
      >
        <p id="1096538094" className="dynamicStyle fsize18 sm-fsize16 text-primary cust-textColor font-semibold">
          {data ? data['1096538094'] : 'Lorem Ipsum'}
        </p>
        <p
          id="1622512764"
          className="dynamicStyle fsize36 md:fsize28 sm-fsize20 font-medium lg:pb-3 md:pb-3 pb-1 clr-191E22 cust-textColor"
        >
          {data ? data['1622512764'] : 'Lorem Ipsum'}
        </p>
        <p
          id="1051677193"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['1051677193']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className="dynamicStyle textColor-light lg:pb-4 md:pb-4 pb-2 fsize16 sm-fsize14 clr-191E22"
        ></p>
        {/* <p className="lg:pb-4 md:pb-4 pb-4 sm-fsize14 clr-191E22">
          Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet
          elementum. Proin bibendum sollicitudin feugiat. Curabitur ut egestas
          justo, vitse molestie ante. Integer magna purus, commodo in diam nec,
          pretium auctor sapien.
        </p> */}

        <Button
          colorScheme="primary"
          className="dynamicStyle lg:px-4 lg:py-2 md:px-4 md:py-2 px-2 py-1  fsize14 sm-fsize12  rounded-lg"
          id="2195935300"
        >
          <span onClick={() => handleClick('2195935300')}>
          {data ? data['2195935300'] : 'READ MORE'}
          </span>
        </Button>
      </div>
      <Card
      className={
        colorMode === 'light'
          ? 'lg:w-1/4 md:w-1/4 sm-pb-6 box-shadow-none'
          : 'lg:w-1/4 md:w-1/4 sm-pb-6'
      }
     >
        <div id="2798158275" className="dynamicStyle2 w-full">
          <Image
            className=" lg:h-80 md:h-80 h-56 sm-w-full"
            src={data ? getImage(data['2798158275']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
          />
        </div>
        <div className="p-2">
        <p
          id="2187447847"
          className="dynamicStyle lg:py-2 md:py-2 py-1 lg:px-2 md:px-2 py-2 px-2 sm-fsize14 cust-textColor"
        >
          {data
            ? data['2187447847']
            : 'We provide wide variety of fabrics prints.'}
        </p>
        <div className="flex lg:px-2 md:px-2 px-2 lg:pb-3 md:pb-3 pb-2 gap-2 ">
          <Phone className="sm-wid text-primary cust-textColor" />
          <p
            id="2177972079"
            className="dynamicStyle cursor-pointer sm-fsize14 md-fsize14 text-primary cust-textColor"
          >
            <a href="tel:+971 54 377 9204">
              {data ? data['2177972079'] : '+971 54 377 9204'}
            </a>
          </p>
        </div>
        <div className="flex lg:px-2 md:px-2 px-2 gap-2 ">
          <Mail className="sm-wid text-primary cust-textColor" />
          <p
            id="8570414603"
            className="dynamicStyle cursor-pointer sm-fsize14 md-fsize14 text-primary cust-textColor"
          >
            <a href="mailto:Example@gmail.com">
              {data ? data['8570414603'] : 'Example@gmail.com'}
            </a>
          </p>
        </div>
        </div>
      </Card>
      <Card className={
        colorMode === 'light'
          ? 'lg:w-1/4 md:w-1/4 sm-pb-6 box-shadow-none'
          : 'lg:w-1/4 md:w-1/4 sm-pb-6'
      }>
        <div id="2607730231" className="dynamicStyle2 w-full">
          <Image
            className=" lg:h-80 md:h-80 h-56 sm-w-full"
            src={data ? getImage(data['2607730231']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
          />
        </div>
        <div className="p-2">
        <p
          id="4132870535"
          className="dynamicStyle lg:py-2 md:py-2 py-1 lg:px-2 md:px-2 py-2 px-2 sm-fsize14 cust-textColor"
        >
          {data
            ? data['4132870535']
            : 'We provide wide variety of fabrics prints.'}
        </p>
        <div className="flex lg:px-2 md:px-2 px-2 lg:pb-3 md:pb-3 pb-2 gap-2">
          <Phone className="sm-wid text-primary cust-textColor" />
          <p
            id="2121777879"
            className="dynamicStyle cursor-pointer sm-fsize14 md-fsize14 text-primary cust-textColor"
          >
            <a href="tel:+971 54 377 9204">
              {data ? data['2121777879'] : '+971 54 377 9204'}
            </a>
          </p>
        </div>
        <div className="flex lg:px-2 md:px-2 px-2 gap-2 ">
          <Mail className="sm-wid text-primary cust-textColor" />
          <p
            id="9393274878"
            className="dynamicStyle cursor-pointer sm-fsize14 md-fsize14 text-primary cust-textColor"
          >
            <a href="mailto:Example@gmail.com">
              {data ? data['9393274878'] : 'Example@gmail.com'}
            </a>
          </p>
        </div>
        </div>
      </Card>
    </section>
  );
};

export default AboutSection7;
