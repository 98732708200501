import { DownloadIcon } from '@chakra-ui/icons';
import { Button, Image } from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  FaCalendarAlt,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaTwitter,
} from 'react-icons/fa';
import { Navigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { FiLogOut, FiMail } from 'react-icons/fi';
import About from './components/About';
import Blogs from './components/Blogs';
import Resume from './components/Resume';
import Works from './components/Works';
import ContactUs from './components/ContactUs';
import UserProfilePage from './components/UserProfilePage';
import ManageAccount from './components/ManageAccount';
import HelpSupport from './components/HelpSupport';
import UserProfilePage1 from './components/UserProfilePage1';
import ManageAccount1 from './components/ManageAccount1';
import HelpSupport1 from './components/HelpSupport1';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../redux/auth/auth-action';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
const Profile3 = () => {
  const dispatch = useDispatch();
  let userData = useSelector(state => state.AuthDetails.user);
  const handleLogout = async () => {
    if (userData && userData.email) {
      let payload = {
        email: userData.email,
      };
      try {
        const data = await dispatch(logout(payload));
        if (data && data.code == 200) {
          await Swal.fire({
            title: 'Success',
            text: `Logged out successfully`,
            icon: 'success',
            timer: '2500',
          });
          Navigate('/');
        } else {
          if (data.message) {
            Swal.fire({
              title: 'Error',
              text: data.message,
              icon: 'error',
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const menuItems = [
    {
      id: '01',
      name: 'Profile',
      icon: 'user',
    },
    {
      id: '02',
      name: 'Manage Account',
      icon: 'file-text',
    },
    {
      id: '03',
      name: 'Help & Support',
      icon: 'briefcase',
    },
  ];

  const [selectedMenu, setSelectedMenu] = useState('01');
  const [selectedTrainer, setSelectedTrainer] = useState({});

  const handleMenu = menu => {
    setSelectedMenu(menu);
  };
  console.log(selectedMenu);

  let userDetail = useSelector(state => state.AuthDetails.user);

  return (
    <section className="container mx-auto lg:pt-20 md:pt-8 pt-8 lg:px-12 md:px-4 px-5">
      <div className="w-full md-w-90 mx-auto lg:flex-row md:flex-col flex-col flex  items-start  lg:py-12 md:py-8 relative ">
        <div className="w-30 md-w-100 sm-w-100 fixed-side bg-white br-20px lg:mr-6 md:mr-6">
          <div className="w-full ">
            <div className="w-full profile-img">
              <Image
                src={`${process.env.REACT_APP_STORAGE_URL}${
                  userDetail && userDetail.userImage
                }`}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                alt="profile"
                className="bg-primary  w-full h-full object-cover"
              />
            </div>
            <div className="mt-3 text-center">
              <h3 className="fsize20 font-medium">
                {`${userDetail ? userDetail.firstName : ''} ${
                  userDetail ? userDetail.lastName : ''
                }`}
              </h3>
              <div className="mt-1 bg-f3f6f6 rounded-sm inline-block lg:px-3 md:px-3 px-2 lg:py-1 md:py-1 py-1">
                <p className="fsize14 font-medium">
                  {' '}
                  {userDetail.location ? selectedTrainer.location : 'India'}
                </p>
              </div>
              <div className="flex justify-center gap-3 py-4">
                <FaFacebookF className="social-media-icon pad-10px bg-f3f6f6" />
                <FaTwitter className="social-media-icon pad-10px bg-f3f6f6" />
                <FaInstagram className="social-media-icon pad-10px bg-f3f6f6" />
                <FaLinkedinIn className="social-media-icon pad-10px bg-f3f6f6" />
              </div>
            </div>
            <div className="mt-3 bg-f3f6f6 lg:py-4 md:py-3 py-2 lg:px-6 md:px-4 px-2 rounded-lg">
              <div className="flex w-full gap-2 py-3 items-center ">
                <FaMobileAlt className="social-icon pad-10px" />
                <div className="">
                  <p className="fsize12">Phone</p>
                  <a href="tel: 1234567890">
                    <p className="font-medium fsize16">{userDetail.mobile}</p>
                  </a>
                </div>
              </div>
              <div className="bottom-border-e3e3e3"></div>
              <div className="flex w-full gap-2 py-3 items-center ">
                <FiMail className="social-icon pad-10px" />
                <div className="">
                  <p className="fsize12">Email</p>
                  <a href="mailto:example@ail.com">
                    <p className="font-medium fsize16">{userDetail.email}</p>
                  </a>
                </div>
              </div>
              <div className="bottom-border-e3e3e3"></div>
              <div className="flex w-full gap-2 py-3 items-center ">
                <FaMapMarkerAlt className="social-icon pad-10px" />
                <div className="">
                  <p className="fsize12">Location</p>
                  <p className="font-medium fsize16">
                    {userDetail.location ? selectedTrainer.location : 'India'}
                  </p>
                </div>
              </div>
              <div className="bottom-border-e3e3e3"></div>
              <div className="flex w-full gap-2 py-3 items-center ">
                <FaCalendarAlt className="social-icon pad-10px" />
                <div className="">
                  <p className="fsize12">Birthday</p>
                  <p className="font-medium fsize16">
                    {new Date(userDetail.dob).toDateString()}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6 flex justify-center">
              <Button className="btn-2" onClick={handleLogout}>
                <FiLogOut className="mr-1" /> Logout
              </Button>
            </div>
          </div>
        </div>
        <div className="w-70 md-w-100 minwidth-70 sm-w-100">
          <div className="w-full  lg:flex md:flex justify-end lg:mt-0 md:mt-6 mt-6">
            <div className="sm-overflow-x flex gap-4  rounded-lg justify-center w-auto bg-fff p-4">
              {menuItems.map((menu, index) => (
                <div
                  key={index}
                  onClick={() => handleMenu(menu.id)}
                  className={
                    selectedMenu === menu.id
                      ? 'tab-title-active1 flex flex-col justify-center items-center cursor-pointer'
                      : 'tab-title1 flex flex-col justify-center items-center cursor-pointer'
                  }
                >
                  <FeatherIcon
                    icon={menu.icon}
                    className="w-1/2 mx-auto fsize24 mb-2"
                  />
                  <p className="fsize12 text-center leading-none">
                    {menu.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {selectedMenu === '01' && (
            <div className="w-full minheight-800 bg-white br-20px my-6 lg:px-6 lg:py-8 md:px-8 md:py-6 p-5">
              <UserProfilePage1 />
            </div>
          )}
          {selectedMenu === '02' && (
            <div className="w-full minheight-800 bg-white br-20px my-6 lg:px-6 lg:py-8 md:px-8 md:py-6 p-5">
              <ManageAccount1 />
            </div>
          )}
          {selectedMenu === '03' && (
            <div className="w-full minheight-800 bg-white br-20px my-6 lg:px-6 lg:py-8 md:px-8 md:py-6 p-5">
              <HelpSupport1 />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Profile3;
