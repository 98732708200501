import React from 'react';
import { BiSupport } from 'react-icons/bi';
import { AiOutlineInstagram } from 'react-icons/ai';
import { FiFacebook } from 'react-icons/fi';
import { FaEnvelope, FaYoutube, FaLinkedinIn } from 'react-icons/fa';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';

const Footer8 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  let brandProfile = useSelector(state => state.brandDetails.brandProfile);
  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }
  return (
    <section>
      <div className="container mx-auto lg:py-12 md:py-8 py-8 px-4 lg:px-12 md:px-10 px-5">
        <div className="lg:flex md:flex lg:gap-8 md:gap-8">
          <div className="lg:w-3/12 md:w-1/4 w-full">
            <Image
              src={getSecondaryImage(brandProfile)}
              alt="footer"
              className="cursor-pointer h-8 sm-mx-auto"
            />

            <p
              id="6019407634"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['6019407634']
                  : 'publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
              className="dynamicStyle text-626262 lg:mt-8 mt-4 sm-fsize14 lg:leading-8 leading-6"
            ></p>
          </div>
          <div className="lg:w-9/12 md:w-3/4 w-full mt-4 lg:mt-0 lg:pl-4 md:pl-4">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
              <div>
                <p
                  id="0234401955"
                  className="dynamicStyle  text-base font-semibold"
                >
                  {data ? data['0234401955'] : 'Lorem ipsum'}
                </p>
                <ul className="lg:pt-4 pt-2 lg:leading-8 leading-6">
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    The Foundation
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Chairman Message
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Board of Directors{' '}
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Key Management Team{' '}
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Contact Us{' '}
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Careers{' '}
                  </li>
                </ul>
              </div>

              <div>
                <p
                  id="8376217951"
                  className="dynamicStyle  text-base font-semibold"
                >
                  {data ? data['8376217951'] : 'Lorem ipsum'}
                </p>
                <ul className="lg:pt-4 pt-2 lg:leading-8 leading-6">
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Gram Seva
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Youth For India Fellowship
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Jivanam
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    ILM
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    LEAP
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    CONSERW{' '}
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Ace{' '}
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Centre of Excellence for PwD{' '}
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Women Empowerment{' '}
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    SBIF Asha Scholarship{' '}
                  </li>
                </ul>
              </div>

              <div>
                {' '}
                <p
                  id="2799314745"
                  className="dynamicStyle  text-base font-semibold"
                >
                  {data ? data['2799314745'] : 'Lorem ipsum'}
                </p>
                <ul className="lg:pt-4 pt-2 lg:leading-8 leading-6">
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Documentaries
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Events
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Reports
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    News Coverage
                  </li>
                  <li className="text-959595 sm-fsize14 cursor-pointer list-notdot">
                    Request for proposal
                  </li>
                </ul>
              </div>
              <div>
                <p
                  id="1756401997"
                  className="dynamicStyle  text-base font-semibold"
                >
                  {data ? data['1756401997'] : 'Lorem ipsum'}
                </p>
                <div className="lg:pt-4 pt-2 leading-10">
                  <div className="text-959595 fsize14 sm-fsize14 cursor-pointer flex items-center gap-2">
                    <FaEnvelope
                      type="fill"
                      className="text-2xl  text-29166f w-2/12 font-bold cursor-pointer"
                    />
                    <p
                      id="6502003664"
                      className="dynamicStyle text-959595 fsize14 sm-fsize14 cursor-pointer w-10/12"
                    >
                      {data ? data['6502003664'] : 'Lorem ipsum'}
                    </p>
                  </div>
                  <div className="text-959595 fsize14 sm-fsize14 cursor-pointer flex items-center gap-2">
                    <BiSupport
                      type="fill"
                      className="text-2xl text-29166f w-2/12 font-bold cursor-pointer"
                    />
                    <p
                      id="7641324924"
                      className="dynamicStyle text-959595 fsize14 sm-fsize14 cursor-pointer w-10/12"
                    >
                      {data ? data['7641324924'] : '022-22151689'}
                    </p>
                  </div>
                  <div className="text-29166f cursor-pointer flex items-center gap-4 mt-3">
                    <AiOutlineInstagram
                      type="fill"
                      className="text-2xl text-29166f font-bold cursor-pointer"
                    />
                    <FiFacebook
                      type="fill"
                      className="text-2xl text-29166f font-bold cursor-pointer"
                    />
                    <FaYoutube
                      type="fill"
                      className="text-2xl text-29166f font-bold cursor-pointer"
                    />
                    <FaLinkedinIn
                      type="fill"
                      className="text-2xl text-29166f font-bold cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer8;
