import { Button, Image } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux/es/exports';

const CardImage7 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  return (
    <div className="container mx-auto lg:py-12 py-10 lg:px-12 md:px-8 px-4">
      <div className="text-center lg:w-1/2 md:w-2/3 w-full mx-auto lg:mb-12 md:mb-12 mb-6">
        <h3
          id="8831206802"
          className="dynamicStyle fsize36 sm-fsize20 font-semibold lg:mb-4 md:mb-4 cust-textColor"
        >
          {data ? data['8831206802'] : 'Lorem Ipsum'}
        </h3>
        <p
          id="5113732478"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['5113732478']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className="dynamicStyle fsize16 sm-fsize14 textColor-light"
        ></p>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
        <div class="image-block">
          <h2 className="text-hover fsize35 sm-fsize23">The Beach</h2>
          <Image
            src="https://nexuscluster.blob.core.windows.net/server01/brained-admin/marcombg.png"
            alt="blogcard"
            className="images-blog filter-b4 object-cover w-full"
          />
          <div className="figcap">
            <h3 className="text-white sm-fsize18 fsize20 font-semibold">
              More Info
            </h3>
            <p className="text-white sm-fsize13 fsize14 font-medium leading-para mt-3">
              Pellentesque habitant morbi tristique senectus et netus et
              malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat
              vitae, ultricies eget, tempor sit amet, ante.
            </p>
            <Button mt={3} className="bg-white fsize14 sm-fsize13 font-medium">
              Know More
            </Button>
          </div>
        </div>
        <div class="image-block">
          <h2 className="text-hover fsize35 sm-fsize23">The Beach</h2>
          <Image
            src="https://nexuscluster.blob.core.windows.net/server01/brained-admin/marcombg.png"
            alt="blogcard"
            className="images-blog filter-b4 object-cover w-full"
          />
          <div className="figcap">
            <h3 className="text-white sm-fsize18 fsize20 font-semibold">
              More Info
            </h3>
            <p className="text-white sm-fsize13 fsize14 font-medium leading-para mt-3">
              Pellentesque habitant morbi tristique senectus et netus et
              malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat
              vitae, ultricies eget, tempor sit amet, antes.
            </p>
            <Button mt={3} className="bg-white fsize14 sm-fsize13 font-medium">
              Know More
            </Button>
          </div>
        </div>
        <div class="image-block">
          <h2 className="text-hover fsize35 sm-fsize23">The Beach</h2>
          <Image
            src="https://nexuscluster.blob.core.windows.net/server01/brained-admin/marcombg.png"
            alt="blogcard"
            className="images-blog filter-b4 object-cover w-full"
          />
          <div className="figcap">
            <h3 className="text-white sm-fsize18 fsize20 font-semibold">
              More Info
            </h3>
            <p className="text-white sm-fsize13 fsize14 font-medium leading-para mt-3">
              Pellentesque habitant morbi tristique senectus et netus et
              malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat
              vitae, ultricies eget, tempor sit amet, ante.
            </p>
            <Button mt={3} className="bg-white fsize14 sm-fsize13 font-medium">
              Know More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardImage7;
