import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Text, Image, Box, useColorMode } from '@chakra-ui/react';

const HeroSection9 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    Aos.init();
  });
  return (
    <section
      className={
        colorMode === 'light'
          ? 'bg-primary relative h-580px md-h-450'
          : 'cust-bgColor relative h-580px md-h-450'
      }
    >
      {/* <img
        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
        alt="hero"
        className="w-full h-580px sm-h-auto object-cover bg-a2a2a2 "
      /> */}

      <div className="absolute top-0 w-full h-580px md-h-450 sm-h-auto lg:flex md:flex  flex justify-center   h-580px sm-h-auto lg:px-0 md:px-0 px-6 lg:py-0 md:py-0 py-4">
        <div
          className=" w-full  flex flex-col justify-center items-center"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <h1
            id="7362576958"
            className="dynamicStyle hero-header text-center lg:mb-8 md:mb-6 mb-2 clr-fff cust-textColor"
          >
            {data ? data['7362576958'] : 'Lorem ipsum generated'}
          </h1>
          <p
            id="1833731917"
            className="dynamicStyle fsize20 sm-fsize16 clr-fff textColor-light"
          >
            {data ? data['1833731917'] : 'Lorem ipsum generated'}
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection9;
