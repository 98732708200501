import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Image } from '@chakra-ui/react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';

const Clients3 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const Client = [
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className="container m-auto lg:py-16 md:py-16 py-12 lg:px-12 md:px-8 px-5">
      <h2
        id="5369058283"
        className="dynamicStyle  fsize30 sm-fsize24 text-center font-semibold"
      >
        {data ? data['5369058283'] : ' Our Clients'}
      </h2>
      <p
        id="5186270664"
        className="dynamicStyle  sm-fsize14 text-center"
        dangerouslySetInnerHTML={{
          __html: data
            ? data['5186270664']
            : 'We are united by our passion for innovation and our commitment',
        }}
      ></p>
      <div className="lg:pt-12 md:pt-12 pt-8">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 15,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 15,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prevz bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-nextz bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>

          <SwiperSlide id="2354986862" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['2354986862']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-md  m-auto object-cover"
            />
          </SwiperSlide>
          <SwiperSlide id="6880204301" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['6880204301']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-md  m-auto object-cover"
            />
          </SwiperSlide>
          <SwiperSlide id="4195950455" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['4195950455']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-md  m-auto object-cover"
            />
          </SwiperSlide>
          <SwiperSlide id="5031819818" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['5031819818']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-md  m-auto object-cover"
            />
          </SwiperSlide>
          <SwiperSlide id="2745207523" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['2745207523']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-md  m-auto object-cover"
            />
          </SwiperSlide>
          <SwiperSlide id="2798033711" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['2798033711']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-md  m-auto object-cover"
            />
          </SwiperSlide>
          <SwiperSlide id="4743599342" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['4743599342']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-md  m-auto object-cover"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Clients3;
