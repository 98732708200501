import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';

import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Team = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section className="container mx-auto  lg:py-16 md:py-16 py-8 lg:px-12 md:px-12 px-5">
      <div className="lg:flex items-center gap-4 lg:mb-10 md:mb-10 mb-6 lg:px-0 md:px-0 ">
        <div className="lg:w-1/2">
          <h1
            id="6748018147"
            className="fsize36 cust-textColor text-primary md-fsize28 font-semibold sm-fsize20 dynamicStyle lg:pr-24 md:pr-16"
          >
            {data ? data['6748018147'] : ' Meet Aur Professional teams'}
          </h1>
        </div>
        <div className="lg:w-1/2 lg:px-6">
          <p
            id="7139783053"
            dangerouslySetInnerHTML={{
              __html: data ? data['7139783053'] : 'Lorem ipsum dolor',
            }}
            className="fsize16 sm-fsize14 textColor-light dynamicStyle"
          >
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            cursus consectetur euismod aenean. */}
          </p>
        </div>
      </div>

      <Swiper
        spaceBetween={30}
        observer={true}
        observeParents={true}
        modules={[Navigation]}
        className="mySwiper container relative overflow-x-hidden
         lg:px-0 md:px-0"
        autoplay={{
          delay: '1000',
        }}
        navigation={{
          nextEl: '.image-swiper-button-next',
          prevEl: '.image-swiper-button-prev',
        }}
        breakpoints={{
          1536: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          991: {
            slidesPerView: 2.2,
            spaceBetween: 25,
          },
          640: {
            slidesPerView: 2.2,
            spaceBetween: 25,
          },
          425: {
            slidesPerView: 1.2,
            spaceBetween: 25,
          },
          325: {
            slidesPerView: 1.2,
            spaceBetween: 25,
          },
        }}
      >
        <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </div>
        <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
          </svg>
        </div>
        <SwiperSlide>
          <Card
            className={
              colorMode === 'light'
                ? 'border p-2 w-full rounded-lg'
                : 'p-2 w-full rounded-lg'
            }
          >
            <div
              id="7376227919"
              className="dynamicStyle2 overflow-hidden w-full"
            >
              <Image
                src={data ? getImage(data['7376227919']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="teams-card"
                className="h-200px zoom w-full object-contain rounded-lg lg:mb-6"
              />
            </div>

            <p
              id="4977252942"
              className="dynamicStyle cust-textColor fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2"
            >
              {data ? data['4977252942'] : 'Lorem Ipsum'}
            </p>
            <p id="9039379059" className="dynamicStyle textColor-light fsize14 text-center">
              {data ? data['9039379059'] : 'Lorem Ipsum'}
            </p>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            className={
              colorMode === 'light'
                ? 'border p-2 w-full rounded-lg'
                : 'p-2 w-full rounded-lg'
            }
          >
            <div
              id="6105228309"
              className="dynamicStyle2 overflow-hidden w-full"
            >
              <Image
                src={data ? getImage(data['6105228309']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="teams-card"
                className="h-200px zoom w-full object-contain rounded-lg lg:mb-6"
              />
            </div>

            <p
              id="6264793908"
              className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 cust-textColor"
            >
              {data ? data['6264793908'] : 'Lorem Ipsum'}
            </p>
            <p id="7271709472" className="dynamicStyle fsize14 text-center textColor-light ">
              {data ? data['7271709472'] : 'Lorem Ipsum'}
            </p>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            className={
              colorMode === 'light'
                ? 'border p-2 w-full rounded-lg'
                : 'p-2 w-full rounded-lg'
            }
          >
            <div
              id="4238465307"
              className="dynamicStyle2 overflow-hidden w-full"
            >
              <Image
                src={data ? getImage(data['4238465307']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="teams-card"
                className="h-200px zoom w-full object-contain rounded-lg lg:mb-6"
              />
            </div>

            <p
              id="1785312991"
              className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 cust-textColor"
            >
              {data ? data['1785312991'] : 'Lorem Ipsum'}
            </p>
            <p id="8323636113" className="dynamicStyle fsize14 text-center textColor-light ">
              {data ? data['8323636113'] : 'Lorem Ipsum'}
            </p>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            className={
              colorMode === 'light'
                ? 'border p-2 w-full rounded-lg'
                : 'p-2 w-full rounded-lg'
            }
          >
            <div
              id="4309546110"
              className="dynamicStyle2 overflow-hidden w-full"
            >
              <Image
                src={data ? getImage(data['4309546110']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="teams-card"
                className="h-200px zoom w-full object-contain rounded-lg lg:mb-6"
              />
            </div>

            <p
              id="6832579192"
              className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 cust-textColor"
            >
              {data ? data['6832579192'] : 'Lorem Ipsum'}
            </p>
            <p id="6875339786" className="dynamicStyle fsize14 text-center textColor-light ">
              {data ? data['6875339786'] : 'Lorem Ipsum'}
            </p>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            className={
              colorMode === 'light'
                ? 'border p-2 w-full rounded-lg'
                : 'p-2 w-full rounded-lg'
            }
          >
            <div
              id="1161391849"
              className="dynamicStyle2 overflow-hidden w-full"
            >
              <Image
                src={data ? getImage(data['1161391849']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="teams-card"
                className="h-200px zoom w-full object-contain rounded-lg lg:mb-6"
              />
            </div>

            <p
              id="8559802176"
              className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 cust-textColor"
            >
              {data ? data['8559802176'] : 'Lorem Ipsum'}
            </p>
            <p id="0674178291" className="dynamicStyle fsize14 text-center textColor-light ">
              {data ? data['0674178291'] : 'Lorem Ipsum'}
            </p>
          </Card>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Team;
