import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Founders2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="lg:py-0 md:py-0 py-6">
      <section className="md:py-8 py-4 lg:py-20">
        <div className="container mx-auto lg:px-12 md:px-10 px-4">
          <div className="cust-reverse flex items-center">
            <div
              className="lg:w-6/12 md:w-6/12 w-full lg:mt-0 mt-4 lg:pr-10"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <h5
                id="2955068673"
                className="dynamicStyle fsize18 sm-fsize16 text-secondary underline font-semibold"
              >
                {data ? data['2955068673'] : 'SBI Foundation'}
              </h5>
              <p
                id="2202298290"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['2202298290']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle fsize16 sm-fsize14 col-79 mt-3"
              ></p>
              {/* <ul className="mt-5">
                  <li className="list-dot fsize16 sm-fsize14 col-79">
                    Improving the socio-economic well-being of the society,
                    particularly of the less fortunate and underprivileged
                    members and enable them to live up to the potential that
                    they all possess.
                  </li>
                  <li className="list-dot fsize16 sm-fsize14 mt-2 col-79">
                    Creating an inclusive development paradigm that serves all
                    Indians and to deliver societal benefits to all geographical
                    regions of the country without any regional, linguistic,
                    caste, creed, religious or other barriers.
                  </li>
                </ul> */}
            </div>
            <div
              id="9139491074"
              className="dynamicStyle2 lg:w-6/12 md:w-6/12 w-full lg:pl-10"
            >
              <Image
                src={data ? getImage(data['9139491074']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="foundation-img object-cover"
                alt="foundation"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="md:py-8 py-4 lg:py-20">
        <div className="container mx-auto lg:px-12 md:px-10 px-4">
          <div className="lg:flex md:flex items-center">
            <div
              id="4343839496"
              className="dynamicStyle2 lg:w-6/12 md:w-6/12 lg:pr-10"
            >
              <Image
                src={data ? getImage(data['4343839496']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="vision-img object-cover"
                alt="vision"
              />
            </div>
            <div
              className="lg:w-6/12 md:w-6/12 lg:mt-0 mt-4 lg:pl-10 md:pl-4"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <h5
                id="8631068767"
                className="dynamicStyle fsize18 sm-fsize16 text-secondary underline font-semibold"
              >
                {data ? data['8631068767'] : 'Our Vision'}
              </h5>
              <p
                id="6209354543"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['6209354543']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle fsize16 sm-fsize14 col-79 mt-3"
              ></p>
              {/* <ul className="mt-5">
                  <li className="list-dot fsize16 sm-fsize14 col-79">
                    Improving the socio-economic well-being of the society,
                    particularly of the less fortunate and underprivileged
                    members and enable them to live up to the potential that
                    they all possess.
                  </li>
                  <li className="list-dot fsize16 sm-fsize14 mt-2 col-79">
                    Creating an inclusive development paradigm that serves all
                    Indians and to deliver societal benefits to all geographical
                    regions of the country without any regional, linguistic,
                    caste, creed, religious or other barriers.
                  </li>
                </ul> */}
            </div>
          </div>
        </div>
      </section>
      <section className="md:py-8 py-4 lg:py-20">
        <div className="container mx-auto lg:px-12 md:px-10 px-4">
          <div className="flex cust-reverse items-center">
            <div
              className="lg:w-6/12 md:w-6/12 w-full lg:mt-0 mt-4 lg:pr-10"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <h5
                id="2731093684"
                className="dynamicStyle fsize18 sm-fsize16 text-secondary underline font-semibold"
              >
                {data ? data['2731093684'] : 'Our Mission'}
              </h5>
              <p
                id="0645816329"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['0645816329']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle fsize16 sm-fsize14 col-79 mt-3"
              ></p>
              {/* <ul className="mt-5">
                  <li className="list-dot fsize16 sm-fsize14 col-79">
                    Improving the socio-economic well-being of the society,
                    particularly of the less fortunate and underprivileged
                    members and enable them to live up to the potential that
                    they all possess.
                  </li>
                  <li className="list-dot fsize16 sm-fsize14 mt-2 col-79">
                    Creating an inclusive development paradigm that serves all
                    Indians and to deliver societal benefits to all geographical
                    regions of the country without any regional, linguistic,
                    caste, creed, religious or other barriers.
                  </li>
                </ul> */}
            </div>
            <div
              id="6371957523"
              className="dynamicStyle2 lg:w-6/12 md:w-6/12 w-full lg:pl-10"
            >
              <Image
                src={data ? getImage(data['6371957523']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="mission-img object-cover"
                alt="mission"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Founders2;
