import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Image, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';

const AboutSection3 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section>
      {' '}
      <div className="lg:flex md:flex container mx-auto  sm-reverse  lg:py-16 md:py-16 lg:px-12 md:px-6 px-5">
        <div
          className="lg:w-1/2 md:w-1/2 lg:py-12 md:py-0 py-8 relative zindex9 flex flex-col justify-center"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <h3
            id="2551488587"
            className="dynamicStyle fsize24 font-semibold clr-ddd9d8 lg:mb-2"
          >
            {data ? data['2551488587'] : 'Lorem Ipsum'}
          </h3>
          <h2
            id="3949541632"
            className="dynamicStyle lg:text-4xl md:text-2xl text-xl  font-bold mt-2 lg:leading-12 md:leading-12 heading-lh leading-6"
          >
            {data ? data['3949541632'] : 'Lorem Ipsum'}
          </h2>
          <p
            id="1829268878"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['1829268878']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle lg:text-base md:text-base text-sm lg:mt-4 md:mt-4 mt-2 textColor-light lg:leading-7 md:leading-7 leading-6"
          ></p>

          <Button
            width="180px"
            height="50px"
            className="bg-primary lg:mt-8 md:mt-8 zindex9 relative mt-6 textwhite sm-btntextwh dynamicStyle"
            id="7567082298"
          >
            <span onClick={() => handleClick('7567082298')}>
            {data ? data['7567082298'] : 'Lorem Ipsum'}
            </span>
          </Button>
        </div>
        <div className="lg:w-1/2 md:w-1/2 lg:pl-8 md:pl-8  lg:mt-0 md:mt-0 mt-12">
          <div
            id="0993103317"
            className="dynamicStyle2 w-full relative zindex9"
          >
            <Image
              src={data ? getImage(data['0993103317']) : ''}
              alt="heroImage"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              className="w-full sm-h250px rounded-2xl minh450px object-contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection3;
