import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Card, Image } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';
import Rating from 'react-rating';
import FeatherIcon from 'feather-icons-react';

const Testimonial2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  console.log(getAllTestimonial, 'getAllTestimonial');
  useEffect(() => {
    dispatch(getTestimonal());
    Aos.init();
  }, []);
  return (
    <>
      {getAllTestimonial ? (
        <section className="py-5">
          <div className="text-center container mx-auto flex flex-col justify-center lg:py-8 lg:mb-16 md:mb-10 mb-6 lg:px-12 md:px-12 px-5">
            <h3
              id="3637674290"
              className="dynamicStyle letter-spacing4px fsize24 md-fsize22 sm-fsize16 font-semibold clr-ddd9d8 "
            >
              {data ? data['3637674290'] : 'Lorem Ipsum'}
            </h3>
            <h1
              id="2333017546"
              className="dynamicStyle fsize36 md-fsize28 sm-fsize20 font-semibold cust-textColor mb-2"
            >
              {data ? data['2333017546'] : 'Lorem Ipsum'}
            </h1>
            <div className="">
              <p
                id="6834492840"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['6834492840']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle textColor-light fsize16 md-fsize14 sm-fsize14 text-center lg:px-0 md:px-16 px-0"
              ></p>
            </div>
          </div>

          <div className="container mx-auto grid lg:grid-cols-2 md:grid-cols-2 mt-5 w-full lg:gap-4 md:gap-4 gap-3 lg:px-12 md:px-12 px-5">
            {getAllTestimonial.data &&
            getAllTestimonial.data.result &&
            getAllTestimonial.data.result.length > 0
              ? getAllTestimonial.data.result.map(testimonial => {
                  return (
                    <div
                      className="testimonial-card border rounded-lg"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      <div className="w-full lg:text-left md:text-left text-center flex">
                        <div className="testimonial-div1 ">
                          <Image
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                            alt="testimonial2"
                            className="testimonial-img rounded-full object-cover"
                          />
                        </div>
                        <div className="w-full text-left lg:mt-0 md:mt-0 mt-2">
                          {/* <h2 className="fsize24 sm-fsize16 font-semibold ">
                            {`${
                              testimonial.firstName.charAt(0).toUpperCase() +
                              testimonial.firstName.slice(1)
                            }` +
                              "\n" +
                              `${testimonial.lastName}`}
                          </h2> */}
                          <h2 className="fsize20 sm-fsize16 font-semibold cust-textColor">
                            {testimonial.name.charAt(0).toUpperCase() +
                              testimonial.name.slice(1)}
                          </h2>
                          <div className="flex justify-between items-center ">
                            {/* <p className="fsize18 sm-fsize14">
                              CEO & Co Founder
                            </p> */}
                            <Rating
                              initialRating={4}
                              readonly={true}
                              emptySymbol={
                                <FeatherIcon
                                  icon="star"
                                  className="mr-1"
                                  size={22}
                                />
                              }
                              fullSymbol={
                                <FeatherIcon
                                  icon="star"
                                  className="mr-1 fillstroke"
                                  size={22}
                                />
                              }
                              fractions={2}
                            />
                          </div>
                        </div>
                      </div>
                      <p className="lg:text-left md:text-justify text-center fsize16 sm-fsize14 lg:pt-4 md:pt-4 pt-2 lg:pb-4 md:pb-4 textColor-light">
                        {testimonial.testimonial}
                      </p>
                    </div>
                  );
                })
              : 'No Data'}
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

export default Testimonial2;
