import { Image } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { getAllBlogs } from '../../../../redux/blogs/blogs-actions';

const Blogs = () => {
  const AllBlogsCategory = useSelector(state => state.portfolio.Category);
  console.log(AllBlogsCategory, 'AllBlogsCategory');

  // const [subCategoryData, setSubCategoryData] = useState([]);

  // const finalAllData = () => {
  //   let finalarray = [];
  //   AllBlogs.result.map(category => {
  //     category.subcategoryId.map(subCat => {
  //       let data = {
  //         id: subCat.adminId,
  //         CatName: category.name,
  //         CatImage: category.image,
  //         subCatName: subCat.name,
  //         subCatImage: subCat.image,
  //         subCatDate: subCat.createdAt,
  //       };
  //       finalarray.push(data);
  //     });
  //   });
  //   console.log(finalarray, 'finalarray');
  //   if (finalarray.length > 0) {
  //     setSubCategoryData(finalarray);
  //   } else {
  //     setSubCategoryData([]);
  //   }
  // }
  // useEffect(() => {
  //   finalAllData()
  // }, []);

 

  return (
    <>
      <div className="lg:px-0 md:px-0 px-5 lg:py-0 md:py-0 py-8 ">
        <div className="flex">
          <h2 className="fsize32 sm-fsize24 font-semibold">Blogs</h2>
        </div>

        <div className="mt-3">
          <div className="lg:mt-8 md:mt-6 mt-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 w-full gap-4">
            {AllBlogsCategory &&
            AllBlogsCategory.result && 
              AllBlogsCategory.result.length > 0 &&
              AllBlogsCategory.result.reverse().map(category => (
                <div className="bg-f3f6f6 lg:p-5 md:p-4 p-3 br-12px">
                  <Image
                    src={process.env.REACT_APP_STORAGE_URL + category.image}
                    alt="blog-image"
                    className="w-full h-200px object-cover br-12px"
                  />
                  <div className="mt-3">
                    <div className="flex items-center gap-2">
                      <p className="fsize12">
                        {new Date(category.createdAt).toDateString()}
                      </p>
                      <p className="black-dot"></p>
                      <p className="fsize12">{category.name}</p>
                    </div>
                    <h2 className="fsize16 font-medium">{category.name}</h2>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
