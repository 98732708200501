import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const VideoDouments1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section className="container mx-auto lg:px-12 md:px-12 px-5 lg:py-12 md:py-12 py-5">
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4">
        <div className="cursor-pointer" data-aos="zoom-in" data-aos-once="true">
          <div id="8818089340" className="dynamicStyle2  w-full">
            <Image
              src={data ? getImage(data['8818089340']) : ''}
              className="w-full h-52 rounded-lg"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>

          <p className="text-primary mt-4 dynamicStyle" id="2051326327">
            {data ? data['2051326327'] : 'Lorem Ipsum'}
          </p>
        </div>
        <div className="cursor-pointer" data-aos="zoom-in" data-aos-once="true">
          <div id="2445260085" className="dynamicStyle2 w-full">
            <Image
              src={data ? getImage(data['2445260085']) : ''}
              className="w-full h-52 rounded-lg"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <p className="text-primary mt-4 dynamicStyle" id="5225662905">
            {data ? data['5225662905'] : 'Lorem Ipsum'}
          </p>
        </div>

        <div className="cursor-pointer" data-aos="zoom-in" data-aos-once="true">
          <div id="0457187647" className="dynamicStyle2  w-full">
            <Image
              src={data ? getImage(data['0457187647']) : ''}
              className="w-full h-52 rounded-lg"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <p className="text-primary mt-4 dynamicStyle" id="6617936691">
            {data ? data['6617936691'] : 'Lorem Ipsum'}
          </p>
        </div>

        <div className="cursor-pointer" data-aos="zoom-in" data-aos-once="true">
          <div id="0966523638" className="dynamicStyle2 w-full">
            <Image
              src={data ? getImage(data['0966523638']) : ''}
              className="w-full h-52 rounded-lg"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <p className="text-primary mt-4 dynamicStyle" id="8304605844">
            {data ? data['8304605844'] : 'Lorem Ipsum'}
          </p>
        </div>

        <div className="cursor-pointer" data-aos="zoom-in" data-aos-once="true">
          <div id="2334256267" className="dynamicStyle2 w-full">
            <Image
              src={data ? getImage(data['2334256267']) : ''}
              className="w-full h-52 rounded-lg"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <p className="text-primary mt-4 dynamicStyle" id="5111532405">
            {data ? data['5111532405'] : 'Lorem Ipsum'}
          </p>
        </div>
      </div>
    </section>
  );
};

export default VideoDouments1;
