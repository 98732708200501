const initialState = {
  elearningCategory: [],
  Course: [],
};
const elearningProduct = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_CATEGORY':
      return { ...state, elearningCategory: action.payload };

    case 'SET_ALL_COURSE':
      return { ...state, Course: action.payload };

    default:
      return state;
  }
};

export default elearningProduct;
