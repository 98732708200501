import { Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';

const AddBanner2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="relative application-h">
      <div className="dynamicStyle2 w-full" id="6704948690">
        <Image
          src={data ? getImage(data['6704948690']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          className="w-full application-h"
          alt="application"
        />
      </div>

      <div className="absolute top-0 left-0 flex items-center application-h w-full">
        <div className="container mx-auto lg:px-12 md:px-12 px-4">
          <div className="lg:w-5/12 " data-aos="fade-up" data-aos-once="true">
            <small
              className="fsize18 sm-fsize15 text-left text-secondary dynamicStyle"
              id="7698708597"
            >
              {data ? data['7698708597'] : 'Lorem Ipsum'}
            </small>
            <h3
              className="fsize38 sm-fsize20 font-semibold text-white text-left dynamicStyle"
              id="3856192915"
            >
              {data ? data['3856192915'] : 'Lorem Ipsum'}
            </h3>
            <p
              className="fsize19 sm-fsize14 text-left mt-2 text-white dynamicStyle"
              id="1405252807"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['1405252807']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
            ></p>

            <Button
              colorScheme="white"
              className="mt-6 bg-primary sm-fsize14 dynamicStyle"
              size="md"
              id="2408499979"
            >
              {data ? data['2408499979'] : 'Lorem Ipsum'}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddBanner2;
