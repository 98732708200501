import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Team7 = ({ pageName }) => {
  const [check, SetCheck] = useState(1);
  // const memeber = [
  //   { id: 1, name: 'All' },
  //   { id: 2, name: 'Programing' },
  //   { id: 3, name: 'Development' },
  //   { id: 4, name: 'Design' },
  //   { id: 5, name: 'Application' },
  // ];
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className="lg:px-12 md:px-8 px-4 lg:py-20 md:py-16 py-8">
      <div className="container mx-auto">
        <h4
          className="fsize30 md-fsize28 sm-fsize20 cust-textColor font-semibold text-center dynamicStyle"
          id="5767189136"
        >
          {data ? data['5767189136'] : 'Meet Aur Professional teams'}
        </h4>
        <div className="lg:w-7/12 md:w-9/12 mx-auto">
          <p
            id="0315992699"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['0315992699']
                : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus',
            }}
            className="fsize17 sm-fsize14 font-medium text-center mt-2 dynamicStyle textColor-light"
          ></p>
        </div>
        <div className="lg:mt-8 md:mt-5 mt-5">
          <div className="mx-auto lg:w-9/12 md:w-8/12">
            <Swiper
              spaceBetween={25}
              className="mySwiper"
              breakpoints={{
                1536: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                820: {
                  slidesPerView: 2,
                  spaceBetween: 12,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 12,
                },
                640: {
                  slidesPerView: 1.9,
                  spaceBetween: 12,
                },
                425: {
                  slidesPerView: 1.9,
                  spaceBetween: 12,
                },
                320: {
                  slidesPerView: 1.9,
                  spaceBetween: 12,
                },
              }}
            >
              <SwiperSlide id={1} onClick={() => SetCheck(1)}>
                <p
                  id="8905937107"
                  className={
                    check === 1
                      ? 'fsize14 bg-primary cust-bgColor text-white py-2 px-2 text-center dynamicStyle'
                      : 'fsize14 border text-primary cust-textColor  py-2 px-2 text-center dynamicStyle'
                  }
                >
                  {data ? data['8905937107'] : 'Lorem Ipsum'}
                </p>
              </SwiperSlide>
              <SwiperSlide id={2} onClick={() => SetCheck(2)}>
                <p
                  id="8041254369"
                  className={
                    check === 2
                      ? 'fsize14 bg-primary cust-bgColor text-white py-2 px-2 text-center dynamicStyle'
                      : 'fsize14 border text-primary cust-textColor  py-2 px-2 text-center dynamicStyle'
                  }
                >
                  {data ? data['8041254369'] : 'Lorem Ipsum'}
                </p>
              </SwiperSlide>
              <SwiperSlide id={3} onClick={() => SetCheck(3)}>
                <p
                  id="1464829046"
                  className={
                    check === 3
                      ? 'fsize14 bg-primary cust-bgColor text-white py-2 px-2 text-center dynamicStyle'
                      : 'fsize14 border text-primary cust-textColor  py-2 px-2 text-center dynamicStyle'
                  }
                >
                  {data ? data['1464829046'] : 'Lorem Ipsum'}
                </p>
              </SwiperSlide>
              <SwiperSlide id={4} onClick={() => SetCheck(4)}>
                <p
                  id="4426583102"
                  className={
                    check === 4
                      ? 'fsize14 bg-primary cust-bgColor text-white py-2 px-2 text-center dynamicStyle'
                      : 'fsize14 border text-primary cust-textColor  py-2 px-2 text-center dynamicStyle'
                  }
                >
                  {data ? data['4426583102'] : 'Lorem Ipsum'}
                </p>
              </SwiperSlide>
              <SwiperSlide id={5} onClick={() => SetCheck(5)}>
                <p
                  id="6200413389"
                  className={
                    check === 5
                      ? 'fsize14 bg-primary  cust-bgColor text-white py-2 px-2 text-center dynamicStyle'
                      : 'fsize14 border text-primary cust-textColor  py-2 px-2 text-center dynamicStyle'
                  }
                >
                  {data ? data['6200413389'] : 'Lorem Ipsum'}
                </p>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="mt-12 lg:w-11/12 mx-auto">
            {check === 1 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="9733816070"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['9733816070']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="1129893720"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['1129893720'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="2629928464"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['2629928464'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="5113740175"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['5113740175']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="8970537348"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['8970537348'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="0020573504"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['0020573504'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="0212706841"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['0212706841']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="1717815709"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['1717815709'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="0766557480"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['0766557480'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="2571265817"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['2571265817']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="5151600389"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['5151600389'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="1124510554"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['1124510554'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                </div>
              </>
            ) : (
              ''
            )}
            {check === 2 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="5991889129"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['5991889129']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="5786168519"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['5786168519'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="6715432870"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['6715432870'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="8719128001"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['8719128001']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="0709044585"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['0709044585'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="6976060610"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['6976060610'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="9811629931"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['9811629931']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="5255662831"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['5255662831'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="0654123155"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['0654123155'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="0536755708"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['0536755708']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="4033770144"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['4033770144'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="5217289981"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['5217289981'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                </div>
              </>
            ) : (
              ''
            )}
            {check === 3 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="4010871371"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['4010871371']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="2640362674"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['2640362674'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="1531071614"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['1531071614'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="0486816678"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['0486816678']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      {' '}
                      <h2
                        id="3352869413"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['3352869413'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="1030908440"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['1030908440'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="9601570126"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['9601570126']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="9774904231"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['9774904231'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="2858278229"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['2858278229'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="7336981245"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['7336981245']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      {' '}
                      <h2
                        id="0322158632"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['0322158632'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="7270218148"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['7270218148'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                </div>
              </>
            ) : (
              ''
            )}
            {check === 4 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="3821284668"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['3821284668']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      {' '}
                      <h2
                        id="1068717975"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['1068717975'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="5744260985"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['5744260985'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="3459682564"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['3459682564']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="7071219400"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['7071219400'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="4312268337"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['4312268337'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="5539053090"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['5539053090']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="3469512801"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['3469512801'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="3095270148"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['3095270148'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="5312391995"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['5312391995']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="2930215174"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['2930215174'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="7579182032"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['7579182032'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                </div>
              </>
            ) : (
              ''
            )}
            {check === 5 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="2473922200"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['2473922200']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="9828918100"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['9828918100'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="8055855294"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['8055855294'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="2208346238"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['2208346238']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="0459310249"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['0459310249'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="8428025464"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['8428025464'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="8341957078"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['8341957078']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="8723912685"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['8723912685'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="0355926785"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['0355926785'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                  <Card
                    className="w-full rounded-lg"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <div
                      id="8064822550"
                      className="dynamicStyle2 flex justify-center w-full"
                    >
                      <Image
                        src={data ? getImage(data['8064822550']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover bg-d2d2d2 css-0"
                      />
                    </div>
                    <div className="p-2">
                      <h2
                        id="9468158182"
                        className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:mb-1 cust-textColor"
                      >
                        {data ? data['9468158182'] : 'Lorem Ipsum'}
                      </h2>
                      <p
                        id="3769516649"
                        className="dynamicStyle fsize15 sm-fsize14 textColor-light cust-textColor"
                      >
                        {data ? data['3769516649'] : 'Lorem Ipsum'}
                      </p>
                    </div>
                  </Card>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team7;
