import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';

const Teams2 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section>
      <div className="container mx-auto lg:flex md:flex block items-center ">
        <div className="w-40 sm-wfull lg:px-5 md:px-6 px-5">
          <Swiper
            spaceBetween={30}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper container relative overflow-x-hidden"
            autoplay={{
              delay: '1000',
            }}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            breakpoints={{
              1536: {
                slidesPerView: 1.5,
                spaceBetween: 60,
              },
              1280: {
                slidesPerView: 1.5,
                spaceBetween: 60,
              },
              1024: {
                slidesPerView: 1.5,
                spaceBetween: 60,
              },
              991: {
                slidesPerView: 1.5,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <Card
              className={
                colorMode === 'light'
                  ? 'border p-2 w-full rounded-lg'
                  : 'p-2 w-full rounded-lg'
              }>
                <div
                  id="9859104539"
                  className="dynamicStyle2 overflow-hidden w-full"
                >
                  <Image
                    src={data ? getImage(data['9859104539']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="teams-card"
                    className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
                  />
                </div>

                <p
                  id="0822100661"
                  className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 cust-textColor lg:mt-0 md:mt-0 mt-2"
                >
                  {data ? data['0822100661'] : 'Lorem Ipsum'}
                </p>
                <p id="5532840884" className="dynamicStyle fsize14 text-center textColor-light">
                  {data ? data['5532840884'] : 'Lorem Ipsum'}
                </p>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className={
                colorMode === 'light'
                  ? 'border p-2 w-full rounded-lg'
                  : 'p-2 w-full rounded-lg'
              }>
                <div
                  id="0365429868"
                  className="dynamicStyle2 overflow-hidden w-full"
                >
                  <Image
                    src={data ? getImage(data['0365429868']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="teams-card"
                    className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
                  />
                </div>

                <p
                  id="8717943149"
                  className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 cust-textColor"
                >
                  {data ? data['8717943149'] : 'Lorem Ipsum'}
                </p>
                <p id="5858045913" className="dynamicStyle fsize14 text-center textColor-light">
                  {data ? data['5858045913'] : 'Lorem Ipsum'}
                </p>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className={
                colorMode === 'light'
                  ? 'border p-2 w-full rounded-lg'
                  : 'p-2 w-full rounded-lg'
              }>
                <div
                  id="5933313012"
                  className="dynamicStyle2 overflow-hidden w-full"
                >
                  <Image
                    src={data ? getImage(data['5933313012']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="teams-card"
                    className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
                  />
                </div>

                <p
                  id="7201300367"
                  className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 cust-textColor"
                >
                  {data ? data['7201300367'] : 'Lorem Ipsum'}
                </p>
                <p id="1918586962" className="dynamicStyle fsize14 text-center textColor-light">
                  {data ? data['1918586962'] : 'Lorem Ipsum'}
                </p>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className={
                colorMode === 'light'
                  ? 'border p-2 w-full rounded-lg'
                  : 'p-2 w-full rounded-lg'
              }>
                <div
                  id="0576852708"
                  className="dynamicStyle2 overflow-hidden w-full"
                >
                  <Image
                    src={data ? getImage(data['0576852708']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="teams-card"
                    className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
                  />
                </div>

                <p
                  id="3303375256"
                  className="dynamicStyle fsize18 font-semibold text-center lg:mb-2 cust-textColor"
                >
                  {data ? data['3303375256'] : 'Lorem Ipsum'}
                </p>
                <p id="7211792066" className="dynamicStyle fsize14 text-center textColor-light">
                  {data ? data['7211792066'] : 'Lorem Ipsum'}
                </p>
              </Card>
            </SwiperSlide>
          </Swiper>
        </div>
        <div
          className="w-60 sm-wfull lg:px-12 ms:px-12 px-5 lg:pt-0 md:pt-0 pt-4"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <h1
            id="9239265230"
            className="dynamicStyle cust-textColor fsize36 md-fsize32 sm-fsize20 lg:leading-10 md:leading-10 leading-8 lg:w-4/5 md:4/5 font-semibold mb-1"
          >
            {data ? data['9239265230'] : 'Meet Aur Professional teams'}
          </h1>
          <p
            id="8804742678"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['8804742678']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle textColor-light fsize16 sm-fsize14"
          >
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            cursus consectetur euismod aenean. */}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Teams2;
