import { Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux/es/exports';

const AboutSection18 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className={
      colorMode === 'light'
        ? 'bg-secondary'
        : ''
    }>
      <div className="container mx-auto lg:px-12 md:px-10 px-5 lg:py-12 md:py-10 py-8 lg:flex gap-12">
        <div className="w-full lg:mb-0 md:mb-6 mb-6">
          <h1
            id="8553225104"
            className="dynamicStyle textwhite uppercase fsize36 font-semibold md:fsize28 sm-fsize20 text-primary"
          >
            {data ? data['8553225104'] : 'Lorem ipsum'}
          </h1>
          <p
            id="2426863193"
            dangerouslySetInnerHTML={{
              __html: data ? data['2426863193'] : 'no-data',
            }}
            className="dynamicStyle fsize14 textwhite textColor-light"
          >
            {/* Our Marine Engineering division is dedicated to serving the maritime
            industry with cutting-edge solutions. We offer a wide range of
            services. */}
          </p>
          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-4 gap-2 mt-6">
            <div className="flex items-center gap-2">
              <div className="green-dot cust-bgColor"></div>
              <p id="9356766138" className="dynamicStyle fsize14 textwhite">
                {data ? data['9356766138'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="green-dot cust-bgColor"></div>
              <p id="3095405367" className="dynamicStyle fsize14 textwhite">
                {data ? data['3095405367'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="green-dot cust-bgColor"></div>
              <p id="0816456007" className="dynamicStyle fsize14 textwhite">
                {data ? data['0816456007'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="green-dot cust-bgColor"></div>
              <p id="5448274608" className="dynamicStyle fsize14 textwhite">
                {data ? data['5448274608'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="green-dot cust-bgColor"></div>
              <p id="4111769935" className="dynamicStyle fsize14 textwhite">
                {data ? data['4111769935'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="green-dot cust-bgColor"></div>
              <p id="0735016315" className="dynamicStyle fsize14 textwhite">
                {data ? data['0735016315'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="green-dot cust-bgColor"></div>
              <p id="8513043931" className="dynamicStyle fsize14 textwhite">
                {data ? data['8513043931'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="green-dot cust-bgColor"></div>
              <p id="8123805840" className="dynamicStyle fsize14 textwhite">
                {data ? data['8123805840'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="green-dot cust-bgColor"></div>
              <p id="3393187084" className="dynamicStyle fsize14 textwhite">
                {data ? data['3393187084'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="green-dot cust-bgColor"></div>
              <p id="6534446282" className="dynamicStyle fsize14 textwhite">
                {data ? data['6534446282'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="green-dot cust-bgColor"></div>
              <p id="3269626863" className="dynamicStyle fsize14 textwhite">
                {data ? data['3269626863'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="green-dot cust-bgColor"></div>
              <p id="8372659681" className="dynamicStyle fsize14 textwhite">
                {data ? data['8372659681'] : 'Lorem ipsum'}
              </p>
            </div>
          </div>
        </div>

        <div id="4015173834" className="dynamicStyle2 w-full">
          <Image
            src={data ? getImage(data['4015173834']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="section-image"
            id=""
            className="w-full h-400px sm-h250px"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutSection18;
