import React from 'react';
import ImageGallery from 'react-image-gallery';

const ProductDetails4 = () => {
  const images = [
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];
  return (
    <div className="container mx-auto lg:px-12 md:px-10 px-5 lg:py-12 md:py-12 py-8">
      <div className="w-full lg:flex md:flex gap-6 lg:mb-14 ">
        <div className="lg:w-full md:w-1/2">
          <ImageGallery items={images} />
        </div>
        <div className="lg:w-full md:w-1/2">
          <h2 className="font-semibold fsize28 sm-fsize20 lg:mb-4 md:mb-4 mb-2 mt-6">
            Apple Iphone 14 Pro
          </h2>

          <p className="fsize14 lg:mb-4 md:mb-4 mb-4 sm-lh-24 md-lh-24 lg:leading-6">
            The iPhone 14 models come in blue, purple, midnight, starlight, and
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails4;
