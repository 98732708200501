import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';

const Team1 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="">
      <div className="text-center container mx-auto flex flex-col justify-center lg:pb-16">
        <h1
          id="2284418816"
          className="dynamicStyle cust-textColor fsize36 font-semibold sm-fsize20 lg:mb-0 md:mb-0 mb-2"
        >
          {data ? data['2284418816'] : 'Meet Aur Professional teams'}
        </h1>
        <p
          id="7743809322"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['7743809322']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className="dynamicStyle textColor-light fsize16 sm-fsize14 text-center"
        >
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus
          consectetur euismod aenean. */}
        </p>
      </div>

      <div className="container mx-auto overflow-x-hidden lg:px-12 md:px-10 px-5">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            820: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <Card
            className={
              colorMode === 'light'
                ? 'box-shadow-none lg:p-4 p-2 w-full rounded-lg'
                : 'lg:p-4 p-2 w-full rounded-lg'
            }
             >
              <div
                id="0978552325"
                className="dynamicStyle2 flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['0978552325']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="imagee"
                  className="teams2-img rounded-full object-cover lg:mb-6 bg-d2d2d2"
                />
              </div>
              <h2
                id="8302754468"
                className="dynamicStyle fsize18 font-semibold lg:mb-2 text-center cust-textColor"
              >
                {data ? data['8302754468'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="7448716612"
                className="dynamicStyle text-gray fsize16 sm-fsize14 text-center textColor-light  textColor-light "
              >
                {data ? data['7448716612'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className={
              colorMode === 'light'
                ? 'box-shadow-none lg:p-4 p-2 w-full rounded-lg'
                : 'lg:p-4 p-2 w-full rounded-lg'
            }>
              <div
                id="9612673494"
                className="dynamicStyle2 flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['9612673494']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="imagee"
                  className="teams2-img rounded-full object-cover lg:mb-6 bg-d2d2d2"
                />
              </div>
              <h2
                id="9467761409"
                className="dynamicStyle fsize18 font-semibold lg:mb-2 text-center cust-textColor"
              >
                {data ? data['9467761409'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="8624279915"
                className="dynamicStyle text-gray fsize16 sm-fsize14 text-center textColor-light  textColor-light "
              >
                {data ? data['8624279915'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className={
              colorMode === 'light'
                ? 'box-shadow-none lg:p-4 p-2 w-full rounded-lg'
                : 'lg:p-4 p-2 w-full rounded-lg'
            }>
              <div
                id="8448502586"
                className="dynamicStyle2 flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['8448502586']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="imagee"
                  className="teams2-img rounded-full object-cover lg:mb-6 bg-d2d2d2"
                />
              </div>
              <h2
                id="1171292301"
                className="dynamicStyle fsize18 font-semibold lg:mb-2 text-center cust-textColor"
              >
                {data ? data['1171292301'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="5807939936"
                className="dynamicStyle text-gray fsize16 text-center textColor-light "
              >
                {data ? data['5807939936'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className={
              colorMode === 'light'
                ? 'box-shadow-none lg:p-4 p-2 w-full rounded-lg'
                : 'lg:p-4 p-2 w-full rounded-lg'
            }>
              <div
                id="9230017509"
                className="dynamicStyle2 flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['9230017509']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="imagee"
                  className="teams2-img rounded-full object-cover lg:mb-6 bg-d2d2d2"
                />
              </div>
              <h2
                id="4301557623"
                className="dynamicStyle fsize18 font-semibold lg:mb-2 text-center cust-textColor"
              >
                {data ? data['4301557623'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="0791154781"
                className="dynamicStyle text-gray fsize16 text-center textColor-light "
              >
                {data ? data['0791154781'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className={
              colorMode === 'light'
                ? 'box-shadow-none lg:p-4 p-2 w-full rounded-lg'
                : 'lg:p-4 p-2 w-full rounded-lg'
            }>
              <div
                id="5161263495"
                className="dynamicStyle2 flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['5161263495']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="image"
                  className="teams2-img rounded-full object-cover lg:mb-6 bg-d2d2d2"
                />
              </div>
              <h2
                id="0398678841"
                className="dynamicStyle fsize18 font-semibold lg:mb-2 text-center cust-textColor"
              >
                {data ? data['0398678841'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="2286338251"
                className="dynamicStyle text-gray fsize16 text-center textColor-light "
              >
                {data ? data['2286338251'] : 'Lorem Ipsum'}
              </p>
            </Card>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Team1;
