import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import React from 'react';

const HelpSupport1 = () => {
  return (
    <div className="">
      <div>
        <h2 className=" fsize20 font-semibold pb-4 clr-606060">
          Help & Support
        </h2>
      </div>
      <div>
        <Accordion allowToggle>
          <AccordionItem mb={4} className="border-accordion">
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Section 1{' '}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>Lorem ipsum dolor sit amet.</AccordionPanel>
          </AccordionItem>
          <AccordionItem mb={4} className="border-accordion">
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Section 2{' '}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>Lorem ipsum dolor sit amet.</AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="flex items-center pt-4">
        <p className="fsize14 fweight-500 mr-1">
          If you have any further queries{' '}
        </p>
        <span className="text-primary font-semibold fsize14 cursor-pointer">
          Connect Us
        </span>
      </div>
    </div>
  );
};

export default HelpSupport1;
