import React, { Fragment, useEffect, useState } from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
//router
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
//import components pages
// import Chat from './pages/allSections/components/AiSubComp/Chat.jsx';
import Sections from "./pages/allSections/Sections";
import { otherLogin, getUserByToken } from "./redux/auth/auth-action.js";
import Master from "./pages/allSections/Master";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { getProject } from "./redux/userDetails/user-actions.js";
// import ForgotPassword from './pages/forgotPassword/ForgotPassword.jsx';

//apis
import { getAllMenu } from "./redux/menu/menu-actions.js";
import { getBrandProfile } from "./redux/brandDetails/bd-actions.js";
import { getCms, getSections } from "./redux/cms/cms-actions.js";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getAllPages } from "./redux/pages/pages-actions";
// import BookAppointment from './pages/allSections/components/BookAppointment.jsx';
import { getGateway } from "./redux/payment/gateway-actions.js";
import UnSubscribe from "./pages/unsubscribe/UnSubscribe.jsx";
import Sports from "./pages/allSections/extension/sports/Sport.jsx";

import ScrollToTop from "./components/ScrollToTop.jsx";
import { getAllForms } from "./redux/forms/forms-action.js";
import AboutSection16 from "./pages/allSections/components/AboutSection16.jsx";
import Testimonial7 from "./pages/allSections/components/Testimonial7.jsx";
// import Profile from './pages/allSections/mainProfile/Profile';
// import IciciHome from './pages/allSections/extension/dms/IciciHome.jsx';
import Bussiness from "./pages/allSections/extension/dms/business-dash/business/Bussiness.jsx";
// import IciciHeader from './pages/allSections/extension/dms/components/IciciHeader.jsx';
import Reviewer from "./pages/allSections/extension/dms/reviewer-dash/reviewer-home/Reviewer.jsx";
import HelpSupport from "./pages/allSections/extension/dms/business-dash/bussiness-helpsupport/Bussiness-Helpsupport.jsx";
import Assets from "./pages/allSections/extension/dms/business-dash/bussiness-assets/Bussiness-Assets.jsx";
import Author from "./pages/allSections/extension/dms/business-dash/bussiness-author/Bussiness-Author.jsx";
import Iciciadmin from "./pages/allSections/extension/dms/admin-dash/admin/Admin.jsx";
import FileManagement from "./pages/allSections/extension/dms/admin-dash/filemanagement/FileManagement.jsx";
import ApproveFile from "./pages/allSections/extension/dms/admin-dash/approvedfile/ApproveFile.jsx";
import AssetsFile from "./pages/allSections/extension/dms/admin-dash/assetsfile/AssetsFile.jsx";
import Leadership from "./pages/allSections/extension/dms/leadership-dash/LeaderShip.jsx";
import Helpsupport2 from "./pages/allSections/extension/dms/leadership-dash/helpsupport/HelpSupport.jsx";
import AssetsFile2 from "./pages/allSections/extension/dms/leadership-dash/assetsfile/AssetsFile.jsx";
import ApproveFile2 from "./pages/allSections/extension/dms/leadership-dash/approvedfile/ApproveFile.jsx";
import Dashboard from "./pages/allSections/extension/dms/author-dash/authordashboard/Dashboard.jsx";
import Authorassets from "./pages/allSections/extension/dms/author-dash/assetsfile/Authorassets.jsx";
import Folderauthor from "./pages/allSections/extension/dms/author-dash/folder/Folderauthor.jsx";
import Helpsupport3 from "./pages/allSections/extension/dms/author-dash/helpsupport/HelpSupport.jsx";
import AuthorTabs from "./pages/allSections/extension/dms/author-dash/authortabs/AuthorTabs.jsx";
import Dashboard2 from "./pages/allSections/extension/dms/approval-dash/dashboard/Dashboard.jsx";
import Helpsupport4 from "./pages/allSections/extension/dms/approval-dash/helpsupport/HelpSupport.jsx";
import Approvalassets from "./pages/allSections/extension/dms/approval-dash/assetsfile/Approvalassets.jsx";
import Folderapproval from "./pages/allSections/extension/dms/approval-dash/folder/Folderapproval.jsx";
import ApprovalTabs from "./pages/allSections/extension/dms/approval-dash/approvaltabs/ApprovalTabs.jsx";
import ClgBusiness from "./pages/allSections/extension/dms/userLogin/ClgBusiness.jsx";
import ReviewAssets from "./pages/allSections/extension/dms/reviewer-dash/reviewer-assets/ReviewAssets.jsx";
import Approval from "./pages/allSections/extension/dms/reviewer-dash/reviewer-approval/Approval.jsx";
import ReviewTab from "./pages/allSections/extension/dms/reviewer-dash/reviewer-tabs/ReviewTab.jsx";
import Profile from "./pages/allSections/mainProfile/Profile";
import AllCourses from "./pages/allSections/extension/elearning/AllCourses.jsx";
import Profile2 from "./pages/allSections/mainProfile/Profile2.jsx";
import Profile3 from "./pages/allSections/mainProfile/Profile3.jsx";

const App = () => {
  const dispatch = useDispatch();

  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let getRoutesNames = useSelector((state) => state.cms.routeNames);
  let getOtherLogin = useSelector((state) => state.AuthDetails.otherLogin);
  // const [dynamicRoutes, setDynamicRoutes] = useState(['test', 'example']);
  // let menuData = useSelector(state => state.menu.menu);
  const [primaryColor, setPrimaryColor] = useState("#7a4189");
  const [secondaryColor, setSecondaryColor] = useState("#7a4189");
  const [tertiaryColor, setTertiaryColor] = useState("#7a4189");
  const [bodyColor, setbodyColor] = useState("#00000");

  const catchLogin = async () => {
    try {
      dispatch(otherLogin(""));
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      const decodedToken = token ? JSON.parse(decodeURIComponent(token)) : null;

      if (decodedToken) {
        const data = await dispatch(getUserByToken({ token: decodedToken }));

        if (data && data.code === 200) {
          Swal.fire({
            title: "Success",
            text: `Loggedin successfully`,
            icon: "success",
            timer: "2500",
          });
          window.history.replaceState(null, "", "/home");
        } else {
          if (data.message) {
            Swal.fire({
              title: "Error",
              text: data.message,
              icon: "error",
            });
          }
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    async function mount() {
      await dispatch(getAllMenu());
      await dispatch(getProject());
      await dispatch(
        getBrandProfile({
          projectId: process.env.REACT_APP_ID,
          projectName: process.env.REACT_APP_COMMUNITY_NAME,
        })
      );
      await dispatch(getCms());
      await dispatch(getSections());
      await dispatch(getBrandProfile());
      await dispatch(getAllForms());
      await dispatch(getGateway());
      await dispatch(getAllPages());

      if (getOtherLogin) {
        catchLogin();
      }

      const fontToLoad = brandProfile ? brandProfile.primaryFont : "sans-serif";
      const fontLink = document.createElement("link");

      if (fontToLoad) {
        fontLink.rel = "stylesheet";
        fontLink.href = `https://fonts.googleapis.com/css?family=${fontToLoad.replace(
          / /g,
          "+"
        )}`;
      }
      if (brandProfile) {
        const primaryColor = brandProfile.primaryColor;
        const secondaryColor = brandProfile.secondaryColor;
        const tertiaryColor = brandProfile.tertiaryColor;
        const bodyColor = brandProfile.bodyColor;

        setPrimaryColor(primaryColor);
        setSecondaryColor(secondaryColor);
        setTertiaryColor(tertiaryColor);
        setbodyColor(bodyColor);
        console.log(bodyColor, "brand");
      }

      document.head.appendChild(fontLink);

      fontLink.addEventListener("load", () => {
        const content = document.getElementById("root");
        content.style.fontFamily = `${fontToLoad},sans-serif`;
      });
    }

    mount();
    // eslint-disable-next-line
  }, [dispatch, primaryColor, secondaryColor, tertiaryColor]);

  const theme = extendTheme({
    styles: {
      global: (props) => ({
        ".cust-textColor": {
          color:
            props.colorMode === "dark" ? "#ffffffeb !important" : "#494949",
        },
        ".navbartextColor-light": {
          color: props.colorMode === "dark" ? "#a0aec0 !important" : "",
        },
        ".textColor-light": {
          color: props.colorMode === "dark" ? "#a0aec0 !important" : "#808080",
        },
        ".cust-bgColor": {
          backgroundColor:
            props.colorMode === "dark" ? "#2d3748 !important" : "",
        },
      }),
    },
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
    },
    colors: {
      light: {
        bg: "#ffffff",
        text: "#000000",
      },
      dark: {
        bg: "#1a202c",
        text: "#ffffff",
      },
      primary: {
        500: `${primaryColor}`,
      },
      primary: {
        500: `${primaryColor}`,
      },
      secondary: {
        500: `${secondaryColor}`,
      },
      tertiary: {
        500: `${tertiaryColor}`,
      },
    },
  });

  //   dispatch(getBrandProfile());
  // }, []);
  function applyThemeToDocument() {
    if (brandProfile && brandProfile.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--chakra-colors-primary",
        brandProfile.primaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("secondaryColor"))
      document.body.style.setProperty(
        "--chakra-colors-secondary",
        brandProfile.secondaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("tertiaryColor"))
      document.body.style.setProperty(
        "--chakra-colors-tertiary",
        brandProfile.tertiaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("bodyColor"))
      document.body.style.setProperty(
        "--chakra-colors-chakra-body-text",
        brandProfile.bodyColor
      );
  }
  applyThemeToDocument();

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <ScrollToTop />
        {/* <IciciHeader /> */}
        <Routes>
          {getRoutesNames &&
            !!getRoutesNames.length &&
            getRoutesNames.map((dynRoute, index) => {
              return (
                <Fragment key={dynRoute}>
                  <Route
                    key={`${dynRoute}${index}`}
                    path={`/${dynRoute.toLocaleLowerCase()}`}
                    element={<Master routeName={dynRoute} />}
                  />
                  <Route
                    key={`${dynRoute}${index}${index}`}
                    path={`/${dynRoute.toLocaleLowerCase()}/cms/:token`}
                    element={<Master routeName={dynRoute} />}
                  />
                  <Route
                    key={`${dynRoute}${index}${index}`}
                    path={`/${dynRoute.toLocaleLowerCase()}/:blogId`}
                    element={<Master routeName={dynRoute} />}
                  />
                </Fragment>
              );
            })}
          {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/section" element={<Sections />} />
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/sports" element={<Sports />} />
          {/* <Route path="/aisectiont" element={<Chat />} /> */}
          {/* <Route path="/scv" element={<SCVHome />} /> */}
          {/* <Route path="*" element={<Login />} /> */}
          <Route path="/AboutSection16" element={<AboutSection16 />} />
          <Route path="/unsubcribe" element={<UnSubscribe />} />
          <Route path="/testimonial7" element={<Testimonial7 />} />
          <Route path="/test" element={<AllCourses />} />
          {/* <Route path="/detail" element={<Testimonial7 />} /> */}
          {/* ============== Login-icici ============== */}
          <Route path="/userlogin" element={<ClgBusiness />} />
          {/* ============== Login-icici ============== */}
          {/* ============== Business-icici ============== */}
          <Route path="/business-home" element={<Bussiness />} />
          <Route path="/business-assets" element={<Assets />} />
          <Route path="/business-author" element={<Author />} />
          <Route path="/business-helpsupport" element={<HelpSupport />} />
          {/* ============== Business-icici ============== */}
          {/* ============== Icici-Admin ============== */}
          <Route path="/admin" element={<Iciciadmin />} />
          <Route path="/admin-tabs" element={<FileManagement />} />
          <Route path="/admin-approved" element={<ApproveFile />} />
          <Route path="/admin-assets" element={<AssetsFile />} />
          {/* ============== Icici-Admin ============== */}
          {/* ============== Leadership ============== */}
          <Route path="/leadership" element={<Leadership />} />
          <Route path="/leadership-helpsupport" element={<Helpsupport2 />} />
          <Route path="/leadership-assets" element={<AssetsFile2 />} />
          <Route path="/leadership-approve" element={<ApproveFile2 />} />
          {/* ============== Leadership ============== */}
          {/* ============== Author ============== */}
          <Route path="/author-dash" element={<Dashboard />} />
          <Route path="/author-assets" element={<Authorassets />} />
          <Route path="/author-folder" element={<Folderauthor />} />
          <Route path="/author-helpsupport" element={<Helpsupport3 />} />
          <Route path="/author-tabs" element={<AuthorTabs />} />
          {/* ============== Author ============== */}
          {/* ============== Approval ============== */}
          <Route path="/approval-dash" element={<Dashboard2 />} />
          <Route path="/approval-helpsupport" element={<Helpsupport4 />} />
          <Route path="/approval-assets" element={<Approvalassets />} />
          <Route path="/approval-folder" element={<Folderapproval />} />
          <Route path="/approval-tabs" element={<ApprovalTabs />} />
          {/* ============== Approval ============== */}
          {/* ============== Reviwer ============== */}
          <Route path="/reviewer" element={<Reviewer />} />
          <Route path="/reviewer-assets" element={<ReviewAssets />} />
          <Route path="/reviewer-approval" element={<Approval />} />
          <Route path="/reviewer-tabs" element={<ReviewTab />} />
          {/* ============== Reviwer ============== */}
          <Route path="/home1" element={<Profile2 />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
