import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

const FeatureCard8 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section className="container mx-auto lg:px-12 md:px-12 px-5 lg:py-8 md:py-8 py-6">
      <div className="text-center lg:w-1/2 md:w-2/3 w-full mx-auto lg:mb-14 md:mb-14 mb-6">
        <h3
          id="2312621399"
          className="dynamicStyle sm-fsize14 font-semibold text-primary mb-2 cust-textColor"
        >
          {data ? data['2312621399'] : 'Lorem Ipsum'}
        </h3>
        <h1
          id="7594297016"
          className="dynamicStyle fsize30 sm-fsize20 font-semibold mb-2 cust-textColor"
        >
          {data ? data['7594297016'] : 'Lorem Ipsum'}
        </h1>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-8">
        <Card className="w-full flex flex-col items-center cust-shadow rounded-xl lg:px-6 md:px-6 px-4 lg:py-12 md:py-10 py-6">
          <div
            id="1874230185"
            className="dynamicStyle2 w-full flex justify-left"
          >
            <Image
              src={data ? getImage(data['1874230185']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              className="object-contain card-img mb-6 "
            />
          </div>
          <div className="lg:pb-4 md:pb-4 pb-2 text-left w-full">
            <h1
              id="0860297696"
              className="fsize24 dynamicStyle sm-fsize18 font-semibold"
            >
              {data ? data['0860297696'] : 'Lorem ipsum'}
            </h1>
            <p
              id="4296735226"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['4296735226']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
              className="dynamicStyle fsize14 font-medium text-gray pt-2 sm-fsize14 text-left"
            ></p>
          </div>
        </Card>

        <Card className="w-full flex flex-col items-center cust-shadow rounded-xl lg:px-6 md:px-6 px-4 lg:py-12 md:py-10 py-6">
          <div
            id="0491931504"
            className="dynamicStyle2 w-full flex justify-left"
          >
            <Image
              src={data ? getImage(data['0491931504']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              className="object-contain card-img mb-6 "
            />
          </div>
          <div className="lg:pb-4 md:pb-4 pb-2 text-left w-full">
            <h1
              id="0172889425"
              className="fsize24 dynamicStyle sm-fsize18 font-semibold"
            >
              {data ? data['0172889425'] : 'Lorem ipsum'}
            </h1>
            <p
              id="8180288670"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['8180288670']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
              className="dynamicStyle fsize14 font-medium text-gray pt-2 sm-fsize14 text-left"
            ></p>
          </div>
        </Card>

        <Card className="w-full flex flex-col items-center cust-shadow rounded-xl lg:px-6 md:px-6 px-4 lg:py-12 md:py-10 py-6">
          <div
            id="9065410798"
            className="dynamicStyle2 w-full flex justify-left"
          >
            <Image
              src={data ? getImage(data['9065410798']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              className="object-contain card-img mb-6 "
            />
          </div>
          <div className="lg:pb-4 md:pb-4 pb-2 text-left w-full">
            <h1
              id="0803510882"
              className="fsize24 dynamicStyle sm-fsize18 font-semibold"
            >
              {data ? data['0803510882'] : 'Lorem ipsum'}
            </h1>
            <p
              id="3608966153"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['3608966153']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
              className="dynamicStyle fsize14 font-medium text-gray pt-2 sm-fsize14 text-left"
            ></p>
          </div>
        </Card>

        {/* <div className="w-full flex flex-col items-center cust-shadow rounded-xl lg:px-6 md:px-6 px-4 lg:py-12 md:py-10 py-6">
          <div
            id="0433984666"
            className="dynamicStyle2 w-full flex justify-left"
          >
            <Image
              src={data ? getImage(data['0433984666']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              className="object-contain card-img mb-6 "
            />
          </div>
          <div className="lg:pb-4 md:pb-4 pb-2 text-left w-full">
            <h1
              id="3629366432"
              className="fsize24 dynamicStyle sm-fsize18 font-semibold"
            >
              {data ? data['3629366432'] : 'Lorem ipsum'}
            </h1>
            <p
              id="2113895211"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['2113895211']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
              className="dynamicStyle fsize14 font-medium text-gray pt-2 sm-fsize14 text-left"
            ></p>
          </div>
        </div>

        <div className="w-full flex flex-col items-center cust-shadow rounded-xl lg:px-6 md:px-6 px-4 lg:py-12 md:py-10 py-6">
          <div
            id="0433984666"
            className="dynamicStyle2 w-full flex justify-left"
          >
            <Image
              src={data ? getImage(data['0433984666']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              className="object-contain card-img mb-6 "
            />
          </div>
          <div className="lg:pb-4 md:pb-4 pb-2 text-left w-full">
            <h1
              id="3629366432"
              className="fsize24 dynamicStyle sm-fsize18 font-semibold"
            >
              {data ? data['3629366432'] : 'Lorem ipsum'}
            </h1>
            <p
              id="2113895211"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['2113895211']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
              className="dynamicStyle fsize14 font-medium text-gray pt-2 sm-fsize14 text-left"
            ></p>
          </div>
        </div>

        <div className="w-full flex flex-col items-center cust-shadow rounded-xl lg:px-6 md:px-6 px-4 lg:py-12 md:py-10 py-6">
          <div
            id="0433984666"
            className="dynamicStyle2 w-full flex justify-left"
          >
            <Image
              src={data ? getImage(data['0433984666']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              className="object-contain card-img mb-6 "
            />
          </div>
          <div className="lg:pb-4 md:pb-4 pb-2 text-left w-full">
            <h1
              id="3629366432"
              className="fsize24 dynamicStyle sm-fsize18 font-semibold"
            >
              {data ? data['3629366432'] : 'Lorem ipsum'}
            </h1>
            <p
              id="2113895211"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['2113895211']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
              className="dynamicStyle fsize14 font-medium text-gray pt-2 sm-fsize14 text-left"
            ></p>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default FeatureCard8;
