import React from "react";
import { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useSelector } from "react-redux/es/exports";
import { Image } from "@chakra-ui/react";

const CounterSection2 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const [counterOn, setCounterOn] = useState(false);

  return (
    <section className="bg-f4f5f6 lg:py-10 md:py-8 py-4">
      <div className="container mx-auto grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 lg:gap-36 md:gap-18 gap-4 m-auto lg:px-12 md:px-8 px-5">
        <div>
          <div className="flex items-center justify-center text-primary">
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <CountUp
                  className=" font-bold fsize36 md-fsize30 sm-fsize24"
                  start={0}
                  end={200}
                  duration={4}
                  delay={0}
                />
              )}
            </ScrollTrigger>
            <p className=" fsize36 md-fsize30 sm-fsize24 text-primary pb-1">
              +
            </p>
          </div>
          <p
            id="4372004291"
            className="dynamicStyle text-center fsize18 md-fsize16 sm-fsize13 text-535353 font-semibold"
          >
            {data ? data["4372004291"] : "Lorem Ipsum"}
          </p>
        </div>
        <div>
          <div className="flex items-center justify-center text-primary">
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <CountUp
                  className=" font-bold fsize36 md-fsize30 sm-fsize24"
                  start={0}
                  end={98}
                  duration={4}
                  delay={0}
                />
              )}
            </ScrollTrigger>
            <p className=" fsize36 md-fsize30 sm-fsize24 text-primary">%</p>
          </div>
          <p
            id="3677823420"
            className="dynamicStyle  text-center fsize18 md-fsize16 sm-fsize13 text-535353 font-semibold"
          >
            {data ? data["3677823420"] : "Lorem Ipsum"}
          </p>
        </div>
        <div className="thirdcounter">
          <div className="flex items-center justify-center text-primary">
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <CountUp
                  className=" font-bold fsize36 md-fsize30 sm-fsize24"
                  start={0}
                  end={14}
                  duration={4}
                  delay={0}
                />
              )}
            </ScrollTrigger>
            {/* <p className=" fsize36">+</p> */}
          </div>
          <p
            id="4655683231"
            className="dynamicStyle  text-center fsize18 md-fsize16 sm-fsize13 text-535353 font-semibold"
          >
            {data ? data["4655683231"] : "Lorem Ipsum"}
          </p>
        </div>
        <div className="fourthcounter">
          <div className="flex items-center justify-center text-primary">
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <CountUp
                  className=" font-bold fsize36 md-fsize30 sm-fsize24"
                  start={0}
                  end={15}
                  duration={4}
                  delay={0}
                />
              )}
            </ScrollTrigger>
            <p className=" fsize36 md-fsize30 sm-fsize24 text-primary">%</p>
          </div>
          <p
            id="1269116367"
            className="dynamicStyle  text-center fsize18 md-fsize16 sm-fsize13 text-535353 font-semibold"
          >
            {data ? data["1269116367"] : "Lorem Ipsum"}
          </p>
        </div>
      </div>
    </section>
  );
};

export default CounterSection2;
