import axios from 'axios';
import { store } from '../../redux/store';

const baseURL = process.env.REACT_APP_BASE_URL;

const API = axios.create({
  baseURL,
  headers: {
    'community-name': process.env.REACT_APP_COMMUNITY_NAME,
    accept: 'application/json',
  },
});

API.interceptors.request.use(
  config => {
    let token = '';
    if (
      store.getState() &&
      store.getState().AuthDetails &&
      store.getState().AuthDetails.user &&
      store.getState().AuthDetails.user.token
    ) {
      token = store.getState().AuthDetails.user.token;
    }
    const cmsToken = localStorage.getItem('cmsToken');

    if (cmsToken) {
      config.headers['Cms-Authorization'] = `Bearer ${cmsToken}`;
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  error => Promise.reject(error)
);

export default API;
