import React from 'react';
import { Text, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const HeroSection14 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <section>
      <div className={colorMode === 'light' ? 'bg-secondary' : 'cust-bgColor'}>
        <div className="lg:flex md:flex sm-reverse h-full items-center">
          <div className="lg:px-20 lg:mt-0 md:mt-0 mt-4 md:px-12 px-5 lg:w-3/5 md:w-3/5 flex items-center banner-height">
            <div>
              <h2
                id="2154722687"
                className="dynamicStyle lg:text-4xl md:text-2xl uppercase text-end text-white text-xl font-semibold"
              >
                {data ? data['2154722687'] : ' 700+ Expert Coaches'}
              </h2>
              {/* <h2 className="lg:text-4xl md:text-2xl uppercase text-end text-white text-xl font-semibold">
                You Could Be One Of Them
              </h2> */}
              <p
                dangerouslySetInnerHTML={{
                  __html: data ? data['3619909429'] : 'no-data',
                }}
                id="3619909429"
                className="dynamicStyle text-white sm-fsize14 opacity-70 lg:mt-4 md:mt-4 mt-2"
              ></p>
            </div>
          </div>

          <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
            <div id="6920255080" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['6920255080']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="heroImage"
                className=" object-cover w-full sm-h250px minh450px"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection14;
